import "./App.css";
import React, { useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navbar } from "./components/Navbar";
import { Home } from "./pages/Home";
import { Login } from "./pages/auth/Login";
import { Signup } from "./pages/auth/Signup";
import { AdminPanel } from "./pages/admin_panel/AdminPanel";
import * as constants from "./api/constants";
import { TwoStepAuth } from "./pages/auth/TwoStepAuth";
import { BusinessPanel } from "./pages/business_panel/BusinessPanel";
import PopupContext from "./contexts/PopupContext";
import { GeneralPage } from "./pages/business_panel/General";
import { LogoPage } from "./pages/business_panel/Logo";
import { LinksPage } from "./pages/business_panel/Links";
import { CatalogPage } from "./pages/business_panel/Catalog";
import { NewCollectionPage } from "./pages/business_panel/NewCollection";
import { CollectionsPage } from "./pages/business_panel/Collections";
import { RequestsPage } from "./pages/admin_panel/Requests";
import { BusinessesPage } from "./pages/admin_panel/Businesses";
import { AddBusinessPage } from "./pages/admin_panel/AddBusiness";
import { Privacy } from "./pages/business_panel/Privacy";
import { SessionsAndDevicesPage } from "./pages/business_panel/SessionsAndDevices";
import { BusinessPage } from "./pages/business_page/BusinessPage";
import { BusinessCollectionsPage } from "./pages/business_page/BusinessCollections";
import { TwoFactorAuth } from "./pages/business_panel/TwoFactorAuth";
import { EditCollectionPage } from "./pages/business_panel/EditCollection";
import { BusinessCollectionProductsPage } from "./pages/business_page/BusinessCollectionProducts";
import { Footer } from "./components/Footer";
import { AboutPage } from "./pages/business_panel/About";
import { BusinessAboutPage } from "./pages/business_page/BusinessAbout";
import { SendNotificationPage } from "./pages/admin_panel/SendNotification";
import { ChangePasswordPage } from "./pages/business_panel/ChangePassword";
import { AnimatePresence } from "framer-motion";
import { AccountSetup } from "./pages/auth/AccountSetup";
import { SuccessModal } from "./components/modals/SuccessModal";
import { ConfirmDialogModal } from "./components/modals/ConfirmDialogModal";
import { ErrorModal } from "./components/modals/ErrorModal";
import { ProductModal } from "./components/modals/ProductModal";
import { ProgressBarModal } from "./components/modals/ProgressBarModal";
import { ImageModal } from "./components/modals/ImageModal";
import {
  RequestPasswordReset,
  ResetPassword,
} from "./pages/auth/ResetPassword";
import { SingleProductPage } from "./pages/business_page/SingleProduct";
import { Authenticator } from "./components/Authenticator";
import { NotFoundPage } from "./pages/NotFoundPage";
import { LocationPage } from "./pages/business_panel/Location";
import { SelectOptionsModal } from "./components/modals/SelectOptionsModal";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { SubscriptionPage } from "./pages/business_panel/Subscription";
import { ManagePaymentMethodPage } from "./pages/business_panel/ManagePaymentMethod";
import { UpgradeToPaidPage } from "./pages/business_panel/UpgradeToPaid";
import { NextPaymentPage } from "./pages/business_panel/NextPayment";
import { BillsPage } from "./pages/business_panel/Bills";
import { CancelSubscriptionPage } from "./pages/business_panel/CancelSubscription";
import { SubscriptionPlans } from "./pages/SubscriptionPlans";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { EmailVerification } from "./pages/auth/EmailVerification";
import { LocationTracker } from "./components/LocationTracker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  function showSuccess(msg, onClose) {
    succModalRef.current.show(msg, onClose);
  }

  function showConfirm(msg, onConfirm, onCancel) {
    confModalRef.current.show(msg, onConfirm, onCancel);
  }

  function showError(msg, onClose) {
    errModalRef.current.show(msg, onClose);
  }

  function showImage(source) {
    imgModalRef.current.show(source);
  }

  function showProduct(data, business_name) {
    proModalRef.current.show(data, business_name);
  }

  function showProgressBar(percent, text, onFinish) {
    prgrsBarModalRef.current.show(percent, text, onFinish);
  }

  function showSelectOptions(options, onSelect) {
    selectOptionsModalRef.current.show(options, onSelect);
  }

  const succModalRef = useRef();
  const confModalRef = useRef();
  const errModalRef = useRef();
  const imgModalRef = useRef();
  const proModalRef = useRef();
  const prgrsBarModalRef = useRef();
  const selectOptionsModalRef = useRef();

  const popupObj = {
    showSuccess: showSuccess,
    showConfirm: showConfirm,
    showError: showError,
    showImage: showImage,
    showProduct: showProduct,
    showProgressBar: showProgressBar,
    showSelectOptions: showSelectOptions,
  };

  const helmetContext = {};

  return (
    <>
      <PopupContext.Provider value={popupObj}>
        <QueryClientProvider client={queryClient}>
          <Authenticator>
            <HelmetProvider context={helmetContext}>
              <Helmet title={constants.NAME} htmlAttributes={{ lang: "es" }} />
              <BrowserRouter>
                <Navbar />
                <LocationTracker />
                <AnimatePresence>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                      path={constants.PRIVACY_POLICY_ROUTE}
                      element={<PrivacyPolicy />}
                    />
                    <Route
                      path={constants.TERMS_AND_CONDITIONS_ROUTE}
                      element={<TermsAndConditions />}
                    />
                    <Route
                      path={constants.MEMBERSHIP_ROUTE}
                      element={<SubscriptionPlans />}
                    />
                    <Route path={constants.LOGIN_ROUTE} element={<Login />} />
                    <Route
                      path={constants.RESET_PASSWORD_ROUTE}
                      element={<RequestPasswordReset />}
                    />
                    <Route
                      path={constants.RESET_PASSWORD_ROUTE + "/:token"}
                      element={<ResetPassword />}
                    />
                    <Route
                      path={constants.EMAIL_VERIFICATION_ROUTE}
                      element={<EmailVerification />}
                    />
                    <Route
                      path={constants.TWO_STEP_AUTH_ROUTE}
                      element={<TwoStepAuth />}
                    />
                    <Route path={constants.SIGNUP_ROUTE} element={<Signup />} />
                    <Route
                      path={constants.SIGNUP_ROUTE + "/finish"}
                      element={<AccountSetup />}
                    />
                    <Route
                      path={constants.BUSINESS_PAGE_ROUTE}
                      element={<BusinessPage />}
                    >
                      <Route
                        index
                        element={
                          <Navigate
                            to={constants.BUSINESS_COLLECTIONS_ROUTE}
                            replace
                          />
                        }
                      />
                      <Route
                        path={constants.BUSINESS_COLLECTIONS_ROUTE}
                        element={<BusinessCollectionsPage />}
                      />
                      <Route
                        path={constants.BUSINESS_COLLECTION_ROUTE}
                        element={<BusinessCollectionProductsPage />}
                      />
                      <Route
                        path={constants.BUSINESS_PRODUCT_ROUTE}
                        element={<SingleProductPage />}
                      />
                      <Route
                        path={constants.BUSINESS_ABOUT_ROUTE}
                        element={<BusinessAboutPage />}
                      />
                    </Route>
                    <Route
                      path={constants.ADMIN_PANEL_ROUTE}
                      element={<AdminPanel />}
                    >
                      <Route index element={<Navigate to="businesses" />} />
                      <Route path="requests" element={<RequestsPage />} />
                      <Route path="businesses" element={<BusinessesPage />} />
                      <Route
                        path="businesses/add-business"
                        element={<AddBusinessPage />}
                      />
                      <Route
                        path="send-notification"
                        element={<SendNotificationPage />}
                      />
                    </Route>
                    <Route
                      path={constants.MY_BUSINESS_ROUTE}
                      element={<BusinessPanel />}
                    >
                      <Route index element={<Navigate to="general" />} />
                      <Route path="general" element={<GeneralPage />} />
                      <Route path="general/logo" element={<LogoPage />} />
                      <Route path="general/links" element={<LinksPage />} />
                      <Route
                        path="general/location"
                        element={<LocationPage />}
                      />
                      <Route path="general/about" element={<AboutPage />} />
                      <Route path="catalog" element={<CatalogPage />} />
                      <Route
                        path="catalog/new-collection"
                        element={<NewCollectionPage />}
                      />
                      <Route
                        path="catalog/collections"
                        element={<CollectionsPage />}
                      />
                      <Route
                        path="catalog/collections/:col_id"
                        element={<EditCollectionPage />}
                      />
                      <Route
                        path="subscription"
                        element={<SubscriptionPage />}
                      />
                      <Route
                        path="subscription/upgrade-to-paid"
                        element={<UpgradeToPaidPage />}
                      />
                      <Route
                        path="subscription/payment"
                        element={<ManagePaymentMethodPage />}
                      />
                      <Route
                        path="subscription/next-payment"
                        element={<NextPaymentPage />}
                      />
                      <Route
                        path="subscription/bills"
                        element={<BillsPage />}
                      />
                      <Route
                        path="subscription/cancel"
                        element={<CancelSubscriptionPage />}
                      />
                      <Route path="privacy" element={<Privacy />} />
                      <Route
                        path="privacy/sessions"
                        element={<SessionsAndDevicesPage />}
                      />
                      <Route
                        path="privacy/two-factor-auth"
                        element={<TwoFactorAuth />}
                      />
                      <Route
                        path="privacy/change-password"
                        element={<ChangePasswordPage />}
                      />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </AnimatePresence>
                <SuccessModal ref={succModalRef} />
                <ConfirmDialogModal ref={confModalRef} />
                <ErrorModal ref={errModalRef} />
                <ImageModal ref={imgModalRef} />
                <ProductModal ref={proModalRef} />
                <ProgressBarModal ref={prgrsBarModalRef} />
                <SelectOptionsModal ref={selectOptionsModalRef} />
                <Footer />
              </BrowserRouter>
            </HelmetProvider>
          </Authenticator>
        </QueryClientProvider>
      </PopupContext.Provider>
    </>
  );
}
