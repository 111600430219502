import axios from "axios";
import * as errors from "../errors";
import * as storage from "../firebase_storage";

const API = process.env.REACT_APP_API_URL;

const fetcher = axios.create({
  baseURL: API + "/files",
  withCredentials: true,
});

export async function uploadLogo(file_obj) {
  const formData = new FormData();
  formData.append("file", file_obj);

  try {
    const res = await fetcher.post("/logo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      logo_img: storage.getBusinessLogo(
        res.data.business_id,
        res.data.img_token
      ),
      logo_thumbnail_img: storage.getBusinessLogo(
        res.data.business_id,
        res.data.thumbnail_img_token,
        true
      ),
    };
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function getBusinessLogo({ business_id }) {
  errors.validateArgs(business_id);

  try {
    const res = await fetcher.get(`/logo/${business_id}`);

    return {
      logo_img: storage.getBusinessLogo(business_id, res.data.logo_img_token),
      logo_thumbnail_img: storage.getBusinessLogo(
        business_id,
        res.data.logo_thumbnail_img_token,
        true
      ),
    };
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function getBusinessHeaderInfo({ business_name }) {
  errors.validateArgs(business_name);

  try {
    const res = await fetcher.get(`/business/${business_name}/header`);
    return {
      ...res.data,
      logo: {
        img: storage.getBusinessLogo(res.data._id, res.data.logo.img_token),
        thumbnail_img: storage.getBusinessLogo(
          res.data._id,
          res.data.logo.thumbnail_img_token,
          true
        ),
      },
    };
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function getCollectionsInfo({ business_id }) {
  errors.validateArgs(business_id);

  try {
    const res = await fetcher.get(`/collections/${business_id}`, {
      withCredentials: true,
    });
    const array = [];
    for (const col of res.data) {
      array.push({
        ...col,
        cover: {
          img: storage.getCollectionCover(
            business_id,
            col._id,
            col.cover.img_token
          ),
        },
      });
    }
    return array;
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function updateCollectionCover({ col_id, cover }) {
  errors.validateArgs(col_id);

  const formData = new FormData();
  formData.append("cover", cover);

  try {
    await fetcher.post(`/${col_id}/cover`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {};
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}
