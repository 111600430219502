import { SessionCard } from "../../components/SessionCard";
import { useMutation, useQuery } from "react-query";
import * as errors from "../../api/errors";
import * as constants from "../../api/constants";
import { useNavigate } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { LineLoader } from "../../components/loaders/LineLoader";
import { usePopup } from "../../hooks/usePopup";
import { closeAllSessions, getAccountSessions } from "../../api/fetchers/auth";
import { useAuth } from "../../hooks/useAuth";
import { motion } from "framer-motion";

const container_variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

export const SessionsAndDevicesPage = () => {
  async function refetchSessions() {
    await query.refetch();
  }

  async function handleCloseAllSessions() {
    popup.showConfirm(
      "¿Seguro que desea cerrar todas las sesiones (incluyendo la actual)?",
      mutation.mutate
    );
  }

  const navigate = useNavigate();
  const popup = usePopup();
  const auth = useAuth();

  const query = useQuery({
    queryFn: getAccountSessions,
    queryKey: `${auth.username}-sessions`,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: auth.authState === constants.AUTH_STATE_AUTHENTICATED,
    onError: (e) => {
      if (e instanceof errors.UnauthorizedError) {
        navigate("/login");
      }
    },
  });

  const mutation = useMutation({
    mutationFn: closeAllSessions,
    onSuccess: (data) => {
      popup.showSuccess(
        "Se cerraron todas las sesiones. Vuelva a iniciar sesión."
      );
      navigate("/login");
      auth.clear();
    },
  });

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Sesiones y Dispositivos</b>
      </p>
      <p className="sm left">
        Estas son las sesiones <b>activas</b> de tu cuenta. Puedes cerrar la
        sesión de otros dispositivos de forma remota.
      </p>
      <p className="sm left">
        Si consideras que la seguridad de tu cuenta ha sido vulnerada, te
        recomendamos{" "}
        <b
          className="href link"
          onClick={() =>
            navigate(constants.MY_BUSINESS_ROUTE + "/privacy/change-password")
          }
        >
          cambiar tu contraseña{" "}
        </b>
        y cerrar sesión en todos los dispositivos.
      </p>
      <button
        className="centered semi_rounded linespace-l red"
        onClick={handleCloseAllSessions}
      >
        Cerrar todas las sesiones
      </button>
      {query.isLoading ? (
        <LineLoader />
      ) : (
        <motion.div
          className="card_display"
          variants={container_variants}
          initial="initial"
          animate="animate"
        >
          {Array.isArray(query.data) ? (
            query.data.map((session) => (
              <SessionCard
                key={session._id}
                {...session}
                onClose={refetchSessions}
              />
            ))
          ) : query.data ? (
            <SessionCard {...query.data} onClose={refetchSessions} />
          ) : null}
        </motion.div>
      )}
    </AnimatedPanelPage>
  );
};
