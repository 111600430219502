import { useState } from "react";
import AuthenticationContext from "../contexts/AuthenticationContext";
import { useQuery } from "react-query";
import { getBasicUserInfo } from "../api/fetchers/auth";
import {
  AUTHENTICATED_USER_DATA,
  AUTH_STATE_AUTHENTICATED,
  AUTH_STATE_UNAUTHENTICATED,
  AUTH_STATE_UNKNOWN,
} from "../api/constants";

export const Authenticator = ({ children }) => {
  function login(data) {
    setUserData(data);
    setAuthState(AUTH_STATE_AUTHENTICATED);
  }

  function clear() {
    setUserData(AUTHENTICATED_USER_DATA);
    setAuthState(AUTH_STATE_UNAUTHENTICATED);
  }

  const [userData, setUserData] = useState(AUTHENTICATED_USER_DATA);

  const { refetch } = useQuery({
    queryKey: "get-user-data",
    queryFn: getBasicUserInfo,
    enabled: localStorage.getItem("sessionCookie") !== null,
    onSuccess: (data) => {
      setUserData(data);
      setAuthState(AUTH_STATE_AUTHENTICATED);
    },
    staleTime: 5000, // Avoid double fetching
    onError: () => setAuthState(AUTH_STATE_UNAUTHENTICATED),
  });

  const [authState, setAuthState] = useState(AUTH_STATE_UNKNOWN);

  return (
    <AuthenticationContext.Provider
      value={{ ...userData, refetch, clear, login, authState }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
