import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/PlanCompareTable.css";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { formatPrice } from "../api/utils";
import { InfoTooltip } from "./Tooltip";
import { useDesktop } from "../hooks/useDesktop";
import { SVGIcon } from "./SVGIcon";

export const Feature = ({
  children,
  basic = <Check />,
  premium = <Check />,
}) => {
  return (
    <tr>
      <td>{children}</td>
      <td>{basic}</td>
      <td className="highlighted-cell">{premium}</td>
    </tr>
  );
};

export const Check = () => {
  return <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#00db6e" }} />;
};

export const PlanCompareTable = () => {
  const isDesktop = useDesktop();

  return (
    <table className="plan-compare">
      <colgroup>
        <col />
        <col style={{ minWidth: "110px" }} />
        <col style={{ minWidth: "110px" }} />
      </colgroup>
      <thead>
        <tr>
          <th></th>
          <th>BÁSICO</th>
          <th className="highlighted-cell">PREMIUM</th>
        </tr>
      </thead>
      <tbody>
        <Feature>Página de negocio</Feature>
        <Feature>Catálogo de productos</Feature>
        <Feature basic={8} premium={24}>
          Cantidad de colecciones
        </Feature>
        <Feature basic={100} premium={300}>
          Cantidad de productos por colección
        </Feature>
        <Feature basic={null}>
          Verificación&nbsp;
          <InfoTooltip
            style={{
              width: isDesktop ? "300px" : "200px",
            }}
          >
            <p>
              Si provees los documentos necesarios, tu negocio será verificado y
              tendrá un distintivo{" "}
              <SVGIcon
                icon_name="verified_badge"
                size={12}
                style={{
                  verticalAlign: "middle",
                  color: "var(--main-color)",
                }}
              />{" "}
              de verificación.{" "}
            </p>
          </InfoTooltip>
        </Feature>
        <Feature basic={null}>Prioridad de atención</Feature>
        <Feature
          basic={<b>{formatPrice(19900)} / mes</b>}
          premium={formatPrice(29900) + " / mes"}
        >
          <b>Precio</b>
        </Feature>
        <Feature basic={<b>14 días</b>} premium={null}>
          <b>Prueba gratuita</b>
        </Feature>
      </tbody>
    </table>
  );
};
