import {
  faCheckDouble,
  faFileShield,
  faKey,
  faTablet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { PanelSidebarOption } from "../../components/PanelSideBar";

export const Privacy = () => {
  const navigate = useNavigate();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Privacidad y Seguridad</b>
      </p>
      <PanelSidebarOption text="Tu cuenta" icon={faUser} disabled />
      <PanelSidebarOption
        text="Cambiar contraseña"
        icon={faKey}
        onClick={() => navigate("change-password")}
      />
      <PanelSidebarOption
        text="Doble autenticación"
        icon={faCheckDouble}
        onClick={() => navigate("two-factor-auth")}
      />
      <PanelSidebarOption
        text="Sesiones y dispositivos"
        icon={faTablet}
        onClick={() => navigate("sessions")}
      />
      <PanelSidebarOption
        text="Política de privacidad"
        icon={faFileShield}
        disabled
      />
    </AnimatedPanelPage>
  );
};
