import { cloneElement } from "react";
import * as icons from "../assets/icons";

export const SVGIcon = ({ className, icon_name, style, size = 24 }) => {
  if (typeof icon_name === "undefined") {
    return <></>;
  }

  const element = icons[icon_name];

  if (typeof element === "undefined") {
    return <></>;
  }

  return cloneElement(element, {
    className,
    style: style ? style : null,
    width: size,
    height: size,
  });
};
