import { faBan, faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { PanelSidebarOption } from "../../components/PanelSideBar";

export const BusinessesPage = () => {
  const navigate = useNavigate();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Negocios</b>
      </p>
      <PanelSidebarOption
        icon={faPlus}
        text="Añadir negocio"
        onClick={() => navigate("add-business")}
      />
      <PanelSidebarOption
        icon="verified_badge"
        text="Verificar negocio"
        onClick={() => navigate("verify-business")}
        disabled
      />
      <PanelSidebarOption
        icon={faBan}
        text="Bloquear negocio"
        onClick={() => navigate("block-business")}
        disabled
      />
    </AnimatedPanelPage>
  );
};
