import * as constants from "../../api/constants";
import React from "react";
import { CollectionCard } from "../../components/CollectionCard";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { LineLoader } from "../../components/loaders/LineLoader";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { getCollectionsInfo } from "../../api/fetchers/files";
import { useAuth } from "../../hooks/useAuth";
import { EmptyBoxIllustration } from "../../assets/illustrations";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortCollections } from "../../api/utils";

export const CollectionsPage = () => {
  const auth = useAuth();

  const query = useQuery({
    queryFn: () =>
      getCollectionsInfo(
        {
          business_id: auth.business_id,
        },
        true
      ),
    queryKey: `${auth.username}-collections-panel`,
    staleTime: 60000, // 1 minute
    enabled: auth.authState === constants.AUTH_STATE_AUTHENTICATED,
  });

  const navigate = useNavigate();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Colecciones</b>
        {query.isSuccess ? (
          <b className="text-light s bold" style={{ marginLeft: "12px" }}>
            {query.data.length}/
            {constants.MAX_COLLECTION_AMOUNT_BY_ROLE[parseInt(auth.role)]}
          </b>
        ) : null}
      </p>
      <div
        style={{
          display: "flex",
          gap: "24px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {query.isSuccess ? (
          <>
            {query.data.length > 0 ? (
              sortCollections(query.data).map((col) => {
                return (
                  <CollectionCard
                    key={col._id}
                    name={col.name}
                    pinned={col.pinned}
                    image={col.cover.img}
                    item_count={col.count}
                    onClick={() => navigate(col._id)}
                    state={col.state}
                  />
                );
              })
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <EmptyBoxIllustration />
                <button
                  className="semi_rounded sm"
                  onClick={() =>
                    navigate(
                      constants.MY_BUSINESS_ROUTE + "/catalog/new-collection"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "8px" }}
                  />
                  Crear una colección
                </button>{" "}
              </div>
            )}
          </>
        ) : query.isLoading ? (
          <LineLoader />
        ) : null}
      </div>
    </AnimatedPanelPage>
  );
};
