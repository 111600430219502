import { useState } from "react";
import "../styles/components/PaymentForm.css";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { createCardToken } from "@mercadopago/sdk-react/coreMethods";
import { CardNumberInput } from "./CardNumberInput";
import { getCardBrand, isDigit } from "../api/utils";
import { MercadoPagoSecureCard } from "./MercadoPagoSecureCard";
import { CVVInput } from "./CVVInput";
import { useMutation } from "react-query";
import { usePopup } from "../hooks/usePopup";
import { LineLoader } from "./loaders/LineLoader";

export const PaymentForm = ({
  onContinue,
  onContinueText = "Continuar",
  disabled = false,
}) => {
  function handleExpChange(e, setter) {
    const value = e.target.value;
    if (!isDigit(value) && value !== "") return;
    if (value.length > 2) return;
    setter(value);
  }

  function handleContinue() {
    createTokenMutation.mutate({
      cardNumber: cardNumber,
      cardholderName: name,
      cardExpirationMonth: cardExpMonth,
      cardExpirationYear: cardExpYear,
      securityCode: cardCVV,
      identificationType: idType,
      identificationNumber: identificationNumber,
    });
  }

  function handleIdChange(e) {
    const value = e.target.value;
    if (!isDigit(value) && value !== "") return;
    setIdentificationNumber(value);
  }

  function isValidForm() {
    return (
      cardNumber.length >= 13 &&
      name.length > 0 &&
      cardExpMonth.length === 2 &&
      cardExpYear.length === 2 &&
      cardCVV.length === 3 &&
      idType.length > 0 &&
      identificationNumber.length > 0
    );
  }

  const idTypes = [
    {
      id: "CC",
      name: "C.C.",
      type: "number",
      min_length: 5,
      max_length: 20,
    },
    {
      id: "CE",
      name: "C.E.",
      type: "number",
      min_length: 5,
      max_length: 20,
    },
    {
      id: "NIT",
      name: "NIT",
      type: "number",
      min_length: 5,
      max_length: 20,
    },
    {
      id: "Otro",
      name: "Otro",
      type: "number",
      min_length: 5,
      max_length: 20,
    },
  ];

  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [cardExpYear, setCardExpYear] = useState("");
  const [cardExpMonth, setCardExpMonth] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [idType, setIdType] = useState(idTypes[0].id);
  const [identificationNumber, setIdentificationNumber] = useState("");

  initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {
    trackingDisabled: true,
  });

  const popup = usePopup();

  const createTokenMutation = useMutation({
    mutationFn: createCardToken,
    onSuccess: (cardToken) => {
      popup.showSuccess(
        "Tarjeta verificada. Confirma el pago para cambiar de plan."
      );
      if (onContinue)
        onContinue({
          tokenId: cardToken.id,
          last4Digits: cardToken.last_four_digits,
          cardBrand: getCardBrand(cardNumber),
        });
    },
    onError: () => popup.showError("La tarjeta es inválida."),
  });

  return (
    <div className="payment-form form">
      <p className="sm">
        <b>Información personal</b>
      </p>
      <div className="cardholder-id-container">
        <select
          placeholder="Tipo de documento"
          value={idType}
          onChange={(e) => setIdType(e.target.value)}
          disabled={disabled}
        >
          {idTypes.map((idType) => (
            <option key={idType.id} value={idType.id}>
              {idType.name}
            </option>
          ))}
        </select>
        <input
          className="underlined"
          placeholder="Número de documento"
          value={identificationNumber}
          onChange={handleIdChange}
          maxLength={
            idTypes.find((idType) => idType.id === idType)?.max_length ?? 20
          }
          disabled={disabled}
        />
      </div>

      <p className="sm">
        <b>Información de pago</b>
      </p>
      <CardNumberInput
        state={cardNumber}
        setter={setCardNumber}
        disabled={disabled}
      />
      <input
        className="underlined card-holder-name"
        placeholder="Nombre en la tarjeta"
        value={name}
        onChange={(e) => setName(e.target.value)}
        maxLength={25}
        disabled={disabled}
      />
      <div className="card-exp-container">
        <input
          type="number"
          min={1}
          max={12}
          placeholder="Mes"
          value={cardExpMonth}
          onChange={(e) => handleExpChange(e, setCardExpMonth)}
          disabled={disabled}
        />
        <input
          type="number"
          min={1}
          max={99}
          placeholder="Año"
          value={cardExpYear}
          onChange={(e) => handleExpChange(e, setCardExpYear)}
          disabled={disabled}
        />
        <CVVInput
          state={cardCVV}
          setter={setCardCVV}
          style={{ marginLeft: "12px" }}
          disabled={disabled}
        />
      </div>
      <MercadoPagoSecureCard />
      {createTokenMutation.isLoading && <LineLoader />}
      <button
        className="left semi_rounded"
        onClick={handleContinue}
        disabled={disabled || !isValidForm()}
      >
        {onContinueText}
      </button>
    </div>
  );
};
