import { NAME } from "../api/constants";
import { Content } from "../components/Content";
import "../styles/pages/PrivacyPolicy.css";

export const TermsAndConditions = () => {
  return (
    <Content className="privacy-policy-page centered">
      <h1>Términos y Condiciones</h1>
      <p className="s text-light" style={{ margin: 0 }}>
        28 de Julio de 2023
      </p>
      <hr />
      <p>
        Bienvenid@ a {NAME}. El uso de esta plataforma y sus servicios se
        encuentra sujeto a los siguientes Términos y Condiciones. Al acceder y
        utilizar nuestros servicios, usted acepta quedar vinculado/a por estos
        términos en su totalidad. Si no está de acuerdo con alguno de los
        términos descritos a continuación, por favor, absténgase de utilizar
        nuestra plataforma.
      </p>
      <h2>1. Descripción de uso</h2>
      <p>
        {NAME} ofrece a los usuarios registrados la posibilidad de crear una
        página para su negocio, en la que podrán personalizar información como
        el logo, enlaces a redes sociales y ubicación física del negocio, entre
        otros detalles. Los usuarios pueden crear colecciones y agregar
        productos dentro de ellas.
      </p>
      <h2>2. Planes de Suscripción</h2>
      <p>
        Ofrecemos dos planes de suscripción: Básico y Premium. Los usuarios que
        opten por el plan Básico podrán crear hasta 8 colecciones, con un límite
        de 100 productos dentro de cada una. Por otro lado, los usuarios del
        plan Premium podrán crear hasta 24 colecciones, con un límite de 300
        productos dentro de cada una. Además, los negocios verificados solo
        están disponibles para los usuarios Premium.
      </p>
      <h2 className="indented">2.1. Prueba Gratuita</h2>
      <p className="indented">
        Actualmente, ofrecemos una prueba gratuita de 14 días del plan Básico a
        usuarios nuevos. Una vez completada la prueba gratuita, deberá
        suscribirse a un plan de pago para seguir utilizando nuestros servicios.
      </p>
      <h2>3. Administración de Suscripción</h2>
      <p>
        El proceso de suscripción y facturación está gestionado por la entidad
        externa MercadoPago. Al suscribirse a uno de nuestros planes, el usuario
        acepta los términos y condiciones de MercadoPago.
      </p>
      <h2>4. Verificación de Negocios</h2>
      <p>
        Los usuarios que opten por el plan Premium tendrán la posibilidad de
        verificar su negocio proporcionando los documentos legales requeridos.
        Una vez verificado, se mostrará un distintivo en su página de negocio,
        brindando mayor confianza a los visitantes.
      </p>
      <h2>5. Cancelación de Suscripción</h2>
      <p>
        Los usuarios pueden cancelar su suscripción en cualquier momento desde
        su cuenta. Al cancelar, el usuario mantendrá los beneficios de su plan
        actual hasta que finalice el periodo de facturación actual. No se
        realizarán reembolsos por periodos de suscripción no utilizados.
      </p>
      <h2>6. Regulación de Contenido</h2>
      <h2 className="indented">6.1. Responsabilidad del Contenido</h2>
      <ul>
        <li className="indented">
          <p>
            Los usuarios son los únicos responsables del contenido que publiquen
            en la plataforma, incluyendo imágenes, descripciones, enlaces y
            cualquier otro material relacionado con su negocio o productos.
          </p>
        </li>
        <li className="indented">
          <p>
            Nos reservamos el derecho, pero no asumimos la obligación, de
            supervisar el contenido proporcionado por los usuarios. No
            garantizamos la exactitud, integridad o calidad del contenido, y no
            nos hacemos responsables de ningún error, omisión o contenido
            ofensivo, difamatorio o ilegal proporcionado por los usuarios.
          </p>
        </li>
        <li className="indented">
          <p>
            Siempre nos esforzaremos por mantener un ambiente seguro y
            respetuoso en {NAME}, por lo que nos reservamos el derecho de
            eliminar o modificar cualquier contenido que consideremos
            inapropiado o que viole estos Términos y Condiciones.
          </p>
        </li>
      </ul>
      <h2 className="indented">6.2. Derechos de Propiedad Intelectual</h2>
      <ul>
        <li className="indented">
          <p>
            Los usuarios garantizan que tienen todos los derechos necesarios,
            incluyendo los derechos de propiedad intelectual, para publicar el
            contenido en la plataforma. Al publicar contenido, los usuarios
            otorgan a {NAME} una licencia no exclusiva, transferible,
            sublicenciable, libre de regalías y a nivel mundial para utilizar,
            reproducir, modificar, adaptar, publicar, traducir y distribuir
            dicho contenido con el propósito de operar y promover el sitio web.
          </p>
        </li>
        <li className="indented">
          <p>
            Cualquier infracción de derechos de propiedad intelectual será
            responsabilidad exclusiva del usuario que haya proporcionado el
            contenido infractor. Si recibimos una notificación válida de que el
            contenido infringe los derechos de propiedad intelectual de un
            tercero, nos reservamos el derecho de eliminar dicho contenido y
            tomar las medidas adecuadas según lo estipulado por las leyes
            aplicables.
          </p>
        </li>
      </ul>
      <h2 className="indented">6.3. Contenido Prohibido</h2>
      <ul>
        <li className="indented">
          <p>
            Los usuarios deben abstenerse de publicar contenido que sea ilegal,
            ofensivo, difamatorio, obsceno, violento, amenazante, acosador,
            discriminatorio, o que viole los derechos de terceros.
          </p>
        </li>
        <li className="indented">
          <p>
            Está prohibido publicar contenido que contenga virus, malware,
            spyware u otros elementos dañinos que puedan afectar la seguridad o
            funcionamiento de la plataforma o de los dispositivos de otros
            usuarios.
          </p>
        </li>
        <li className="indented">
          <p>
            Nos reservamos el derecho de decidir discrecionalmente qué contenido
            se considera inapropiado o prohibido y tomar las medidas
            correspondientes, incluyendo la eliminación del contenido y la
            suspensión o cancelación de la cuenta del usuario infractor.
          </p>
        </li>
      </ul>
      <h2 className="indented">6.4. Notificación de Contenido Inapropiado</h2>
      <p className="indented">
        Si un usuario considera que algún contenido publicado es inapropiado,
        ofensivo o infringe sus derechos de propiedad intelectual, puede ponerse
        en contacto con nosotros a través de los canales de soporte
        proporcionados en la página web. Revisaremos las notificaciones de
        manera oportuna y tomaremos las acciones adecuadas según corresponda.
      </p>
      <h2 className="indented">
        6.5. Modificaciones a la Regulación de Contenido
      </h2>
      <p className="indented">
        Nos reservamos el derecho de modificar la presente sección de regulación
        de contenido en cualquier momento. Cualquier cambio se notificará a los
        usuarios mediante la plataforma o mediante el correo electrónico
        asociado a su cuenta.
      </p>
      <p className="indented">
        Es importante que los usuarios comprendan la importancia de cumplir con
        las regulaciones de contenido establecidas y que sepan que la
        publicación de contenido inapropiado o infractor puede resultar en la
        eliminación del mismo y en consecuencias adicionales según lo estipulado
        por las leyes aplicables.
      </p>
      <h2>7. Responsabilidad del Usuario</h2>
      <p>
        El usuario es el único responsable de mantener la confidencialidad de su
        cuenta y contraseña, y de restringir el acceso a su dispositivo para
        evitar cualquier uso no autorizado de su cuenta.
      </p>
      <h2>8. Modificaciones en los Términos y Condiciones</h2>
      <p>
        Nos reservamos el derecho de modificar estos Términos y Condiciones en
        cualquier momento. Cualquier cambio se notificará a los usuarios
        mediante la plataforma o mediante el correo electrónico asociado a su
        cuenta.
      </p>
      <h2>9. Terminación del Servicio</h2>
      <p>
        Nos reservamos el derecho de cancelar o suspender el acceso a {NAME} y
        sus servicios en cualquier momento, sin previo aviso y por cualquier
        razón, incluyendo, pero no limitado a, violaciones de los Términos y
        Condiciones.
      </p>
    </Content>
  );
};
