import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { CodeInput } from "../../components/CodeInput";
import { Loader } from "../../components/loaders/Loader";
import * as constants from "../../api/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { validateTwoStepAuthToken } from "../../api/fetchers/auth";
import { Content } from "../../components/Content";
import { useMutation } from "react-query";
import { UnauthorizedError } from "../../api/errors";
import { usePopup } from "../../hooks/usePopup";
import { useAuth } from "../../hooks/useAuth";

export const TwoStepAuth = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === null) {
      navigate(constants.LOGIN_ROUTE);
    }
  }, [state, navigate]);

  function clearCodeInput() {
    setCode("");
  }

  const popup = usePopup();
  const auth = useAuth();

  const { isLoading, mutate } = useMutation({
    mutationFn: validateTwoStepAuthToken,
    mutationKey: "two-step-auth",
    onSuccess: (data) => {
      auth.login(data);
      if (data.role === constants.ADMIN_ROLE) {
        navigate(constants.ADMIN_PANEL_ROUTE);
      } else {
        navigate(constants.MY_BUSINESS_ROUTE);
      }
      popup.showSuccess("Sesión iniciada");
    },
    onError: (error) => {
      setCode("");
      if (error instanceof UnauthorizedError) {
        popup.showError("Código inválido");
      } else {
        popup.showError("Ocurrió un error inesperado");
      }
    },
  });

  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === constants.CODE_LENGTH && !isLoading) {
      mutate({ _id: state._id, value: code });
    }
  }, [code, isLoading, mutate, state._id]);

  if (state === null) {
    return (
      <p className="centered l">
        <b>Redireccionando...</b>
      </p>
    );
  }

  return (
    <Content>
      <div style={{ paddingTop: "30px" }}>
        <Helmet title="Autenticación" />
        <div className="container centered semi_rounded form">
          <p className="l centered">
            <b>Autenticación en dos pasos</b>
          </p>
          <p className="sm centered">
            Se envió un código de verificación a <b>{state.email}</b>
          </p>
          <CodeInput
            className="centered"
            length={constants.CODE_LENGTH}
            state={code}
            setter={setCode}
            disabled={isLoading}
          />
          <button
            className="centered"
            onClick={clearCodeInput}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faEraser} />
          </button>
          {isLoading ? (
            <Loader className="centered" style={{ width: "fit-content" }} />
          ) : null}
        </div>
      </div>{" "}
    </Content>
  );
};
