import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { PASSWORD_ALLOWED_CHARACTERS } from "../api/constants";
import "../styles/components/PasswordInput.css";

export const PasswordInput = ({
  ref,
  className,
  inputClassName,
  style,
  inputStyle,
  state,
  setter,
  placeholder,
}) => {
  function handleChange(e) {
    for (let i = 0; i < e.target.value.length; i++) {
      if (!PASSWORD_ALLOWED_CHARACTERS.includes(e.target.value.charAt(i))) {
        return;
      }
    }

    try {
      setter(e.target.value);
    } catch {}
  }

  const [hidden, setHidden] = useState(true);

  return (
    <div
      className={"password-input" + (className ? className : "")}
      style={style}
    >
      <input
        ref={ref}
        className={inputClassName}
        style={{ width: "100%", ...inputStyle }}
        placeholder={placeholder}
        type={hidden ? "password" : "text"}
        value={state}
        onChange={handleChange}
      />
      <FontAwesomeIcon
        onClick={() => setHidden(!hidden)}
        icon={hidden ? faEye : faEyeSlash}
      />
    </div>
  );
};
