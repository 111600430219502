import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import * as utils from "../api/utils";
import "../styles/components/EditableText.css";

export const EditableText = ({
  state,
  setter,
  onChange,
  inputType,
  ...inputProps
}) => {
  function handleChange(e) {
    var str = e.target.value;
    if (inputType === "number") {
      if (!utils.isDigit(str) && str !== "") {
        return;
      }
    }
    setter(e.target.value);
    try {
      onChange();
    } catch {}
  }

  const inputRef = useRef();

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        gap: "6px",
        width: "fit-content",
        alignItems: "center",
      }}
    >
      <input
        ref={inputRef}
        className="editable"
        value={state}
        onChange={handleChange}
        {...inputProps}
      />
      <FontAwesomeIcon
        className="button"
        onClick={() => inputRef.current.focus()}
        fontSize={12}
        icon={faPenToSquare}
      />
    </div>
  );
};
