import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ProgressBar } from "../ProgressBar";
import { FADE, POPUP } from "../animations/framer-animations";
import { SimpleSpinner } from "../loaders/SimpleSpinner";
import "../../styles/components/Modals.css";

export const ProgressBarModal = forwardRef((props, ref) => {
  function handleFinish() {
    try {
      info.onFinish();
    } catch {}
    setTimeout(() => {
      setHidden(true);
    }, 600);
  }

  function show(percentage, text, onFinish) {
    if (hidden) {
      setHidden(false);
    }

    setInfo({
      text: text,
      percentage: percentage,
      onFinish: onFinish,
    });
  }

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const [hidden, setHidden] = useState(true);
  const [info, setInfo] = useState({});

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="modal_container"
          variants={FADE}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <motion.div
            className="modal rounded"
            variants={POPUP}
            initial="initial"
            exit="exit"
            animate="animate"
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <SimpleSpinner style={{ display: "table", margin: "0 auto" }} />
            <ProgressBar
              percent={info.percentage}
              text={info.text}
              onCompleted={handleFinish}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
