import "../../styles/components/Animations.css";

export const SuccessCheckAnimation = () => {
  return (
    <div className="f-modal-alert">
      <div className="f-modal-icon f-modal-success animate">
        <span className="f-modal-line f-modal-tip animateSuccessTip"></span>
        <span className="f-modal-line f-modal-long animateSuccessLong"></span>
        <div className="f-modal-placeholder"></div>
        <div className="f-modal-fix"></div>
      </div>
    </div>
  );
};

export const WarningIconAnimation = () => {
  return (
    <div className="f-modal-alert">
      <div className="f-modal-icon f-modal-warning scaleWarning">
        <span className="f-modal-body pulseWarningIns"></span>
        <span className="f-modal-dot pulseWarningIns"></span>
      </div>
    </div>
  );
};

export const ErrorIconAnimation = () => {
  return (
    <div className="f-modal-alert">
      <div className="f-modal-icon f-modal-error animate">
        <span className="f-modal-x-mark">
          <span className="f-modal-line f-modal-left animateXLeft"></span>
          <span className="f-modal-line f-modal-right animateXRight"></span>
        </span>
        <div className="f-modal-placeholder"></div>
        <div className="f-modal-fix"></div>
      </div>
    </div>
  );
};
