import axios from "axios";
import * as errors from "../errors";

const API = process.env.REACT_APP_API_URL;

const fetcher = axios.create({
  baseURL: API + "/forms",
});

export async function signup(data) {
  try {
    await fetcher.post("/signup", {
      _class: "signup",
      data: data,
    });
    return {};
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function cancelSubscriptionForm({ reasons, otherReasons }) {
  try {
    await fetcher.post("/cancel-subscription", {
      reasons: reasons,
      other_reason: otherReasons,
    });
    return {};
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}
