import { faImage, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import * as utils from "../api/utils";
import * as constants from "../api/constants";
import "./../styles/components/FileInput.css";
import { usePopup } from "../hooks/usePopup";

export const FileIcon = ({ filename }) => {
  if (!filename) {
    return <></>;
  }

  const ext = utils.getExtension(filename);

  if (constants.IMAGE_ALLOWED_EXTENSIONS.includes(ext)) {
    return <FontAwesomeIcon className="file_icon img" icon={faImage} />;
  }
};

export const FileInput = ({ state, setter, allowExtensions, popup = true }) => {
  function handleChange(e) {
    if (e.target.files.length > 0) {
      if (
        Array.isArray(allowExtensions) &&
        !allowExtensions.includes(utils.getExtension(e.target.files[0].name))
      ) {
        if (popup) {
          popup_.showError(
            `El archivo seleccionado no es una imagen válida. (Selecciona un archivo ${allowExtensions.join(
              ", "
            )})`
          );
        }
        return;
      }
      setter(e.target.files[0]);
    }
  }

  function clear() {
    setter({});
  }

  const actualFileInputRef = useRef(null);
  const popup_ = usePopup();

  return (
    <div className="file_input semi_rounded">
      <input
        className="hidden"
        ref={actualFileInputRef}
        type="file"
        onChange={handleChange}
      />
      <p className="s">
        <FileIcon filename={state?.name} />
        {state.name ? state.name : "Ningún archivo seleccionado..."}
      </p>
      <div className="file-input-controls">
        {state.name ? (
          <button className="white semi_rounded" onClick={clear}>
            <FontAwesomeIcon icon={faXmarkCircle} />
          </button>
        ) : (
          <button
            className="s semi_rounded"
            onClick={() => {
              actualFileInputRef.current.click();
            }}
          >
            Seleccionar
          </button>
        )}
      </div>
    </div>
  );
};
