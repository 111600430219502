import React from "react";
import { motion } from "framer-motion";

const animations = {
  initial: { opacity: 0, x: 200 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -200 },
};

const transition = { duration: 0.5 };

export const AnimatedPanelPage = ({ children, style }) => {
  return (
    <motion.div
      className="form"
      variants={animations}
      style={style}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={transition}
    >
      {children}
    </motion.div>
  );
};
