import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/CheckBox.css";

export const CheckBox = ({ style, state, setter }) => {
  function handleClick() {
    if (state) {
      setter(false);
    } else {
      setter(true);
    }
  }

  return (
    <button
      className={`checkbox${state ? " selected" : ""}`}
      style={style}
      onClick={handleClick}
    >
      {state ? <FontAwesomeIcon icon={faCheck} /> : null}
    </button>
  );
};
