import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { usePopup } from "../../hooks/usePopup";
import { AUTH_STATE_AUTHENTICATED, COUNTRY_LIST } from "../../api/constants";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getBusinessInfo, updateBusiness } from "../../api/fetchers/main";
import {
  faCity,
  faEarthAmerica,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ChangeableField } from "../../components/ChangeableField";
import { getNameFromValue } from "../../api/utils";
import { useState } from "react";
import { TaggedInput } from "../../components/TaggedInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LineLoader } from "../../components/loaders/LineLoader";

export const LocationPage = () => {
  function handleCountryChange(value) {
    popup.showConfirm(
      "¿Estás seguro que quieres cambiar el país de tu negocio?",
      () => {
        updateMutation.mutate({
          location: {
            ...data.location,
            country: value,
          },
        });
      }
    );
  }

  function handleCityUpdate() {
    popup.showConfirm(
      "¿Estás seguro que quieres cambiar la ciudad de tu negocio?",
      () => {
        updateMutation.mutate({
          location: {
            ...data.location,
            city,
          },
        });
      }
    );
  }

  function handleLocationDelete() {
    popup.showConfirm(
      "¿Estás seguro que quieres eliminar la ubicación de tu negocio?",
      () => {
        updateMutation.mutate(
          {
            location: {
              country: null,
              city: null,
            },
          },
          {
            onSuccess: () => {
              setCity("");
              setCityUpdated(false);
            },
          }
        );
      }
    );
  }

  const popup = usePopup();
  const auth = useAuth();
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, data } = useQuery({
    queryFn: () => getBusinessInfo({ business_id: auth.business_id }),
    queryKey: `basic-business-info-${auth.business_id}`,
    enabled: auth.authState === AUTH_STATE_AUTHENTICATED,
    staleTime: 600000,
    onSuccess: (data) => {
      if (data.location) {
        setCity(data.location.city);
      }
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      popup.showSuccess("Se actualizó el país de tu negocio");
      queryClient.invalidateQueries(`basic-business-info-${auth.business_id}`);
      queryClient.invalidateQueries(`${auth.username}-header`);
    },
  });

  const [city, setCity] = useState(data?.location?.city || "");
  const [cityUpdated, setCityUpdated] = useState(false);

  return (
    <AnimatedPanelPage>
      <p className="l linespace-l">
        <b>Tu Ubicación</b>
      </p>
      {(isLoading || updateMutation.isLoading) && <LineLoader />}
      <p>
        <b>País</b>
      </p>
      <ChangeableField
        className="linespace-sm"
        text={
          isSuccess && data.location.country
            ? getNameFromValue(data.location.country)
            : isLoading
            ? "Cargando..."
            : "Agrega tu país"
        }
        icon={faEarthAmerica}
        onClick={() =>
          popup.showSelectOptions(COUNTRY_LIST, handleCountryChange)
        }
      />
      <p>
        <b>Ciudad</b>
      </p>
      <TaggedInput>
        <FontAwesomeIcon icon={faCity} className="text-light" />
        <input
          placeholder={isLoading ? "Cargando..." : "Agrega tu ciudad"}
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
            setCityUpdated(true);
          }}
        />
      </TaggedInput>
      <button
        className="semi_rounded left"
        disabled={!cityUpdated}
        onClick={handleCityUpdate}
      >
        Actualizar ciudad
      </button>
      <hr style={{ width: "100%" }} />
      <button
        className="wide semi_rounded left red"
        onClick={handleLocationDelete}
      >
        <FontAwesomeIcon
          icon={faTrash}
          style={{
            marginRight: "12px",
          }}
        />
        Borrar ubicación de mi negocio
      </button>
    </AnimatedPanelPage>
  );
};
