import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/LocationPin.css";
import { getNameFromValue } from "../api/utils";

export const LocationPin = ({ city, country, style }) => {
  country = getNameFromValue(country);

  return (
    <p className="location-pin" style={style}>
      <FontAwesomeIcon icon={faLocationDot} />
      {country && city
        ? `${city}, ${country}`
        : country
        ? country
        : city
        ? city
        : null}
    </p>
  );
};
