import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ImagePlaceholder } from "../Image";
import "../../styles/components/Modals.css";

export const ImageModal = forwardRef((props, ref) => {
  function onClose() {
    try {
      setDisplay("none");
      onCloseFunc();
    } catch {}
  }

  function show(source, onCloseF) {
    setSource(source);
    setOnCloseFunc(onCloseF);
    setDisplay("var(--modal-display)");
  }

  const [display, setDisplay] = useState("none");
  const [onCloseFunc, setOnCloseFunc] = useState(() => {});
  const [source, setSource] = useState({});

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <div className="modal_container" style={{ display: display }}>
      <div
        style={{
          width: "90vw",
          height: "90vh",
          maxWidth: "90vw",
          maxHeight: "90vh",
        }}
      >
        <ImagePlaceholder
          src={source}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <FontAwesomeIcon
        className="button"
        style={{
          position: "absolute",
          right: "40px",
          top: "40px",
          fontSize: "40px",
          color: "white",
        }}
        icon={faXmark}
        onClick={onClose}
      />
    </div>
  );
});
