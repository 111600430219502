import { faCircle, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SVGIcon } from "./SVGIcon";
import "../styles/components/PaymentMethod.css";
import { AVAILABLE_CARDS } from "../api/constants";

export const PaymentMethod = ({
  cardBrand,
  last4,
  onChangeClick,
  changeButton = true,
}) => {
  return (
    <div className="container semi_rounded payment-method-container">
      <div className="payment-method">
        {typeof last4 !== "undefined" ? (
          <>
            {AVAILABLE_CARDS.includes(cardBrand) ? (
              <SVGIcon size={32} icon_name={cardBrand} />
            ) : (
              <FontAwesomeIcon
                icon={faCreditCard}
                style={{ fontSize: "24px" }}
              />
            )}
            <FontAwesomeIcon
              icon={faCircle}
              style={{ fontSize: "5px", color: "var(--color5)" }}
            />
            <p className="st">
              Terminada en <b>{last4}</b>
            </p>
          </>
        ) : (
          <p className="st">No has configurado un método de pago.</p>
        )}
      </div>
      {changeButton && (
        <button className="semi_rounded" onClick={onChangeClick}>
          Cambiar
        </button>
      )}
    </div>
  );
};
