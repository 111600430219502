import "../styles/components/Separator.css";

const Separator = ({ text, children, className }) => {
  return (
    <div className={`text-divider bold text-light sm ${className ?? ""}`}>
      {text}
      {children}
    </div>
  );
};

export default Separator;
