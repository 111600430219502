import { useMutation, useQuery, useQueryClient } from "react-query";
import { BillRecord } from "../../components/BillRecord";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { useAuth } from "../../hooks/useAuth";
import { getUserBills } from "../../api/fetchers/payments";
import { LineLoader } from "../../components/loaders/LineLoader";
import { MercadoPagoSecureCard } from "../../components/MercadoPagoSecureCard";
import { useState } from "react";

const BILLS_PER_QUERY = 12;

export const BillsPage = () => {
  const auth = useAuth();

  const queryClient = useQueryClient();

  const { data, isLoading, isSuccess } = useQuery({
    queryFn: getUserBills,
    queryKey: `${auth.username}-bills`,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: auth.authState === "authenticated",
    onSuccess: (newData) => {
      setItemsRemaining(newData.length === BILLS_PER_QUERY);
    },
  });

  const mutation = useMutation({
    mutationFn: getUserBills,
    onSuccess: (newData) => {
      queryClient.setQueryData(`${auth.username}-bills`, [...data, ...newData]);
      setItemsRemaining(newData.length === BILLS_PER_QUERY);
    },
  });

  const [itemsRemaining, setItemsRemaining] = useState(false);

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Facturas</b>
      </p>
      {isLoading && <LineLoader />}
      <MercadoPagoSecureCard
        text={
          <b>
            Las siguientes facturas son suministradas por{" "}
            <b style={{ color: "#00bcff" }}>MercadoPago</b>.
          </b>
        }
        buttonText=""
      />
      <div className="table_container">
        <table className="bills">
          <thead>
            <tr>
              <th>Identificador</th>
              <th>Estado</th>
              <th>Fecha</th>
              <th>Monto</th>
            </tr>
          </thead>
          {isSuccess && (
            <tbody>
              {data.map((bill) => (
                <BillRecord key={bill.id} {...bill} />
              ))}
            </tbody>
          )}
        </table>
        {itemsRemaining && (
          <button
            className="semi_rounded transparent"
            onClick={() => mutation.mutate({ offset: data.length })}
          >
            Cargar más
          </button>
        )}
      </div>
    </AnimatedPanelPage>
  );
};
