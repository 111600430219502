import { useNavigate } from "react-router";
import { NotFoundIllustration } from "../assets/illustrations";
import { Content } from "../components/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <div
        style={{
          width: "100%",
          maxWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <NotFoundIllustration style={{ maxWidth: "100%", height: "auto" }} />
        <button className="semi_rounded sm" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faHouse} style={{ marginRight: "8px" }} />
          Regresar al inicio
        </button>
      </div>
    </Content>
  );
};
