import {
  faBell,
  faCircleExclamation,
  faImagePortrait,
  faWallet,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as utils from "../api/utils";
import { LineLoader } from "./loaders/LineLoader";
import { AnimatePresence, motion } from "framer-motion";
import { FADE_SLIDEUP } from "./animations/framer-animations";
import "../styles/components/NotificationButton.css";
import {
  discardNotification,
  getNotifications,
} from "../api/fetchers/notifications";
import { useAuth } from "../hooks/useAuth";
import { OpenEnvelope } from "../assets/illustrations";

const Notification = ({ data }) => {
  function invalidateNotifications(res) {
    queryClient.invalidateQueries({
      queryKey: `${auth.username}-notifications`,
    });
  }

  const queryClient = useQueryClient();

  const auth = useAuth();

  const discardMutation = useMutation({
    mutationFn: () => discardNotification(data._id),
    onSuccess: invalidateNotifications,
  });

  return (
    <a href={data.link} target="_blank" rel="noreferrer">
      <div className="notification">
        <div className="icon_circle">
          {data.entity === 1 ? (
            <FontAwesomeIcon icon={faWallet} />
          ) : data.entity === 2 ? (
            <FontAwesomeIcon icon={faImagePortrait} />
          ) : (
            <FontAwesomeIcon icon={faCircleExclamation} />
          )}
        </div>
        <p className="s">{utils.getNotificationMessage(data)}</p>
        <div
          className="notification-discard button"
          onClick={discardMutation.mutate}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
    </a>
  );
};

const NotificationsContainer = () => {
  const auth = useAuth();

  const { data, isSuccess, isLoading } = useQuery({
    queryFn: getNotifications,
    queryKey: `${auth.username}-notifications`,
    staleTime: 60000,
  });

  return (
    <motion.div
      className="notification_container"
      variants={FADE_SLIDEUP}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={FADE_SLIDEUP.transition}
    >
      <p className="sm bold" style={{ marginTop: "16px", marginLeft: "20px" }}>
        Notificaciones
      </p>
      {isLoading ? <LineLoader style={{ marginTop: "20px" }} /> : null}
      {isSuccess ? (
        data.length > 0 ? (
          data.map((n) => <Notification key={n._id} data={n} />)
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <OpenEnvelope />
            <p className="text-light">
              <b>No tienes notificaciones</b>
            </p>
          </div>
        )
      ) : null}
    </motion.div>
  );
};

export const NotificationButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AnimatePresence>
        {open ? <NotificationsContainer /> : null}
      </AnimatePresence>
      <button className="notification" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faBell} />
      </button>
    </>
  );
};
