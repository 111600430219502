export const POPUP = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
  },
  exit: {
    scale: 0,
  },
  transition: {
    type: "tween",
    duration: 0.1,
  },
};

export const SLIDE = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 100,
  },
  transition: {
    ease: "linear",
    duration: 0.5,
  },
};

export const FADE = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transitionDelay: 0.3,
    transition: {
      delay: 0.25,
    },
  },
};

export const FADE_SLIDEUP = {
  initial: {
    y: 80,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 80,
    opacity: 0,
  },
  transition: {
    ease: "linear",
    duration: 0.2,
  },
};

const DROPDOWN_DURATION = 0.3;

export const DROPDOWN = {
  initial: {
    scaleY: 0,
  },
  animate: {
    scaleY: 1,
  },
  exit: {
    scaleY: 0,
  },
  transition: {
    ease: "easeIn",
    duration: DROPDOWN_DURATION,
  },
};

export const DROPDOWN_CONTENT_ANIMATION = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: DROPDOWN_DURATION,
  },
};

export const SLIDEUP = {
  initial: {
    y: "100vh",
  },
  animate: {
    y: 0,
  },
  exit: {
    y: "100vh",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.275,
  },
};
