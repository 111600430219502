import usePasswordStrength from "../hooks/usePasswordStrength";

export const PasswordStrengthLabel = ({ password, style }) => {
  const strength = formatPasswordStrength(usePasswordStrength(password));

  return (
    <b
      style={{
        color:
          strength === "Muy alta"
            ? "#00910f"
            : strength === "Alta"
            ? "#36d646"
            : strength === "Media"
            ? "#c28100"
            : "#db3312",
        ...style,
      }}
      className="bold codetext"
    >
      {strength}
    </b>
  );
};

export function formatPasswordStrength(strength) {
  if (strength >= 4) {
    return "Muy alta";
  } else if (strength === 3) {
    return "Alta";
  } else if (strength === 2) {
    return "Media";
  } else {
    return "Baja";
  }
}
