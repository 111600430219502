import { FormErrorMsg, FormInfoMsg } from "../../components/FormMessages";
import { useMutation } from "react-query";
import { AnimatedBusinessPage } from "../../components/animations/AnimatedBusinessPage";
import * as constants from "../../api/constants";
import { isValidPassword, isValidUsername } from "../../api/validators";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router";
import { PasswordInput } from "../../components/PasswordInput";
import { LineLoader } from "../../components/loaders/LineLoader";
import preventLeaving from "../../components/effects/preventLeaving";
import { PasswordStrengthLabel } from "../../components/PasswordStrengthLabel";
import { usePopup } from "../../hooks/usePopup";
import { requestPasswordReset, resetPassword } from "../../api/fetchers/auth";
import { Content } from "../../components/Content";

export const ResetPassword = () => {
  function handleReset() {
    resetPasswordMutation.mutate({
      token: token,
      password: newPass,
    });
  }

  const { token } = useParams();

  const popup = usePopup();

  const navigate = useNavigate();

  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () =>
      popup.showSuccess(
        "¡Has cambiado tu contraseña! Ya puedes iniciar sesión.",
        () => navigate(constants.LOGIN_ROUTE)
      ),
  });

  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");

  useEffect(preventLeaving);

  return (
    <div className="centered_content">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "400px",
          maxWidth: "100%",
        }}
      >
        <p className="l bold">Reestablecer contraseña</p>
        <p className="sm">Asigna una nueva contraseña a tu cuenta:</p>
        <PasswordInput
          style={{ width: "100%" }}
          inputClassName="underlined"
          placeholder="Nueva contraseña"
          state={newPass}
          setter={setNewPass}
        />
        <PasswordInput
          style={{ width: "100%" }}
          inputClassName="underlined"
          placeholder="Confirma contraseña"
          state={newPassConf}
          setter={setNewPassConf}
        />
        <p className="st text-light right">
          Ingresa una contraseña de {constants.PASSWORD_MIN_LENGTH} a{" "}
          {constants.PASSWORD_MAX_LENGTH} caracteres
        </p>
        <p className="s linespace-m">
          Seguridad: <PasswordStrengthLabel password={newPass} />
        </p>
        {newPass !== newPassConf ? (
          <FormErrorMsg text="Las contraseñas no coinciden." />
        ) : null}
        {resetPasswordMutation.isLoading ? <LineLoader /> : null}
        <button
          className="bold semi_rounded"
          onClick={handleReset}
          disabled={
            !isValidPassword(newPass) ||
            newPass !== newPassConf ||
            resetPasswordMutation.isLoading
          }
        >
          Reestablecer
        </button>
      </div>
    </div>
  );
};

export const RequestPasswordReset = () => {
  const MailSent = () => {
    return (
      <AnimatedBusinessPage
        className="container semi_rounded"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          width: "500px",
          maxWidth: "100%",
          padding: "20px",
        }}
      >
        <p className="l bold">Revisa tu correo</p>
        <p className="sm" style={{ textAlign: "center" }}>
          Te hemos enviado un correo electrónico a{" "}
          <b>{requestMutation.data.email}</b> con un enlace para recuperar tu
          contraseña.
        </p>
        <FontAwesomeIcon
          icon={faEnvelope}
          style={{ fontSize: "58px", color: "var(--color5)" }}
        />
      </AnimatedBusinessPage>
    );
  };

  function handleSendRequest() {
    requestMutation.mutate({
      username,
    });
  }

  const requestMutation = useMutation({
    mutationFn: requestPasswordReset,
  });

  const [username, setUsername] = useState("");

  return (
    <Content>
      <div className="centered_content">
        {requestMutation.isSuccess ? (
          <MailSent />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "400px",
              maxWidth: "100%",
            }}
          >
            <p className="l bold">Recuperar contraseña</p>
            <input
              className="underlined"
              placeholder="Nombre de usuario"
              maxLength={constants.USERNAME_MAX_LENGTH}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormInfoMsg text="Te enviaremos un enlace a tu correo electrónico para reestrablecer tu contraseña." />
            {requestMutation.isLoading ? (
              <LineLoader />
            ) : requestMutation.isError ? (
              <FormErrorMsg text="No se encontró el usuario" />
            ) : null}
            <button
              className="semi_rounded bold"
              disabled={!isValidUsername(username) || requestMutation.isLoading}
              onClick={handleSendRequest}
            >
              Enviar enlace
            </button>
          </div>
        )}
      </div>
    </Content>
  );
};
