import { isDigit } from "../api/utils";
import { SVGIcon } from "./SVGIcon";
import { TaggedInput } from "./TaggedInput";

export const CVVInput = ({ state, setter, style, disabled = false }) => {
  function handleChange(e) {
    const value = e.target.value;
    if (!isDigit(value) && value !== "") return;
    if (value.length > 3) return;
    setter(value);
  }

  return (
    <TaggedInput style={style}>
      <SVGIcon icon_name="password" size={16} />
      <input
        type="number"
        min={1}
        max={999}
        placeholder="CVV"
        value={state}
        onChange={handleChange}
        disabled={disabled}
      />
    </TaggedInput>
  );
};
