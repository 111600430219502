import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/ChangeableField.css";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";

export const ChangeableField = ({
  text,
  icon,
  light = false,
  onClick,
  className,
}) => {
  return (
    <div
      className={`changeable-field${className ? " " + className : ""}`}
      onClick={onClick}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={light ? "text-light" : undefined}
        />
      )}
      <p className={light ? "text-light" : undefined}>
        <b>{text}</b>
      </p>
      <FontAwesomeIcon icon={faRepeat} className="text-light" />
    </div>
  );
};
