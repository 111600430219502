import { FLAG_ICONS, PHONE_CODES } from "../api/constants";
import { isDigit } from "../api/utils";
import "../styles/components/InputTag.css";

export const PhoneInput = ({
  numberState,
  numberSetter,
  codeState,
  codeSetter,
}) => {
  function handleChange(e) {
    if (isDigit(e.target.value) || e.target.value === "") {
      numberSetter(e.target.value);
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="input_tag">
        <img
          src={FLAG_ICONS.colombia}
          style={{ width: "16px", height: "16px", marginRight: "8px" }}
          alt="Colombia Flag"
          className="country_code"
        />
        <p className="s">
          <b>{PHONE_CODES.colombia}</b>
        </p>
      </div>
      <input
        value={numberState}
        onChange={handleChange}
        placeholder="Número de celular"
        maxLength={10}
      />
    </div>
  );
};
