import { useEffect, useState } from "react";
import {
  AUTH_STATE_UNKNOWN,
  BASIC_PLAN,
  MEMBERSHIP_ROUTE,
  NAME,
  PREMIUM_PLAN,
} from "../../api/constants";
import { formatPrice } from "../../api/utils";
import {
  DropdownSelect,
  DropdownSelectOption,
} from "../../components/DropdownSelect";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { FormInfoMsg } from "../../components/FormMessages";
import { useAuth } from "../../hooks/useAuth";
import { useMutation } from "react-query";
import { initializeSubscription } from "../../api/fetchers/payments";
import { usePopup } from "../../hooks/usePopup";
import { LineLoader } from "../../components/loaders/LineLoader";
import { MercadoPagoSecureCard } from "../../components/MercadoPagoSecureCard";
import { SubscriptionStatus } from "../../components/SubscriptionStatus";
import { useNavigate } from "react-router";

export const UpgradeToPaidPage = () => {
  function updatePreapproval({ id, init_point }) {
    auth.login({
      ...auth,
      subscription: {
        ...auth.subscription,
        preapproval: {
          ...auth.subscription.preapproval,
          id: id,
          init_point: init_point,
        },
      },
    });
  }

  function handleContinue() {
    initMutation.mutate({
      plan_id: plan,
    });
  }

  const auth = useAuth();
  const popup = usePopup();

  const navigate = useNavigate();

  const [plan, setPlan] = useState("");

  const initMutation = useMutation({
    mutationFn: initializeSubscription,
    onSuccess: (data) => updatePreapproval(data),
    onError: () =>
      popup.showError(
        "No se pudo inicializar tu suscripción. Intenta de nuevo."
      ),
  });

  useEffect(() => {
    if (
      auth.subscription.plan.id !== "free-trial" &&
      auth.subscription.status === "active" &&
      !auth.subscription.plan.exp
    ) {
      popup.showSuccess(
        <>
          <b>¡Te has suscrito!</b>
          <br />
          Ya puedes disfrutar de los beneficios de tu plan.
        </>
      );
      navigate("./../");
    }
  }, [auth, popup, navigate]);

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Suscribirse a un plan de pago</b>
      </p>
      {auth.authState === AUTH_STATE_UNKNOWN && <LineLoader />}
      <SubscriptionStatus
        exp={auth.subscription.plan.exp}
        plan={auth.subscription.plan.id}
        status={auth.subscription.status}
        buttonText=""
      />
      <DropdownSelect
        defaultText="Escoge nuevo plan..."
        text={
          plan === BASIC_PLAN.id
            ? BASIC_PLAN.name
            : plan === PREMIUM_PLAN.id
            ? PREMIUM_PLAN.name
            : null
        }
        setter={setPlan}
      >
        <DropdownSelectOption
          text={`${BASIC_PLAN.name} (${formatPrice(
            BASIC_PLAN.price + "900"
          )} / mes)`}
          value={BASIC_PLAN.id}
        />
        <DropdownSelectOption
          text={`${PREMIUM_PLAN.name} (${formatPrice(
            PREMIUM_PLAN.price + "900"
          )} / mes)`}
          value={PREMIUM_PLAN.id}
        />
      </DropdownSelect>
      <button
        className="semi_rounded left"
        disabled={
          !plan ||
          initMutation.isLoading ||
          auth.subscription.preapproval?.init_point
        }
        onClick={handleContinue}
      >
        Continuar
      </button>
      <hr />
      {auth.subscription.preapproval?.init_point ? (
        <>
          <p className="m">
            <b>Método de Pago</b>
          </p>
          <FormInfoMsg text="Presiona el botón para continuar al pago. Una vez lo hayas completado, refresca esta página." />
          <MercadoPagoSecureCard
            text={
              <b>
                Tu suscripción y datos de pago serán procesados de forma segura
                por <b style={{ color: "#00bcff" }}>MercadoPago.</b>
              </b>
            }
            buttonText="Continuar al pago"
            buttonRef={auth.subscription.preapproval.init_point}
          />
        </>
      ) : (
        <>
          <FormInfoMsg
            text={
              <>
                Actualmente estás haciendo uso de una prueba gratuita.
                Suscríbete a un plan de suscripción para seguir usando&nbsp;
                <b className="bold">{NAME}</b> sin problemas.
                <br />
                <br />
                Para más información sobre los planes de suscripción y sus
                beneficios,{" "}
                <a
                  className="bold"
                  href={MEMBERSHIP_ROUTE}
                  target="_blank"
                  rel="noreferrer"
                >
                  clic aquí
                </a>
                .
              </>
            }
          />
        </>
      )}
    </AnimatedPanelPage>
  );
};
