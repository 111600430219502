import { useState } from "react";
import { useMutation } from "react-query";
import { LineLoader } from "../../components/loaders/LineLoader";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { usePopup } from "../../hooks/usePopup";
import { sendCustomNotification } from "../../api/fetchers/notifications";
import { TaggedInput } from "../../components/TaggedInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH } from "../../api/constants";
import { CountedTextArea } from "../../components/CountedTextArea";

export const SendNotificationPage = () => {
  function handleClick() {
    sendMutation.mutate({
      custom_message: message,
      notifiers: [user],
      link: link || undefined,
    });
  }

  const popup = usePopup();

  const sendMutation = useMutation({
    mutationFn: sendCustomNotification,
    onSuccess: (data) => popup.showSuccess("Notificación enviada"),
    onError: (error) => popup.showError("No se pudo enviar la notificación"),
  });

  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Enviar Notificación</b>
      </p>

      <input
        placeholder="Usuario"
        value={user}
        onChange={(e) => setUser(e.target.value)}
        maxLength={USERNAME_MAX_LENGTH}
      />
      <CountedTextArea
        style={{ width: "100%" }}
        rows={3}
        placeholder="Mensaje"
        state={message}
        setter={setMessage}
        maxLength={200}
      />
      <TaggedInput style={{ width: "100%" }}>
        <FontAwesomeIcon icon={faLink} />
        <input
          className="form"
          placeholder={window.location.origin}
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </TaggedInput>

      {sendMutation.isLoading ? <LineLoader /> : null}
      <button
        className="bold semi_rounded"
        onClick={handleClick}
        disabled={
          sendMutation.isLoading ||
          user.length < USERNAME_MIN_LENGTH ||
          user.length > USERNAME_MAX_LENGTH ||
          !message ||
          message.length > 200
        }
      >
        Enviar
      </button>
    </AnimatedPanelPage>
  );
};
