import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { AnimatedBusinessPage } from "../../components/animations/AnimatedBusinessPage";
import { CollectionCard } from "../../components/CollectionCard";
import { LineLoader } from "../../components/loaders/LineLoader";
import { getCollectionsInfo } from "../../api/fetchers/files";
import { useAuth } from "../../hooks/useAuth";
import { EmptyBoxIllustration } from "../../assets/illustrations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  COLLECTION_STATE_PUBLIC,
  MY_BUSINESS_ROUTE,
} from "../../api/constants";
import { sortCollections } from "../../api/utils";

export const BusinessCollectionsPage = () => {
  const { business_id } = useOutletContext();
  const { business_name } = useParams();

  const auth = useAuth();

  const { data, isSuccess, isLoading } = useQuery({
    queryFn: () => getCollectionsInfo({ business_id: business_id }),
    queryKey: `${business_id}-collections`,
    staleTime: 600000,
  });

  const navigate = useNavigate();

  return (
    <>
      {isSuccess ? (
        <AnimatedBusinessPage>
          {data.filter((col) => col.state === COLLECTION_STATE_PUBLIC)
            .length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "48px",
              }}
            >
              <p className="text-light sm text-center">
                <b>No hay colecciones para mostrar &#128532;</b>
              </p>
              <EmptyBoxIllustration />
              {auth.username === business_name && (
                <button
                  className="semi_rounded sm"
                  onClick={() =>
                    navigate(MY_BUSINESS_ROUTE + "/catalog/new-collection")
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "8px" }}
                  />
                  Crear una colección
                </button>
              )}
            </div>
          ) : (
            <div
              className="card_display"
              style={{
                padding: "0 12px",
              }}
            >
              {sortCollections(
                data.filter((col) => col.state === COLLECTION_STATE_PUBLIC)
              ).map((col) => (
                <CollectionCard
                  key={col._id}
                  name={col.name}
                  pinned={col.pinned}
                  image={col.cover.img}
                  item_count={col.count}
                  onClick={() => navigate("../" + col.name)}
                  state={col.state}
                />
              ))}
            </div>
          )}
        </AnimatedBusinessPage>
      ) : isLoading ? (
        <LineLoader />
      ) : null}
    </>
  );
};
