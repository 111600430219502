import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { faCalendar, faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { getSubscriptionData } from "../../api/fetchers/payments";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import { LineLoader } from "../../components/loaders/LineLoader";
import { formatDatetime, formatPrice } from "../../api/utils";
import { MercadoPagoSecureCard } from "../../components/MercadoPagoSecureCard";

export const NextPaymentPage = () => {
  const auth = useAuth();

  const { data, isLoading, isSuccess } = useQuery({
    queryFn: getSubscriptionData,
    queryKey: `${auth.username}-subscription-data`,
    staleTime: 1000 * 60 * 5, // 10 minutes
    enabled: auth.authState === "authenticated",
  });

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Próximo pago</b>
      </p>
      {isLoading && <LineLoader />}
      <p>
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "8px" }} />
        Fecha de pago:{" "}
        {isSuccess && (
          <b>
            {data.next_payment_date
              ? formatDatetime(data.next_payment_date, { includeHour: false })
              : data.summarized.last_charged_date
              ? formatDatetime(data.summarized.last_charged_date, {
                  includeHour: false,
                  monthAddition: 1,
                })
              : formatDatetime(data.date_created, {
                  includeHour: false,
                  monthAddition: 1,
                })}
          </b>
        )}
      </p>
      <p>
        <FontAwesomeIcon
          icon={faMoneyBillWave}
          style={{ marginRight: "8px" }}
        />
        Monto:{" "}
        {isSuccess && (
          <b>
            {formatPrice(data.auto_recurring.transaction_amount)}
            &nbsp;
            {data.auto_recurring.currency_id}
          </b>
        )}
      </p>
      <hr />
      <p className="m">
        <b>Método de Pago</b>
      </p>
      {auth.subscription.preapproval?.init_point && (
        <MercadoPagoSecureCard
          text={
            <b>
              Tu suscripción es administrada por{" "}
              <b style={{ color: "#00bcff" }}>MercadoPago</b>. Presiona el botón
              para cambiar tu método de pago.
            </b>
          }
          buttonText="Cambiar método de pago"
          buttonRef={auth.subscription.preapproval.init_point}
        />
      )}
    </AnimatedPanelPage>
  );
};
