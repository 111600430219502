import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { PasswordInput } from "../../components/PasswordInput";
import { LineLoader } from "../../components/loaders/LineLoader";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as constants from "../../api/constants";
import { PasswordStrengthLabel } from "../../components/PasswordStrengthLabel";
import { usePopup } from "../../hooks/usePopup";
import { finishSignup } from "../../api/fetchers/auth";
import { Content } from "../../components/Content";
import { useAuth } from "../../hooks/useAuth";

export const AccountSetup = () => {
  function handleSetPassword() {
    mutation.mutate({
      username: username,
      token: token,
      password: newPass,
    });
  }

  const [params] = useSearchParams();

  const username = params.get("username");
  const token = params.get("token");

  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");

  const popup = usePopup();
  const auth = useAuth();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: finishSignup,
    onSuccess: () => {
      auth.refetch();
      popup.showSuccess("¡Contraseña creada correctamente!", () =>
        navigate(constants.MY_BUSINESS_ROUTE)
      );
    },
    onError: () => popup.showError("Lo sentimos, ocurrió un error."),
  });

  return (
    <Content>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "40px",
        }}
      >
        <Helmet title="Completar registro" />
        <p className="l bold text-center">Crea una contraseña</p>
        <p className="sm text-center">
          Termina el registro estableciendo una contraseña para tu cuenta.
        </p>
        <div
          className="semi_rounded"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "var(--main-color)",
            padding: "35px",
            gap: "20px",
          }}
        >
          <PasswordInput
            placeholder="Contraseña"
            state={newPass}
            setter={setNewPass}
          />
          <PasswordInput
            placeholder="Confirma contraseña"
            state={newPassConf}
            setter={setNewPassConf}
          />
          <p className="s linespace-m text-white">
            Seguridad: <PasswordStrengthLabel password={newPass} />
          </p>
          <button
            className="semi_rounded bold s white"
            disabled={mutation.isLoading || !newPass || newPass !== newPassConf}
            onClick={handleSetPassword}
          >
            Establecer contraseña
          </button>
          {mutation.isLoading ? <LineLoader white /> : null}
        </div>
      </div>
    </Content>
  );
};
