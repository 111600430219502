import * as constants from "./constants";

// const priceFormatter = Intl.NumberFormat(undefined, {
//   style: "currency",
//   currency: "USD",
//   maximumFractionDigits: 0,
// });

function isEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function isDigit(str) {
  return /^\d+$/.test(str);
}

function getExtension(filename) {
  filename = filename.toLowerCase();
  const RE = /(?:\.([^.]+))?$/;
  return RE.exec(filename)[1];
}

function getCookie(name) {
  function escape(s) {
    // eslint-disable-next-line no-useless-escape
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}

export function getDownloadURL(path, token) {
  if (token == null) {
    return null;
  }

  return (
    constants.FIREBASE_BASE_URL +
    constants.FIREBASE_BUCKET_NAME +
    "/o/" +
    encodeURIComponent(path) +
    "?alt=media&token=" +
    token
  );
}

export function getWhatsAppLink(number, message) {
  return `https://wa.me/${number}${
    message ? `?text=${encodeURIComponent(message)}` : ""
  }`;
}

export function getProductLink(_id, b_name, col_id) {
  return `${window.location.origin}/b/${b_name}/${col_id}/${_id}`;
}

export function getProductMessage(_id, b_name, col_id) {
  return constants.PRODUCT_MESSAGE + getProductLink(_id, b_name, col_id);
}

export function getBusinessPageCurrentRoute() {
  return window.location.pathname.split("/")[
    constants.BUSINESS_PAGE_ROUTE.split("/").length
  ];
}

export { isEmail, isDigit, getExtension, getCookie };

export function getNotificationMessage(data) {
  function formatDatetime(datetime) {
    return datetime;
  }

  const MESSAGES = {
    1: {
      1: () => "Tu suscripción se pagó correctamente.",
      2: () => "No se pudo realizar el pago de tu suscripción.",
    },
    2: {
      1: () =>
        "Tu negocio ha sido deshabilitado temporalmente. Paga tu suscripción para rehabilitarlo.",
      2: () => "Tu negocio ha sido bloqueado. Contáctanos para más detalles.",
    },
    3: {
      1: () => "Actualizaremos nuestras políticas pronto.",
      2: () => "Actualizamos nuestras políticas.",
      3: ({ start, end }) =>
        `${constants.NAME} estará deshabilitada del ${formatDatetime(
          start
        )} hasta el ${formatDatetime(end)} por mantenimiento.`,
      4: ({ custom_message }) => custom_message,
    },
  };

  return MESSAGES[data.entity][data.entity_type](data);
}

export function getPasswordStrength(password) {
  // Taken from: https://martech.zone/javascript-password-strength/

  // Initialize variables
  var strength = 0;

  // Check password length
  if (password.length >= 8) {
    strength += 1;
  }

  // Check for mixed case
  if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    strength += 1;
  }

  // Check for numbers
  if (password.match(/\d/)) {
    strength += 1;
  }

  // Check for special characters
  if (password.match(/[^a-zA-Z\d]/)) {
    strength += 1;
  }

  // Return results
  return strength;
}

export function formatPrice(price) {
  if (typeof price === "number") {
    price = price.toString();
  }

  const m = price.length % 3;
  let formatted = "$" + (m > 0 ? price.substring(0, m) : "");

  for (let i = m; i < price.length; i++) {
    if ((i - m) % 3 === 0 && i !== 0) {
      formatted += ".";
    }
    formatted += price[i];
  }

  return formatted;
}

export function getNameFromValue(value) {
  try {
    return constants.COUNTRY_LIST.find((c) => c.value === value).ES;
  } catch {
    return "";
  }
}

export function getCardBrand(number) {
  const visa = /^4[0-9]{6,}$/;
  const mastercard =
    /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;
  const amex = /^3[47][0-9]{5,}$/;
  const dinner_club = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;

  if (visa.test(number)) {
    return "visa";
  }
  if (mastercard.test(number)) {
    return "mastercard";
  }
  if (amex.test(number)) {
    return "amex";
  }
  if (dinner_club.test(number)) {
    return "dinner_club";
  }

  return null;
}

export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function stringSorter(a, b) {
  const a_ = removeAccents(a.toLowerCase());
  const b_ = removeAccents(b.toLowerCase());
  return a_ > b_ ? 1 : a_ < b_ ? -1 : 0;
}

export function sortCollections(array) {
  const pinned = array
    .filter((c) => c.pinned)
    .sort((a, b) => stringSorter(a.name, b.name));
  const not_pinned = array
    .filter((c) => !c.pinned)
    .sort((a, b) => stringSorter(a.name, b.name));
  return pinned.concat(not_pinned);
}

export function formatDatetime(
  date,
  { includeHour = true, monthAddition = 0 } = {}
) {
  const time = Date.parse(date);
  const newDate = new Date(time);

  newDate.setMonth(newDate.getMonth() + monthAddition);

  return newDate.toLocaleString("es-CO", {
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: includeHour ? "numeric" : undefined,
    minute: includeHour ? "numeric" : undefined,
    hour12: false,
  });
}
