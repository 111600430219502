import * as constants from "../../api/constants";
import { useState } from "react";
import { useMutation } from "react-query";
import { ImagePlaceholder } from "../../components/Image";
import { FileInput } from "../../components/FileInput";
import {
  FormErrorMsg,
  FormInfoMsg,
  FormWarningMsg,
} from "../../components/FormMessages";
import { Loader } from "../../components/loaders/Loader";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { usePopup } from "../../hooks/usePopup";
import { addBusinessCollection } from "../../api/fetchers/main";
import { onChangeCollectionNameHandler } from "../../api/validators";

export const NewCollectionPage = () => {
  function handleAdd() {
    popup.showConfirm("¿Seguro quieres crear una nueva colección?", () =>
      mutation.mutate({
        name,
        cover,
      })
    );
  }

  function isValidForm() {
    return (
      name &&
      name.length >= constants.MIN_COLLECTION_NAME_LENGTH &&
      name.length <= constants.MAX_COLLECTION_NAME_LENGTH &&
      !constants.RESERVED_COLLECTION_NAMES.includes(name) &&
      cover.name
    );
  }

  const [name, setName] = useState("");

  const [cover, setCover] = useState({});

  const mutation = useMutation({
    mutationFn: addBusinessCollection,
    onSuccess: () => popup.showSuccess("Colección añadida correctamente."),
    onError: () => popup.showError("Ya tienes una colección con este nombre."),
  });

  const popup = usePopup();

  return (
    <AnimatedPanelPage>
      <p className="l linespace-l">
        <b>Nueva Colección</b>
      </p>
      <p className="m">
        <b>Nombre de la colección</b>
      </p>
      <input
        className="form"
        placeholder="Nombre"
        maxLength={constants.MAX_COLLECTION_NAME_LENGTH}
        value={name}
        onChange={(e) => onChangeCollectionNameHandler(e, setName)}
      />
      {constants.RESERVED_COLLECTION_NAMES.includes(name) && (
        <FormErrorMsg text="Este nombre no está permitido" />
      )}
      {name && name.length < constants.MIN_COLLECTION_NAME_LENGTH && (
        <FormErrorMsg text="El nombre es muy corto" />
      )}
      <p className="m">
        <b>Cover</b>
      </p>
      {cover.name ? (
        <ImagePlaceholder
          src={cover}
          style={{ width: "300px", height: "300px" }}
        />
      ) : (
        <p className="sm">Añade un cover a esta colección.</p>
      )}
      <FileInput state={cover} setter={setCover} />
      <FormInfoMsg
        text={
          <>
            Al crear una colección, esta será&nbsp;<b>Privada</b>. Ve a&nbsp;
            <b>Panel &#62; Tu Catálogo &#62; Colecciones</b>&nbsp;para agregar
            productos y hacer la colección pública cuando esté lista.
          </>
        }
      />
      {!isValidForm() && (
        <FormWarningMsg text="Debes darle un nombre y una imagen a tu colección." />
      )}
      <button
        className="semi_rounded"
        onClick={handleAdd}
        disabled={!isValidForm() || mutation.isLoading}
      >
        {mutation.isLoading ? <Loader /> : null}
        Añadir
      </button>
    </AnimatedPanelPage>
  );
};
