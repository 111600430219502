import { useEffect, useState } from "react";
import { getPasswordStrength } from "../api/utils";

export default function usePasswordStrength(password) {
  const [grade, setGrade] = useState(0);

  useEffect(() => {
    setGrade(getPasswordStrength(password));
  }, [password]);

  return grade;
}
