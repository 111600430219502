import { useState } from "react";
import { useNavigate } from "react-router";
import { FormErrorMsg } from "../../components/FormMessages";
import * as constants from "../../api/constants";
import { useMutation } from "react-query";
import { LineLoader } from "../../components/loaders/LineLoader";
import { Helmet } from "react-helmet-async";
import { PasswordInput } from "../../components/PasswordInput";
import "../../styles/pages/Login.css";
import { usePopup } from "../../hooks/usePopup";
import { login } from "../../api/fetchers/auth";
import { useAuth } from "../../hooks/useAuth";
import { Content } from "../../components/Content";
import {
  isValidPassword,
  isValidUsername,
  onChangeUsernameHandler,
} from "../../api/validators";

export const Login = () => {
  function redirect(data) {
    if (
      data.auth_status === constants.AUTH_STATUS_SUCCESS ||
      data.auth_status === constants.AUTH_STATUS_RECOVERED
    ) {
      if (data.auth_status === constants.AUTH_STATUS_SUCCESS) {
        popup.showSuccess("Sesión iniciada correctamente.");
        authContext.refetch();
      }
      if (data.role === constants.ADMIN_ROLE) {
        navigate(constants.ADMIN_PANEL_ROUTE);
      } else {
        navigate(constants.MY_BUSINESS_ROUTE);
      }
    } else if (data.auth_status === constants.AUTH_STATUS_PENDING) {
      navigate(constants.TWO_STEP_AUTH_ROUTE, {
        state: {
          email: data.email,
          _id: data._id,
        },
      });
    }
  }

  function handleLogin() {
    if (isValidUsername(username)) {
      mutation.mutate({
        username: username,
        password: password,
      });
    }
  }

  async function handleKeyDown(event) {
    if (event.key === "Enter" && !mutation.isLoading) {
      handleLogin();
    }
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const authContext = useAuth();

  const popup = usePopup();

  const mutation = useMutation({
    mutationKey: "login",
    mutationFn: login,
    onSuccess: redirect,
  });

  return (
    <Content>
      <Helmet title="Iniciar Sesión" />
      <div style={{ paddingTop: "40px" }} onKeyDown={handleKeyDown}>
        <div
          className="container centered form semi_rounded"
          style={{ width: "400px" }}
        >
          <p className="l left">
            <b>Iniciar Sesión</b>
          </p>
          <input
            type="text"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            className="form"
            placeholder="Usuario"
            value={username}
            onChange={(e) => onChangeUsernameHandler(e, setUsername)}
          />
          <PasswordInput
            style={{ width: "100%" }}
            placeholder="Contraseña"
            state={password}
            setter={setPassword}
          />
          <p
            className="s right link href"
            style={{ marginRight: "10px" }}
            onClick={() => navigate(constants.RESET_PASSWORD_ROUTE)}
          >
            Restaurar contraseña
          </p>
          {mutation.isError ? (
            <FormErrorMsg text={"Usuario o contraseña inválidos."} />
          ) : null}
          {mutation.isLoading ? <LineLoader /> : null}
          <button
            onClick={handleLogin}
            className="centered wide"
            disabled={
              mutation.isLoading ||
              !isValidUsername(username) ||
              !isValidPassword(password)
            }
          >
            Entrar
          </button>
        </div>
      </div>
      <div
        className="login-signup form rounded centered"
        onClick={() => navigate("/signup")}
      >
        <p className="m">
          <b>¿Aún no tienes una cuenta?</b>
        </p>
        <p className="sm">Regístra tu negocio y personaliza tu catálogo.</p>
      </div>
    </Content>
  );
};
