import { forwardRef, useImperativeHandle, useState } from "react";
import { WarningIconAnimation } from "../animations/Animations";
import { AnimatePresence, motion } from "framer-motion";
import { FADE, POPUP } from "../animations/framer-animations";
import "../../styles/components/Modals.css";

export const ConfirmDialogModal = forwardRef((props, ref) => {
  function close() {
    setHidden(true);
    setOnConfirmFunc(() => () => {});
    setOnCancelFunc(() => () => {});
  }

  function onConfirm() {
    close();
    try {
      onConfirmFunc();
    } catch {}
  }

  function onCancel() {
    close();
    try {
      onCancelFunc();
    } catch {}
  }

  function show(msg, onConfirmF, onCancelF) {
    setMessage(msg);
    setOnConfirmFunc(() => onConfirmF);
    setOnCancelFunc(() => onCancelF);
    setHidden(false);
  }

  const [hidden, setHidden] = useState(true);
  const [message, setMessage] = useState("");
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => {});
  const [onCancelFunc, setOnCancelFunc] = useState(() => () => {});

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="modal_container"
          variants={FADE}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <motion.div
            className="modal rounded"
            variants={POPUP}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={POPUP.transition}
          >
            <WarningIconAnimation />
            <p className="sm centered linespace-l">{message}</p>
            <div style={{ display: "flex", gap: "16px" }}>
              <button className="centered" onClick={onConfirm}>
                Confirmar
              </button>
              <button className="centered" onClick={onCancel}>
                Cancelar
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
