import { faLock, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import * as constants from "../api/constants";
import "../styles/components/CollectionCard.css";
import "../styles/components/Card.css";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

export const CollectionCard = ({
  name,
  pinned,
  image,
  item_count,
  state,
  onClick,
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      className={`collection_card card semi_rounded${loaded ? "" : " loading"}`}
      onClick={onClick ? onClick : null}
    >
      {pinned && (
        <FontAwesomeIcon
          icon={faThumbTack}
          size="xl"
          className="collection_pinned"
        />
      )}
      <div className="img_container">
        <img
          alt=""
          src={
            typeof image === "string"
              ? image
              : image instanceof Blob
              ? window.URL.createObjectURL(image)
              : null
          }
          onLoad={() => setLoaded(true)}
          className={loaded ? "" : "col_img_loading"}
        />
        <div className="collection_info">
          <b className="font-secondary sm">{name}</b>
          <div>
            {state === constants.COLLECTION_STATE_PRIVATE ? (
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: "var(--color6)" }}
              />
            ) : null}
            <FontAwesomeIcon icon={faLayerGroup} />
            <b className="s font-secondary">{item_count}</b>
          </div>
        </div>
      </div>
    </div>
  );
};
