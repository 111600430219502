import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as constants from "../api/constants";
import "../styles/components/Footer.css";
import { useNavigate } from "react-router";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="footer">
        <div className="footer-group">
          <p className="sm footer-group-title">Información legal</p>
          <p
            className="sm footer-link"
            onClick={() => navigate(constants.PRIVACY_POLICY_ROUTE)}
          >
            Política de privacidad
          </p>
          <p
            className="sm footer-link"
            onClick={() => navigate(constants.TERMS_AND_CONDITIONS_ROUTE)}
          >
            Términos y condiciones
          </p>
        </div>
        <div className="footer-group">
          <p className="sm footer-group-title">Negocios</p>
          <p
            className="sm footer-link"
            onClick={() => navigate(constants.LOGIN_ROUTE)}
          >
            Iniciar sesion
          </p>
          <p
            className="sm footer-link"
            onClick={() => navigate(constants.SIGNUP_ROUTE)}
          >
            Únete
          </p>
        </div>
        <div className="footer-group">
          <p className="sm footer-group-title">Más información</p>
          {/* <p
            className="sm footer-link"
            onClick={() => navigate(constants.ABOUT_ROUTE)}
          >
            Acerca de {constants.NAME}
          </p> */}
          <p
            className="sm footer-link"
            onClick={() => navigate(constants.MEMBERSHIP_ROUTE)}
          >
            Planes de suscripción
          </p>
        </div>
        <div className="footer-group">
          <p className="sm footer-group-title">Contacto</p>
          <a href={`mailto:${constants.CONTACT_EMAIL}`}>
            <p className="sm footer-link">
              <FontAwesomeIcon icon={faEnvelope} /> {constants.CONTACT_EMAIL}
            </p>
          </a>
          {/* <a>
            <p className="sm footer-link"><SVGIcon icon_name="whatsapp"/> </p>
          </a> */}
        </div>
      </div>
    </footer>
  );
};
