import React from "react";
import { PanelSidebarOption } from "../../components/PanelSideBar";
import {
  faObjectGroup,
  faPlus,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";

export const CatalogPage = () => {
  const navigate = useNavigate();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Tu Catálogo</b>
      </p>
      <PanelSidebarOption
        icon={faPlus}
        text="Nueva colección"
        onClick={() => navigate("new-collection")}
      />
      <PanelSidebarOption
        icon={faObjectGroup}
        text="Colecciones"
        onClick={() => navigate("collections")}
      />
      <PanelSidebarOption icon={faTag} text="Etiquetas" disabled />
    </AnimatedPanelPage>
  );
};
