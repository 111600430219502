import * as storage from "../firebase_storage";
import * as errors from "../errors";
import axios from "axios";

const API = process.env.REACT_APP_API_URL;

const fetcher = axios.create({
  baseURL: API,
  withCredentials: true,
});

export async function addBusiness(data) {
  try {
    const res = await fetcher.post("/business", data);
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function updateBusiness(data) {
  try {
    await fetcher.put("/business", data);
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function getBusinessInfo({ business_id }) {
  errors.validateArgs(business_id);

  try {
    const res = await fetcher.get(`/business/${business_id}/basic`);
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function addBusinessCollection({ name, cover }) {
  errors.validateArgs(name, cover);

  const formData = new FormData();
  formData.append("name", name.trim());
  formData.append("cover", cover);

  try {
    await fetcher.post("/business/collection", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function deleteCollection({ collection_id }) {
  errors.validateArgs(collection_id);

  try {
    await fetcher.delete(`business/collection/${collection_id}`);
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function uploadCollectionItem({
  col_id,
  _id,
  name,
  image,
  price,
  tags,
}) {
  errors.validateArgs(col_id);
  const formData = new FormData();

  if (typeof _id !== "undefined") {
    formData.append("_id", _id);
  }

  formData.append("col_id", col_id);
  if (name) {
    formData.append("name", name);
  }
  if (image) {
    formData.append("image", image);
  }
  if (typeof price !== "undefined" && price !== null && price !== "") {
    formData.append("price", parseInt(price));
  }
  if (tags) {
    formData.append("tags", tags);
  }

  try {
    await fetcher.post("/item", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

// On development
export async function updateItem({
  collection_id,
  item_id,
  name,
  price,
  tags,
}) {
  try {
    await fetcher.put(`/collection/${collection_id}/${item_id}`, {
      name,
      price,
      tags,
    });
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function getCollectionItems({
  business_id,
  collection_id,
  page,
  items,
  sendCredentials = false,
}) {
  errors.validateArgs(business_id, collection_id, page, items);

  try {
    const res = await fetcher.get(
      `/${business_id}/${collection_id}/items?page=${page}&items=${items}`,
      {
        withCredentials: sendCredentials,
      }
    );
    const array = [];
    const col_data = res.data.pop();

    for (const item of res.data) {
      array.push({
        ...item,
        image: {
          img: storage.getItemImage(
            business_id,
            col_data._id,
            item._id,
            item.image.img_token
          ),
          thumbnail_img: storage.getItemImage(
            business_id,
            col_data._id,
            item._id,
            item.image.thumbnail_img_token,
            true
          ),
        },
      });
    }
    array.push(col_data);
    return array;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function getSingleItem({ business_id, collection_id, item_id }) {
  errors.validateArgs(business_id, collection_id, item_id);

  try {
    const res = await fetcher.get(`${business_id}/${collection_id}/${item_id}`);
    return {
      ...res.data,
      image: {
        img: storage.getItemImage(
          business_id,
          res.data.collection_id,
          res.data._id,
          res.data.image.img_token
        ),
        thumbnail_img: storage.getItemImage(
          business_id,
          collection_id,
          res.data._id,
          res.data.image.thumbnail_img_token,
          true
        ),
      },
    };
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function updateCollection({ collection_id, name, state, pinned }) {
  errors.validateArgs(collection_id);

  try {
    await fetcher.put(`/collection/${collection_id}`, {
      name: name?.trim(),
      state,
      pinned,
    });
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function deleteItem({ collection_id, item_id }) {
  errors.validateArgs(collection_id, item_id);

  try {
    await fetcher.delete(`/collection/${collection_id}/${item_id}`);
    return;
  } catch (err) {
    errors.throwError(err.response);
  }
}
