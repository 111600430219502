import { useState } from "react";
import * as constants from "../../api/constants";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { PasswordInput } from "../../components/PasswordInput";
import { useMutation } from "react-query";
import { LineLoader } from "../../components/loaders/LineLoader";
import { PasswordStrengthLabel } from "../../components/PasswordStrengthLabel";
import { usePopup } from "../../hooks/usePopup";
import { changePassword } from "../../api/fetchers/auth";
import { FormInfoMsg } from "../../components/FormMessages";
import { useNavigate } from "react-router";

export const ChangePasswordPage = () => {
  function validPassword() {
    if (newPass !== newPassConf) {
      return false;
    }
    if (
      newPass.length < constants.PASSWORD_MIN_LENGTH ||
      newPass.length > constants.PASSWORD_MAX_LENGTH
    ) {
      return false;
    }
    return true;
  }

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");

  const popup = usePopup();

  const mutation = useMutation({
    mutationFn: () =>
      changePassword({
        current_password: currentPass,
        new_password: newPass,
      }),
    onSuccess: (data) =>
      popup.showSuccess("La contraseña se reestableció correctamente."),
    onError: (error) =>
      popup.showError("Ocurrió un error. Por favor, Intente nuevamente."),
  });

  const navigate = useNavigate();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Cambiar Contraseña</b>
      </p>
      <p className="sm">
        <b>Verifica tu identidad</b>
      </p>
      <PasswordInput
        state={currentPass}
        setter={setCurrentPass}
        style={{ width: "180px" }}
        placeholder="Contraseña actual"
      />
      <p className="sm">
        <b>Configura tu nueva contraseña</b>
      </p>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        <PasswordInput
          placeholder="Nueva contraseña"
          style={{ width: "180px" }}
          state={newPass}
          setter={setNewPass}
        />
        <PasswordInput
          placeholder="Confirma nueva contraseña"
          style={{ width: "180px" }}
          state={newPassConf}
          setter={setNewPassConf}
        />
      </div>
      <p className="s linespace-m">
        Seguridad: <PasswordStrengthLabel password={newPass} />
      </p>
      <FormInfoMsg
        text={
          <>
            Si crees que tu cuenta ha sido vulnerada, te recomendamos
            <b
              className="href"
              onClick={() =>
                navigate(constants.MY_BUSINESS_ROUTE + "/privacy/sessions")
              }
            >
              {" "}
              cerrar todas las sesiones
            </b>{" "}
            también.
          </>
        }
      />
      <button
        className="semi_rounded left"
        disabled={!validPassword() || mutation.isLoading}
        onClick={mutation.mutate}
      >
        Cambiar contraseña
      </button>
      {mutation.isLoading ? <LineLoader /> : null}
    </AnimatedPanelPage>
  );
};
