import {
  faCheckDouble,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "react-query";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { LineLoader } from "../../components/loaders/LineLoader";
import "../../styles/pages/business_panel/TwoFactorAuth.css";
import { usePopup } from "../../hooks/usePopup";
import { turnTwoStepAuth } from "../../api/fetchers/auth";
import { useAuth } from "../../hooks/useAuth";
import {
  AUTH_STATE_AUTHENTICATED,
  AUTH_STATE_UNAUTHENTICATED,
  AUTH_STATE_UNKNOWN,
  LOGIN_ROUTE,
} from "../../api/constants";
import { useNavigate } from "react-router";
import { useEffect } from "react";

const EmailMethod = ({ selected }) => {
  return (
    <div className="container card semi_rounded auth_method">
      <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
      <div>
        <p className="sm">
          <b>Correo electrónico</b>
        </p>
        <p className="s">
          Enviaremos un código temporal para iniciar sesión a tu correo.
        </p>
      </div>
      {selected ? (
        <div className="auth_method-selected">
          <FontAwesomeIcon icon={faCheckDouble} className="text-white" />
        </div>
      ) : null}
    </div>
  );
};

export const TwoFactorAuth = () => {
  async function handleTurnTwoStepAuth() {
    popup.showConfirm(
      auth.two_step_auth ? (
        <>
          <b>Está acción no es recomendada</b>
          <br />
          ¿Seguro que desea desactivar la autenticación en dos pasos?
        </>
      ) : (
        "¿Seguro que desea activar la autenticación en dos pasos?"
      ),
      () => mutation.mutate({ value: !auth.two_step_auth })
    );
  }

  const popup = usePopup();
  const auth = useAuth();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: turnTwoStepAuth,
    onSuccess: () => {
      popup.showSuccess(
        `Se ${
          auth.two_step_auth ? "desactivó" : "activó"
        } la autenticación en dos pasos correctamente.`
      );
      auth.two_step_auth = !auth.two_step_auth;
    },
  });

  useEffect(() => {
    if (auth.authState === AUTH_STATE_UNAUTHENTICATED) {
      navigate(LOGIN_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Autenticación en Dos Pasos</b>
      </p>
      {auth.authState === AUTH_STATE_UNKNOWN ? (
        <LineLoader />
      ) : auth.authState === AUTH_STATE_AUTHENTICATED ? (
        <>
          <div
            className="linespace-l"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <div>
              <p className="sm">
                <b>
                  La autenticación en dos pasos está{" "}
                  {auth.two_step_auth ? "activada." : "desactivada."}
                </b>
              </p>
              <p className="s">
                Te recomendamos mantener esta característica activada para mayor
                seguridad.
              </p>
            </div>
            <button className="semi_rounded" onClick={handleTurnTwoStepAuth}>
              {auth.two_step_auth ? "Desactivar" : "Activar"}
            </button>
          </div>
          <p className="m">
            <b>Método de autenticación</b>
          </p>
          <EmailMethod selected={auth.two_step_auth} />
        </>
      ) : null}
    </AnimatedPanelPage>
  );
};
