import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { SLIDEUP } from "./animations/framer-animations";
import "../styles/components/ProgressBar.css";

export const ProgressBar = ({ percent, text, onCompleted, style }) => {
  if (percent > 1) {
    percent = 1;
  }

  if (percent === 1 && typeof onCompleted === "function") {
    onCompleted();
  }

  const percentage = Math.ceil(percent * 100) + "%";

  return (
    <div
      className="progress-bar-placeholder"
      style={{ position: "relative", overflow: "hidden", ...style }}
    >
      {percent === 1 ? (
        <motion.div
          variants={SLIDEUP}
          initial="initial"
          exit="exit"
          animate="animate"
          style={{
            position: "absolute",
            top: "6px",
            left: "10px",
            color: "white",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </motion.div>
      ) : null}
      <div className="progress-bar" style={{ width: percentage }}></div>
      <p
        className="bold s"
        style={{
          position: "absolute",
          color: "white",
          left: "34px",
          top: "8px",
        }}
      >
        {text}
      </p>
      <p
        className="bold s"
        style={{
          position: "absolute",
          color: "white",
          right: "10px",
          top: "8px",
        }}
      >
        {percentage === "100%" ? "COMPLETADO" : percentage}
      </p>
    </div>
  );
};
