import ColombiaIcon from "../assets/svg/Colombia.svg";

export const NAME = "Vitriina";

export const FIREBASE_BASE_URL = "https://firebasestorage.googleapis.com/v0/b/";
export const FIREBASE_BUCKET_NAME = process.env.REACT_APP_FIREBASE_BUCKET_NAME;

export const CONTACT_EMAIL = "admin@vitriina.com";

export const ADMIN_ROLE = 0;
export const BASIC_USER_ROLE = 1;
export const PREMIUM_USER_ROLE = 2;

export const MAX_COLLECTION_AMOUNT_BY_ROLE = {
  [BASIC_USER_ROLE]: 8,
  [PREMIUM_USER_ROLE]: 24,
};

export const MAX_ITEM_AMOUNT_PER_COLLECTION_BY_ROLE = {
  [BASIC_USER_ROLE]: 100,
  [PREMIUM_USER_ROLE]: 300,
};

export const BUSINESS_STATE_PUBLIC = 0;
export const BUSINESS_STATE_PRIVATE = 1;
export const BUSINESS_STATE_BLOCKED = 2;

export const COLLECTION_STATE_PUBLIC = 0;
export const COLLECTION_STATE_PRIVATE = 1;

export const BASIC_PLAN = {
  id: "basic",
  name: "Básico",
  price: "19",
  text: "Ideal para probar nuestra plataforma",
  features: [
    "Catálogo y página de negocio",
    "8 Colecciones",
    "100 Productos por colección",
  ],
  role: BASIC_USER_ROLE,
};

export const PREMIUM_PLAN = {
  id: "premium",
  name: "Premium",
  price: "29",
  text: "Ideal para negocios con un catálogo amplio de productos",
  features: [
    "Catálogo y página de negocio",
    "20 Colecciones",
    "300 Productos por colección",
    "Prioridad de servicio",
  ],
  role: PREMIUM_USER_ROLE,
};

export const AUTH_STATUS_SUCCESS = 0;
export const AUTH_STATUS_PENDING = 1; // For two-step-authentication
export const AUTH_STATUS_RECOVERED = 2;

// Front-end routes (!)
export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const TWO_STEP_AUTH_ROUTE = "/two-step-auth";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const EMAIL_VERIFICATION_ROUTE = "/validate-email/:token";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
export const CONTACT_ROUTE = "/contact";
export const ABOUT_ROUTE = "/about";
export const MEMBERSHIP_ROUTE = "/subscription-plans";
export const ADMIN_PANEL_ROUTE = "/administrator-panel";
export const MY_BUSINESS_ROUTE = "/mybusiness";
export const BUSINESS_PAGE_ROUTE = "/b/:business_name";
export const BUSINESS_COLLECTIONS_ROUTE = "collections";
export const BUSINESS_COLLECTION_ROUTE = ":collection_id";
export const BUSINESS_PRODUCT_ROUTE = ":collection_id/:item_id";
export const BUSINESS_ABOUT_ROUTE = "about";

export const CODE_LENGTH = 6;

export const IMAGE_ALLOWED_EXTENSIONS = ["jpeg", "jpg", "png"];

export const PHONE_NUMBER_LENGTH = 10; // No country code
export const MAX_INSTAGRAM_USER_LENGTH = 30;

export const FLAG_ICONS = {
  colombia: ColombiaIcon,
};

export const PHONE_CODES = {
  colombia: "+57",
};

export const PRODUCT_MESSAGE = "Hola! Tengo interés en este producto: ";

export const MIN_COLLECTION_NAME_LENGTH = 4;
export const MAX_COLLECTION_NAME_LENGTH = 32;
export const RESERVED_COLLECTION_NAMES = [
  "about",
  "collections",
  "contact",
  "location",
];

// NOTIFICATIONS

export const NOTIFICATION_ENTITY_BILLS = 1;
export const NOTIFICATION_ENTITY_ACCOUNT_STATUS = 2;
export const NOTIFICATION_ENTITY_GENERAL = 3;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;

// SIGNUP

export const BUSINESS_NAME_MAX_LENGTH = 30;
export const USERNAME_MAX_LENGTH = 30;
export const BUSINESS_NAME_MIN_LENGTH = 4;
export const USERNAME_MIN_LENGTH = 4;

export const BUSINESS_NAME_ALLOWED_CHARACTERS =
  "AaÁáBbCcDdEeÉéFfGgHhIiÍíJjKkLlMmNnÑñOoÓóPpQqRrSsTtUuÚúVvWwXxYyZz0123456789 ";
export const PASSWORD_ALLOWED_CHARACTERS =
  "AaÁáBbCcDdEeÉéFfGgHhIiÍíJjKkLlMmNnÑñOoÓóPpQqRrSsTtUuÚúVvWwXxYyZz0123456789~`!@#$%^&*()_-+={[}]|:;'<,>.?";
export const USERNAME_ALLOWED_CHARACTERS =
  "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789_-.";
export const COLLECTION_NAME_ALLOWED_CHARACTERS =
  "AaÁáBbCcDdEeÉéFfGgHhIiÍíJjKkLlMmNnÑñOoÓóPpQqRrSsTtUuÚúVvWwXxYyZz0123456789 ._-()";

export const MOBILE_SCREEN_THRESHOLD = 800;

export const AUTH_STATE_UNKNOWN = "unknown";
export const AUTH_STATE_UNAUTHENTICATED = "unauthenticated";
export const AUTH_STATE_AUTHENTICATED = "authenticated";

export const SUB_PLAN_STATUS_ACTIVE = "active";
export const SUB_PLAN_STATUS_INACTIVE = "inactive";
export const SUB_PLAN_STATUS_CANCELLED = "cancelled";

export const SUB_PLAN_NAMES = {
  "free-trial": "Prueba gratuita",
  basic: "Plan básico",
  premium: "Plan premium",
};

export const AVAILABLE_CARDS = ["visa", "mastercard", "amex", "diners_club"];

export const AUTHENTICATED_USER_DATA = {
  _id: undefined,
  state: undefined,
  business_id: undefined,
  username: undefined,
  email: undefined,
  role: undefined,
  two_step_auth: undefined,
  subscription: {
    preapproval: {
      id: undefined,
      init_point: undefined,
    },
    plan: {
      id: undefined,
      exp: undefined,
    },
    status: undefined,
  },
};

export const COUNTRY_LIST = [
  { value: "afghanistan", ES: "Afganistán" },
  { value: "albania", ES: "Albania" },
  { value: "algeria", ES: "Argelia" },
  { value: "andorra", ES: "Andorra" },
  { value: "angola", ES: "Angola" },
  { value: "antigua and barbuda", ES: "Antigua y Barbuda" },
  { value: "argentina", ES: "Argentina" },
  { value: "armenia", ES: "Armenia" },
  { value: "australia", ES: "Australia" },
  { value: "austria", ES: "Austria" },
  { value: "azerbaijan", ES: "Azerbaiyán" },
  { value: "bahamas", ES: "Bahamas" },
  { value: "bahrain", ES: "Baréin" },
  { value: "bangladesh", ES: "Bangladés" },
  { value: "barbados", ES: "Barbados" },
  { value: "belarus", ES: "Bielorrusia" },
  { value: "belgium", ES: "Bélgica" },
  { value: "belize", ES: "Belice" },
  { value: "benin", ES: "Benín" },
  { value: "bhutan", ES: "Bután" },
  { value: "bolivia", ES: "Bolivia" },
  { value: "bosnia and herzegovina", ES: "Bosnia y Herzegovina" },
  { value: "botswana", ES: "Botsuana" },
  { value: "brazil", ES: "Brasil" },
  { value: "brunei", ES: "Brunéi" },
  { value: "bulgaria", ES: "Bulgaria" },
  { value: "burkina faso", ES: "Burkina Faso" },
  { value: "burundi", ES: "Burundi" },
  { value: "cabo verde", ES: "Cabo Verde" },
  { value: "cambodia", ES: "Camboya" },
  { value: "cameroon", ES: "Camerún" },
  { value: "canada", ES: "Canadá" },
  { value: "central african republic", ES: "República Centroafricana" },
  { value: "chad", ES: "Chad" },
  { value: "chile", ES: "Chile" },
  { value: "china", ES: "China" },
  { value: "colombia", ES: "Colombia" },
  { value: "comoros", ES: "Comoras" },
  { value: "congo (brazzaville)", ES: "Congo (Brazzaville)" },
  { value: "congo (kinshasa)", ES: "Congo (Kinshasa)" },
  { value: "costa rica", ES: "Costa Rica" },
  { value: "cote d'ivoire", ES: "Costa de Marfil" },
  { value: "croatia", ES: "Croacia" },
  { value: "cuba", ES: "Cuba" },
  { value: "cyprus", ES: "Chipre" },
  { value: "czech republic", ES: "República Checa" },
  { value: "denmark", ES: "Dinamarca" },
  { value: "djibouti", ES: "Yibuti" },
  { value: "dominica", ES: "Dominica" },
  { value: "dominican republic", ES: "República Dominicana" },
  { value: "ecuador", ES: "Ecuador" },
  { value: "egypt", ES: "Egipto" },
  { value: "el salvador", ES: "El Salvador" },
  { value: "equatorial guinea", ES: "Guinea Ecuatorial" },
  { value: "eritrea", ES: "Eritrea" },
  { value: "estonia", ES: "Estonia" },
  { value: "eswatini", ES: "Esuatini" },
  { value: "ethiopia", ES: "Etiopía" },
  { value: "fiji", ES: "Fiyi" },
  { value: "finland", ES: "Finlandia" },
  { value: "france", ES: "Francia" },
  { value: "gabon", ES: "Gabón" },
  { value: "gambia", ES: "Gambia" },
  { value: "georgia", ES: "Georgia" },
  { value: "germany", ES: "Alemania" },
  { value: "ghana", ES: "Ghana" },
  { value: "greece", ES: "Grecia" },
  { value: "grenada", ES: "Granada" },
  { value: "guatemala", ES: "Guatemala" },
  { value: "guinea", ES: "Guinea" },
  { value: "guinea-bissau", ES: "Guinea-Bisáu" },
  { value: "guyana", ES: "Guyana" },
  { value: "haiti", ES: "Haití" },
  { value: "honduras", ES: "Honduras" },
  { value: "hungary", ES: "Hungría" },
  { value: "iceland", ES: "Islandia" },
  { value: "india", ES: "India" },
  { value: "indonesia", ES: "Indonesia" },
  { value: "iran", ES: "Irán" },
  { value: "iraq", ES: "Irak" },
  { value: "ireland", ES: "Irlanda" },
  { value: "israel", ES: "Israel" },
  { value: "italy", ES: "Italia" },
  { value: "jamaica", ES: "Jamaica" },
  { value: "japan", ES: "Japón" },
  { value: "jordan", ES: "Jordania" },
  { value: "kazakhstan", ES: "Kazajistán" },
  { value: "kenya", ES: "Kenia" },
  { value: "kiribati", ES: "Kiribati" },
  { value: "korea, north", ES: "Corea del Norte" },
  { value: "korea, south", ES: "Corea del Sur" },
  { value: "kosovo", ES: "Kosovo" },
  { value: "kuwait", ES: "Kuwait" },
  { value: "kyrgyzstan", ES: "Kirguistán" },
  { value: "laos", ES: "Laos" },
  { value: "latvia", ES: "Letonia" },
  { value: "lebanon", ES: "Líbano" },
  { value: "lesotho", ES: "Lesoto" },
  { value: "liberia", ES: "Liberia" },
  { value: "libya", ES: "Libia" },
  { value: "liechtenstein", ES: "Liechtenstein" },
  { value: "lithuania", ES: "Lituania" },
  { value: "luxembourg", ES: "Luxemburgo" },
  { value: "madagascar", ES: "Madagascar" },
  { value: "malawi", ES: "Malaui" },
  { value: "malaysia", ES: "Malasia" },
  { value: "maldives", ES: "Maldivas" },
  { value: "mali", ES: "Mali" },
  { value: "malta", ES: "Malta" },
  { value: "marshall islands", ES: "Islas Marshall" },
  { value: "mauritania", ES: "Mauritania" },
  { value: "mauritius", ES: "Mauricio" },
  { value: "mexico", ES: "México" },
  { value: "micronesia", ES: "Micronesia" },
  { value: "moldova", ES: "Moldavia" },
  { value: "monaco", ES: "Mónaco" },
  { value: "mongolia", ES: "Mongolia" },
  { value: "montenegro", ES: "Montenegro" },
  { value: "morocco", ES: "Marruecos" },
  { value: "mozambique", ES: "Mozambique" },
  { value: "myanmar", ES: "Myanmar (Birmania)" },
  { value: "namibia", ES: "Namibia" },
  { value: "nauru", ES: "Nauru" },
  { value: "nepal", ES: "Nepal" },
  { value: "netherlands", ES: "Países Bajos" },
  { value: "new zealand", ES: "Nueva Zelanda" },
  { value: "nicaragua", ES: "Nicaragua" },
  { value: "niger", ES: "Níger" },
  { value: "nigeria", ES: "Nigeria" },
  { value: "north macedonia", ES: "Macedonia del Norte" },
  { value: "norway", ES: "Noruega" },
  { value: "oman", ES: "Omán" },
  { value: "pakistan", ES: "Pakistán" },
  { value: "palau", ES: "Palaos" },
  { value: "panama", ES: "Panamá" },
  { value: "papua new guinea", ES: "Papúa Nueva Guinea" },
  { value: "paraguay", ES: "Paraguay" },
  { value: "peru", ES: "Perú" },
  { value: "philippines", ES: "Filipinas" },
  { value: "poland", ES: "Polonia" },
  { value: "portugal", ES: "Portugal" },
  { value: "qatar", ES: "Catar" },
  { value: "romania", ES: "Rumanía" },
  { value: "russia", ES: "Rusia" },
  { value: "rwanda", ES: "Ruanda" },
  { value: "saint kitts and nevis", ES: "San Cristóbal y Nieves" },
  { value: "saint lucia", ES: "Santa Lucía" },
  {
    value: "saint vincent and the grenadines",
    ES: "San Vicente y las Granadinas",
  },
  { value: "samoa", ES: "Samoa" },
  { value: "san marino", ES: "San Marino" },
  { value: "sao tome and principe", ES: "Santo Tomé y Príncipe" },
  { value: "saudi arabia", ES: "Arabia Saudita" },
  { value: "senegal", ES: "Senegal" },
  { value: "serbia", ES: "Serbia" },
  { value: "seychelles", ES: "Seychelles" },
  { value: "sierra leone", ES: "Sierra Leona" },
  { value: "singapore", ES: "Singapur" },
  { value: "slovakia", ES: "Eslovaquia" },
  { value: "slovenia", ES: "Eslovenia" },
  { value: "solomon islands", ES: "Islas Salomón" },
  { value: "somalia", ES: "Somalia" },
  { value: "south africa", ES: "Sudáfrica" },
  { value: "south sudan", ES: "Sudán del Sur" },
  { value: "spain", ES: "España" },
  { value: "sri lanka", ES: "Sri Lanka" },
  { value: "sudan", ES: "Sudán" },
  { value: "suriname", ES: "Surinam" },
  { value: "sweden", ES: "Suecia" },
  { value: "switzerland", ES: "Suiza" },
  { value: "syria", ES: "Siria" },
  { value: "taiwan", ES: "Taiwán" },
  { value: "tajikistan", ES: "Tayikistán" },
  { value: "tanzania", ES: "Tanzania" },
  { value: "thailand", ES: "Tailandia" },
  { value: "timor-leste", ES: "Timor Oriental" },
  { value: "togo", ES: "Togo" },
  { value: "tonga", ES: "Tonga" },
  { value: "trinidad and tobago", ES: "Trinidad y Tobago" },
  { value: "tunisia", ES: "Túnez" },
  { value: "turkey", ES: "Turquía" },
  { value: "turkmenistan", ES: "Turkmenistán" },
  { value: "tuvalu", ES: "Tuvalu" },
  { value: "uganda", ES: "Uganda" },
  { value: "ukraine", ES: "Ucrania" },
  { value: "united arab emirates", ES: "Emiratos Árabes Unidos" },
  { value: "united kingdom", ES: "Reino Unido" },
  { value: "united states of america", ES: "Estados Unidos de América" },
  { value: "uruguay", ES: "Uruguay" },
  { value: "uzbekistan", ES: "Uzbekistán" },
  { value: "vanuatu", ES: "Vanuatu" },
  { value: "vatican city", ES: "Ciudad del Vaticano" },
  { value: "venezuela", ES: "Venezuela" },
  { value: "vietnam", ES: "Vietnam" },
  { value: "yemen", ES: "Yemen" },
  { value: "zambia", ES: "Zambia" },
  { value: "zimbabwe", ES: "Zimbabue" },
];
