import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faInfoCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import {
  DROPDOWN,
  DROPDOWN_CONTENT_ANIMATION,
} from "./animations/framer-animations";
import "../styles/components/FormMessages.css";

export const FormErrorMsg = ({ text }) => {
  return (
    <motion.div
      variants={DROPDOWN}
      style={{ originY: 0 }}
      transition={DROPDOWN.transition}
      initial="initial"
      animate="animate"
      exit="exit"
      className="form_message error"
    >
      <motion.p
        variants={DROPDOWN_CONTENT_ANIMATION}
        transition={DROPDOWN_CONTENT_ANIMATION.transition}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <FontAwesomeIcon
          className="error"
          icon={faCircleXmark}
          style={{ marginRight: "6px", color: "#ac1238" }}
        />
        {text}
      </motion.p>
    </motion.div>
  );
};

export const FormInfoMsg = ({ text }) => {
  return (
    <motion.div
      variants={DROPDOWN}
      style={{ originY: 0 }}
      transition={DROPDOWN.transition}
      initial="initial"
      animate="animate"
      exit="exit"
      className="form_message info"
    >
      <motion.p
        variants={DROPDOWN_CONTENT_ANIMATION}
        transition={DROPDOWN_CONTENT_ANIMATION.transition}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        {text}
      </motion.p>
    </motion.div>
  );
};

export const FormWarningMsg = ({ text }) => {
  return (
    <motion.div
      variants={DROPDOWN}
      style={{ originY: 0 }}
      transition={DROPDOWN.transition}
      initial="initial"
      animate="animate"
      exit="exit"
      className="form_message warning"
    >
      <motion.p
        variants={DROPDOWN_CONTENT_ANIMATION}
        transition={DROPDOWN_CONTENT_ANIMATION.transition}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <FontAwesomeIcon icon={faTriangleExclamation} />
        {text}
      </motion.p>
    </motion.div>
  );
};

export const FormSuccessMsg = ({ text }) => {
  return (
    <motion.div
      variants={DROPDOWN}
      style={{ originY: 0 }}
      transition={DROPDOWN.transition}
      initial="initial"
      animate="animate"
      exit="exit"
      className="form_message success"
    >
      <motion.p
        variants={DROPDOWN_CONTENT_ANIMATION}
        transition={DROPDOWN_CONTENT_ANIMATION.transition}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <FontAwesomeIcon icon={faCircleCheck} />
        {text}
      </motion.p>
    </motion.div>
  );
};
