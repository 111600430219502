import { createContext } from "react";
import { AUTHENTICATED_USER_DATA } from "../api/constants";

const AuthenticationContext = createContext({
  ...AUTHENTICATED_USER_DATA,

  refetch: () => {},
  clear: () => {},
  login: () => {},
  authState: "unknown",
});

export default AuthenticationContext;
