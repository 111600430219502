import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SVGIcon } from "./SVGIcon";
import "../styles/components/PanelSideBar.css";

export const PanelSidebarOption = ({
  selected,
  id,
  onClick,
  icon,
  text,
  disabled,
}) => {
  function handleClick() {
    if (!disabled && onClick) {
      onClick();
    }
  }

  return (
    <div
      className={
        "panel_sidebar_option" +
        (selected && selected === id ? " selected" : "") +
        (disabled ? " disabled" : "")
      }
      onClick={handleClick}
    >
      <div className="inline">
        {typeof icon === "object" ? (
          <div style={{ minWidth: "fit-content", width: "24px" }}>
            {" "}
            <FontAwesomeIcon icon={icon} />
          </div>
        ) : typeof icon === "string" ? (
          <SVGIcon icon_name={icon} />
        ) : null}
        <p className="sm">
          <b>{text}</b>
        </p>
      </div>
      <FontAwesomeIcon
        className="panel_sidebar_option-arrow"
        icon={faArrowRight}
      />
    </div>
  );
};

export const PanelSidebar = (props) => {
  // item[0] : item ID
  // item[1] : Text
  // item[2] : onClick
  // item[3] : FontAwesome icon
  // item[4] : Disabled

  return (
    <div className="panel_sidebar desktop">
      {props.items.map((item, i) => (
        <PanelSidebarOption
          key={i}
          id={item[0]}
          text={item[1]}
          onClick={item[2]}
          icon={item[3]}
          selected={props.selected}
          disabled={item[4]}
        />
      ))}
    </div>
  );
};
