import React, { useEffect, useState } from "react";
import "../styles/components/Image.css";

export const ImagePlaceholder = ({ src, style, className, imgStyle, alt }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  return (
    <div
      className={`${className ? className : ""}${loaded ? "" : " loading"}`}
      style={style}
    >
      <img
        alt={alt}
        className={`placeholder${src == null ? " hidden" : ""}${
          loaded ? " loaded" : " transparent"
        }`}
        src={
          typeof src === "string"
            ? src
            : src instanceof Blob
            ? window.URL.createObjectURL(src)
            : null
        }
        style={imgStyle}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};
