import { useOutletContext, useParams } from "react-router";
import { AnimatedBusinessPage } from "../../components/animations/AnimatedBusinessPage";
import { useQuery } from "react-query";
import { ProductPage } from "../Product";
import { LineLoader } from "../../components/loaders/LineLoader";
import { getSingleItem } from "../../api/fetchers/main";

export const SingleProductPage = () => {
  const { business_id } = useOutletContext();

  const { business_name, collection_id, item_id } = useParams();

  const query = useQuery({
    queryFn: () => getSingleItem({ business_id, collection_id, item_id }),
    queryKey: `item-${business_id}-${collection_id}-${item_id}`,
    staleTime: 600000,
  });

  if (query.isSuccess) {
    return (
      <AnimatedBusinessPage>
        <ProductPage
          data={query.data}
          businessName={business_name}
          style={{ maxWidth: "100%", maxHeight: "none" }}
        />
      </AnimatedBusinessPage>
    );
  } else {
    return <LineLoader />;
  }
};
