import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { LineLoader } from "../../components/loaders/LineLoader";
import { usePopup } from "../../hooks/usePopup";
import { getBusinessInfo, updateBusiness } from "../../api/fetchers/main";
import { useAuth } from "../../hooks/useAuth";
import { AUTH_STATE_AUTHENTICATED } from "../../api/constants";

export const AboutPage = () => {
  function handleSave() {
    setChange(false);
    update_mutation.mutate({
      description: text,
    });
  }

  const popup = usePopup();
  const queryClient = useQueryClient();
  const auth = useAuth();

  const { data, isSuccess, isLoading } = useQuery({
    queryFn: () =>
      getBusinessInfo({
        business_id: auth.business_id,
      }),
    queryKey: `basic-business-info-${auth.business_id}`,
    onSuccess: (data) => {
      if (data.description) {
        setText(data.description);
      }
    },
    staleTime: 600000,
    enabled: auth.authState === AUTH_STATE_AUTHENTICATED,
  });

  const update_mutation = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      popup.showSuccess("Se actualizó correctamente.");
      queryClient.invalidateQueries(`basic-business-info-${auth.business_id}`);
    },
    onError: () => popup.showError("No se pudo actualizar."),
  });

  const [text, setText] = useState(isSuccess ? data.description : "");
  const [change, setChange] = useState(false);

  return (
    <AnimatedPanelPage>
      <p className="l linespace-l">
        <b>Acerca del negocio</b>
      </p>
      <div
        style={{
          display: "flex",
          width: "fit-content",
          flexDirection: "column",
          alignItems: "end",
          gap: "16px",
        }}
      >
        {isLoading ? <LineLoader /> : null}
        <textarea
          disabled={!isSuccess}
          rows={6}
          placeholder="Añade una descripción a tu negocio..."
          style={{ maxWidth: "calc(100vw - 40px)", width: "400px" }}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            if (!change) {
              setChange(true);
            }
          }}
        />
        {update_mutation.isLoading ? <LineLoader /> : null}
        <button
          disabled={!change}
          onClick={handleSave}
          className="semi_rounded"
          style={{ width: "fit-content" }}
        >
          Guardar cambios
        </button>
      </div>
    </AnimatedPanelPage>
  );
};
