import React, { cloneElement, useEffect, useRef, useState } from "react";
import "../styles/components/HorizontalMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_THRESHOLD } from "../api/constants";

export const HorizontalPageSelectorOption = ({
  text,
  icon,
  onClick,
  state,
  setter,
  index,
}) => {
  function handleClick() {
    if (state !== index) {
      setter(index);
    }

    if (typeof onClick === "function") {
      onClick();
    }
  }

  const isDesktop = useMediaQuery({
    query: `(min-width: ${MOBILE_SCREEN_THRESHOLD}px)`,
  });

  return (
    <div
      className={
        "horizontal_menu_option" + (state === index ? " selected" : "")
      }
      onClick={handleClick}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          style={{ marginRight: isDesktop ? "12px" : undefined }}
        />
      )}
      {isDesktop && text}
    </div>
  );
};

export const HorizontalPageSelector = ({ defaultSelect, children, style }) => {
  const [selectedIdx, setSelectedIdx] = useState(
    typeof defaultSelect !== "undefined" ? defaultSelect : -1
  );

  const main = useRef();

  useEffect(() => {
    main.current.addEventListener("wheel", (e) => {
      if (e.deltaY > 0) {
        try {
          main.current.scrollLeft += 50;
        } catch {}
      } else {
        try {
          main.current.scrollLeft -= 50;
        } catch {}
      }
    });
  });

  return (
    <div ref={main} className="horizontal_menu" style={{ ...style }}>
      {Array.isArray(children)
        ? children.map((child, i) => {
            return cloneElement(child, {
              state: selectedIdx,
              setter: setSelectedIdx,
              index: i,
              key: i,
            });
          })
        : cloneElement(children, {
            state: selectedIdx,
            setter: setSelectedIdx,
            index: 0,
            key: 0,
          })}
    </div>
  );
};
