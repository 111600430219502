import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useOutletContext, useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroller";
import { ImagePlaceholder } from "../../components/Image";
import { LineLoader } from "../../components/loaders/LineLoader";
import * as utils from "../../api/utils";
import * as constants from "../../api/constants";
import "../../styles/pages/business_page/BusinessCollectionProducts.css";
import { usePopup } from "../../hooks/usePopup";
import { getCollectionItems } from "../../api/fetchers/main";
import Separator from "../../components/Separator";
import { BackButton } from "../../components/BackButton";

const ProductOverlay = ({ name, price }) => {
  return (
    <div className="item_overlay">
      <p className="m bold text-white">Ver </p>
      <p
        style={{ position: "absolute", bottom: "45px" }}
        className="s bold text-white"
      >
        {name}
      </p>
      {price && (
        <div
          className="semi_rounded white"
          style={{ position: "absolute", bottom: "16px", padding: "4px 12px" }}
        >
          <p className="s text-purple bold">{utils.formatPrice(price)}</p>
        </div>
      )}
    </div>
  );
};

const Product = ({ name, price, tags, image, _id, colUuid }) => {
  function handleClick() {
    navigate("");
    popup.showProduct(
      { name, price, tags, image, _id, colUuid },
      business_name
    );
  }

  const { business_name } = useParams();

  const [overlay, setOverlay] = useState(false);

  const popup = usePopup();

  const navigate = useNavigate();

  return (
    <div
      className="item"
      style={{ "--aspect-ratio": 1 }}
      onMouseEnter={() => setOverlay(true)}
      onMouseLeave={() => setOverlay(false)}
      onClick={handleClick}
    >
      <ImagePlaceholder
        src={image.thumbnail_img}
        imgStyle={{ objectFit: "cover" }}
      />
      {overlay && <ProductOverlay name={name} price={price} />}
    </div>
  );
};

export const BusinessCollectionProductsPage = () => {
  function handleNewData(data) {
    setColUuid(data.pop()._id);

    if (data.length < ITEMS_PER_QUERY) {
      setItemsRemaining(false);
    }

    setItems([...items, ...data]);
  }

  function loadFunc(i) {
    if (!mutation.isLoading) {
      mutation.mutate({
        business_id: business_id,
        collection_id: collection_id,
        page: page,
        items: ITEMS_PER_QUERY,
      });
      setPage(page + 1);
    }
  }

  const ITEMS_PER_QUERY = 12;

  const { business_id } = useOutletContext();
  const { collection_id } = useParams();

  const [items, setItems] = useState([]);
  const [colUuid, setColUuid] = useState("");

  const [itemsRemaining, setItemsRemaining] = useState(true);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: getCollectionItems,
    onSuccess: handleNewData,
    onError: () => navigate(`../${constants.BUSINESS_COLLECTIONS_ROUTE}`),
    mutationKey: `${collection_id}-products-${page}`,
  });

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadFunc}
      hasMore={itemsRemaining}
      loader={<LineLoader key="infinite-scroll-loader" />}
    >
      <Separator text={collection_id.toUpperCase()} className="font-secondary">
        <BackButton
          onClick={() => navigate(`../${constants.BUSINESS_COLLECTIONS_ROUTE}`)}
          style={{
            position: "absolute",
            left: "20px",
            zIndex: "1",
          }}
        />
      </Separator>
      <div className="item_display">
        {items.map((item) => (
          <Product
            key={item._id}
            image={item.image}
            name={item.name}
            price={item.price}
            tags={item.tags}
            _id={item._id}
            colUuid={colUuid}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
};
