import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/LinkCard.css";
import { faCopy, faLink } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const LinkCard = ({ children, link, title = "" }) => {
  function handleCopy() {
    navigator.clipboard.writeText(link);
    setCopied(true);
  }

  link = encodeURI(link).replace("https://", "");

  const [copied, setCopied] = useState(false);

  return (
    <div className="link-card-container">
      {title && (
        <p className="sm black link-card-title">
          <FontAwesomeIcon icon={faLink} />
          {title}
        </p>
      )}
      <p className="link-card-text">{children}</p>
      <div className="link-card-link">
        <button className="link-card-copy" onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} />
          {copied ? "Copiado" : "Copiar"}
        </button>
        {link}
      </div>
    </div>
  );
};
