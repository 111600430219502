import { motion } from "framer-motion";
import { SLIDE } from "./framer-animations";

export const AnimatedBusinessPage = ({ children, style, className }) => {
  return (
    <motion.div
      className={className}
      style={{ ...style, transition: "linear" }}
      variants={SLIDE}
      initial="initial"
      animate="animate"
      transition={{ ease: "linear" }}
      exit="exit"
    >
      {children}
    </motion.div>
  );
};
