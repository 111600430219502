export const TaggedInput = ({ children, className, style }) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  return (
    <div style={{ display: "flex", ...style }} className={className}>
      <div className="input_tag">{children[0]}</div>
      {children.slice(1)}
    </div>
  );
};
