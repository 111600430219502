import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useState, useImperativeHandle } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../styles/components/SideBar.css";

const ANIMATION = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
};

const Option = (props) => {
  function handleClick() {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  }

  return (
    <div
      className={`sidebar_option${props.disabled ? " disabled" : ""}`}
      onClick={handleClick}
    >
      <p className="m text-white">{props.text}</p>
    </div>
  );
};

export const SideBar = forwardRef((props, ref) => {
  // item[0] = option.text
  // item[1] = option.onClick

  function handleOptionClick(onClick) {
    if (typeof onClick === "function") {
      onClick();
    }
    setHidden(true);
  }

  const [hidden, setHidden] = useState(props.show ? false : true);

  useImperativeHandle(ref, () => ({
    show: () => {
      setHidden(false);
    },
  }));

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="sidebar"
          variants={ANIMATION}
          animate="animate"
          initial="initial"
          exit="exit"
          transition={{ ease: "easeInOut" }}
        >
          <FontAwesomeIcon
            style={{ color: "white", margin: "40px 0px" }}
            icon={faBars}
            onClick={() => setHidden(true)}
          />

          {props.items.map((item, i) => (
            <Option
              key={i}
              text={item[0]}
              onClick={() => handleOptionClick(item[1])}
              disabled={item[2]}
            />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
});
