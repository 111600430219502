import { forwardRef, useImperativeHandle, useState } from "react";
import { SuccessCheckAnimation } from "../animations/Animations";
import { AnimatePresence, motion } from "framer-motion";
import { FADE, POPUP } from "../animations/framer-animations";
import "../../styles/components/Modals.css";

export const SuccessModal = forwardRef((props, ref) => {
  function onClose() {
    try {
      setHidden(true);
      onCloseFunc();
      setOnCloseFunc(() => () => {});
    } catch {}
  }

  function show(msg, onCloseF) {
    setMessage(msg);
    if (typeof onCloseF === "function") {
      setOnCloseFunc(() => onCloseF);
    }
    setHidden(false);
  }

  const [hidden, setHidden] = useState(true);

  const [message, setMessage] = useState("");
  const [onCloseFunc, setOnCloseFunc] = useState(() => () => {});

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="modal_container"
          variants={FADE}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <motion.div
            className="modal rounded"
            variants={POPUP}
            transition={POPUP.transition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <SuccessCheckAnimation />
            <p className="sm centered linespace-l">{message}</p>
            <button className="centered wide" onClick={onClose}>
              Cerrar
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
