import { Content } from "../components/Content";
import { FormInfoMsg } from "../components/FormMessages";
import "../styles/pages/PrivacyPolicy.css";

export const PrivacyPolicy = () => {
  return (
    <Content className="privacy-policy-page centered">
      <h1>Política de Privacidad</h1>
      <p className="s text-light" style={{ margin: 0 }}>
        Última modificación: 29 de Julio de 2023
      </p>
      <hr />
      <p>
        Vitriina (&quot;nosotros&quot;, &quot;nuestro&quot; o &quot;nos&quot;)
        se compromete a proteger su privacidad. Esta Política de privacidad
        explica cómo Vitriina recopila, usa y divulga su información personal.
      </p>
      <p>
        Esta Política de privacidad se aplica a nuestro sitio web,{" "}
        <a href="https://vitriina.com">https://vitriina.com</a>, y sus
        subdominios asociados (colectivamente, nuestro &quot;Servicio&quot;). Al
        acceder o utilizar nuestro Servicio, usted indica que ha leído,
        comprendido y aceptado nuestra recopilación, almacenamiento, uso y
        divulgación de su información personal como se describe en esta Política
        de privacidad.
      </p>
      <h2 id="definiciones-y-t-rminos-clave">
        1. Definiciones y Términos Clave
      </h2>
      <p>
        Para ayudar a explicar las cosas de la manera más clara posible en esta
        Política de privacidad, cada vez que se hace referencia a cualquiera de
        estos términos, se definen estrictamente como:
      </p>
      <ul>
        <li>
          <strong>Cookie</strong>: pequeña cantidad de datos generados por un
          sitio web y guardados por su navegador web. Se utiliza para
          identificar su navegador, proporcionar análisis, recordar información
          sobre usted, como su preferencia de idioma o información de inicio de
          sesión.
        </li>
        <li>
          <strong>Compañía</strong>: cuando esta política menciona
          &quot;Compañía&quot;, &quot;nosotros&quot;, &quot;nos&quot; o
          &quot;nuestro&quot;, se refiere a Vitriina que es responsable de su
          información en virtud de esta Política de privacidad.
        </li>
        <li>
          <strong>Plataforma</strong>: sitio web de Internet, aplicación web o
          aplicación digital de cara al público de Vitriina.
        </li>
        <li>
          <strong>País</strong>: donde se encuentra Vitriina o los propietarios
          / fundadores de Vitriina en este caso es Colombia.
        </li>
        <li>
          <strong>Cliente</strong>: se refiere a la empresa, organización o
          persona que se registra para utilizar el Servicio Vitriina para
          gestionar las relaciones con sus consumidores o usuarios del servicio.
        </li>
        <li>
          <strong>Dispositivo</strong>: cualquier dispositivo conectado a
          Internet, como un teléfono, tablet, computadora o cualquier otro
          dispositivo que se pueda usar para visitar Vitriina y usar los
          servicios.
        </li>
        <li>
          <strong>Dirección IP</strong>: a cada dispositivo conectado a Internet
          se le asigna un número conocido como dirección de protocolo de
          Internet (IP). Estos números generalmente se asignan en bloques
          geográficos. A menudo, se puede utilizar una dirección IP para
          identificar la ubicación desde la que un dispositivo se conecta a
          Internet.
        </li>
        <li>
          <strong>Personal</strong>: se refiere a aquellas personas que son
          empleadas por Vitriina o están bajo contrato para realizar un servicio
          en nombre de una de las partes.
        </li>
        <li>
          <strong>Datos Personales</strong>: cualquier información que directa,
          indirectamente o en conexión con otra información, incluido un número
          de identificación personal, permita la identificación de una persona
          física.
        </li>
        <li>
          <strong>Servicio</strong>: se refiere al servicio brindado por
          Vitriina como se describe en los términos relativos (si están
          disponibles) y en esta plataforma.
        </li>
        <li>
          <strong>Terceros</strong>: se refiere a anunciantes, patrocinadores de
          concursos, socios promocionales y de marketing, y otros que brindan
          nuestro contenido o cuyos productos o servicios que creemos que pueden
          interesarle.
        </li>
        <li>
          <strong>Sitio web</strong>: el sitio de Vitriina al que se puede
          acceder a través de esta URL:{" "}
          <a href="https://vitriina.com">https://vitriina.com</a>.
        </li>
        <li>
          <strong>Usted</strong>: una persona o entidad que está registrada con
          Vitriina para utilizar los Servicios.{" "}
        </li>
      </ul>
      <h2 id="-qu-informaci-n-recopilamos-">
        2. ¿Qué información recopilamos?
      </h2>
      <p>
        Recopilamos información suya cuando visita nuestra plataforma, se
        registra en nuestro sitio, realiza un pedido, se suscribe a nuestro
        boletín, responde a una encuesta o completa un formulario.
      </p>
      <ul>
        <li>Nombre / nombre de usuario</li>
        <li>Números de teléfono</li>
        <li>Correos electrónicos </li>
        <li>Direcciones de facturación</li>
        <li>Números de tarjetas de débito / crédito</li>
        <li>Contraseña</li>
      </ul>
      <h2 id="-c-mo-usamos-la-informaci-n-que-recopilamos-">
        3. ¿Cómo usamos la información que recopilamos?
      </h2>
      <p>
        Cualquiera de la información que recopilamos de usted puede usarse de
        una de las siguientes maneras:
      </p>
      <ul>
        <li>
          Para personalizar su experiencia (su información nos ayuda a responder
          mejor a sus necesidades individuales)
        </li>
        <li>
          Para mejorar nuestra plataforma (nos esforzamos continuamente por
          mejorar lo que ofrece nuestra plataforma en función de la información
          y los comentarios que recibimos de usted)
        </li>
        <li>
          Para mejorar el servicio al cliente (su información nos ayuda a
          responder de manera más efectiva a sus solicitudes de servicio al
          cliente y necesidades de soporte)
        </li>
        <li>Para procesar transacciones</li>
        <li>
          Para administrar un concurso, promoción, encuesta u otra
          característica del sitio
        </li>
        <li>Para enviar correos electrónicos periódicos</li>
      </ul>
      <h2 id="-cu-ndo-usa-vitriina-la-informaci-n-del-usuario-final-de-terceros-">
        4. ¿Cuándo usa Vitriina la información del usuario final de terceros?
      </h2>
      <p>
        Vitriina recopilará los datos del usuario final necesarios para
        proporcionar los servicios de Vitriina a nuestros clientes. Los usuarios
        finales pueden proporcionarnos voluntariamente la información que han
        puesto a disposición en los sitios web de las redes sociales. Si nos
        proporciona dicha información, podemos recopilar información disponible
        públicamente de los sitios web de redes sociales que ha indicado. Puede
        controlar la cantidad de información que los sitios web de redes
        sociales hacen pública visitando estos sitios web y cambiando su
        configuración de privacidad.
      </p>
      <h2 id="-cu-ndo-usa-vitriina-la-informaci-n-del-cliente-de-terceros-">
        5. ¿Cuándo usa Vitriina la información del cliente de terceros?
      </h2>
      <p>
        Recibimos información de terceros cuando se comunica con nosotros. Por
        ejemplo, cuando nos envía su dirección de correo electrónico para
        mostrar interés en convertirse en cliente de Vitriina, recibimos
        información de un tercero que brinda servicios automáticos de detección
        de fraude a Vitriina. Ocasionalmente, también recopilamos información
        que se pone a disposición del público en los sitios web de redes
        sociales. Puede controlar la cantidad de información que los sitios web
        de redes sociales hacen pública visitando estos sitios web y cambiando
        su configuración de privacidad.
      </p>
      <h2 id="-compartimos-la-informaci-n-que-recopilamos-con-terceros-">
        6. ¿Compartimos la información que recopilamos con terceros?
      </h2>
      <p>
        Podemos compartir la información que recopilamos, tanto personal como no
        personal, con terceros como anunciantes, patrocinadores de concursos,
        socios promocionales y de marketing, y otros que proporcionan nuestro
        contenido o cuyos productos o servicios creemos que pueden interesarle.
        También podemos compartirlo con nuestras compañías afiliadas y socios
        comerciales actuales y futuros, y si estamos involucrados en una fusión,
        venta de activos u otra reorganización comercial, también podemos
        compartir o transferir su información personal y no personal a nuestros
        sucesores en interés.
      </p>
      <p>
        Podemos contratar proveedores de servicios de terceros de confianza para
        que realicen funciones y nos brinden servicios, como el alojamiento y el
        mantenimiento de nuestros servidores y la plataforma, almacenamiento y
        administración de bases de datos, administración de correo electrónico,
        marketing de almacenamiento, procesamiento de tarjetas de crédito,
        servicio y cumplimiento de pedidos de productos y servicios que puede
        comprar a través de la plataforma. Es probable que compartamos su
        información personal, y posiblemente alguna información no personal, con
        estos terceros para permitirles realizar estos servicios para nosotros y
        para usted.
      </p>
      <p>
        Podemos compartir partes de los datos de nuestro archivo de registro,
        incluidas las direcciones IP, con fines analíticos con terceros, como
        socios de análisis web, desarrolladores de aplicaciones y redes
        publicitarias. Si se comparte su dirección IP, se puede utilizar para
        estimar la ubicación general y otros datos tecnológicos, como la
        velocidad de conexión, si ha visitado la plataforma en una ubicación
        compartida y el tipo de dispositivo utilizado para visitar la
        plataforma. Pueden agregar información sobre nuestra publicidad y lo que
        ve en la plataforma y luego proporcionar auditorías, investigaciones e
        informes para nosotros y nuestros anunciantes.
      </p>
      <p>
        También podemos divulgar información personal y no personal sobre usted
        al gobierno, a funcionarios encargados de hacer cumplir la ley o a
        terceros privados, según consideremos, a nuestro exclusivo criterio,
        necesario o apropiado para responder a reclamos, procesos legales
        (incluidas citaciones), para proteger nuestra derechos e intereses o los
        de un tercero, la seguridad del público o de cualquier persona, para
        prevenir o detener cualquier actividad ilegal, poco ética o legalmente
        procesable, o para cumplir con las órdenes judiciales, leyes, reglas y
        regulaciones aplicables.
      </p>
      <h2 id="-d-nde-y-cu-ndo-se-recopila-la-informaci-n-de-los-clientes-y-usuarios-finales-">
        7. ¿Dónde y cuándo se recopila la información de los clientes y usuarios
        finales?
      </h2>
      <p>
        Vitriina recopilará la información personal que nos envíe. También
        podemos recibir información personal sobre usted de terceros como se
        describe anteriormente.
      </p>
      <h2 id="-c-mo-utilizamos-su-direcci-n-de-correo-electr-nico-">
        8. ¿Cómo utilizamos su dirección de correo electrónico?
      </h2>
      <p>
        Al enviar su dirección de correo electrónico en esta plataforma, acepta
        recibir nuestros correos electrónicos. Puede cancelar su participación
        en cualquiera de estas listas de correo electrónico en cualquier momento
        haciendo clic en el enlace de exclusión voluntaria u otra opción para
        cancelar la suscripción que se incluye en el correo electrónico
        respectivo.
      </p>
      <p>
        Solo enviamos correos electrónicos a personas que nos han autorizado a
        contactarlos, ya sea directamente o a través de un tercero. No enviamos
        correos electrónicos comerciales no solicitados, porque odiamos el spam
        tanto como usted.
      </p>
      <p>
        Al enviar su dirección de correo electrónico, también acepta permitirnos
        usar su dirección de correo electrónico para la orientación de la
        audiencia del cliente en sitios como Facebook, donde mostramos
        publicidad personalizada a personas específicas que han optado por
        recibir nuestras comunicaciones. Las direcciones de correo electrónico
        enviadas solo a través de la página de procesamiento de pedidos se
        utilizarán con el único propósito de enviarle información y
        actualizaciones relacionadas con su pedido. Sin embargo, si nos ha
        proporcionado el mismo correo electrónico a través de otro método,
        podemos usarlo para cualquiera de los fines establecidos en esta
        Política.
      </p>
      <FormInfoMsg
        text={
          <>
            <strong>Nota</strong>
            <br />
            Si en algún momento desea cancelar la suscripción para no recibir
            correos electrónicos futuros, incluimos instrucciones detalladas
            para cancelar la suscripción en la parte inferior de cada correo
            electrónico.
          </>
        }
      ></FormInfoMsg>
      <h2 id="-podr-a-transferirse-mi-informaci-n-a-otros-pa-ses-">
        9. ¿Podría transferirse mi información a otros países?
      </h2>
      <p>
        Vitriina está incorporada en Colombia. La información recopilada a
        través de nuestro sitio web, a través de interacciones directas con
        usted o del uso de nuestros servicios de ayuda puede transferirse de vez
        en cuando a nuestras oficinas o personal, o a terceros, ubicados en todo
        el mundo, y puede verse y alojarse en cualquier lugar del mundo,
        incluidos los países que pueden no tener leyes de aplicación general que
        regulen el uso y la transferencia de dichos datos. En la mayor medida
        permitida por la ley aplicable, al utilizar cualquiera de los
        anteriores, usted acepta voluntariamente la transferencia
        transfronteriza y el alojamiento de dicha información.
      </p>
      <h2 id="-la-informaci-n-recopilada-a-trav-s-del-servicio-vitriina-es-segura-">
        10. ¿La información recopilada a través del Servicio Vitriina es segura?
      </h2>
      <p>
        Tomamos precauciones para proteger la seguridad de su información.
        Contamos con procedimientos físicos, electrónicos y administrativos para
        ayudar a salvaguardar, prevenir el acceso no autorizado, mantener la
        seguridad de los datos y usar correctamente su información. Sin embargo,
        ni las personas ni los sistemas de seguridad son infalibles, incluidos
        los sistemas de cifrado. Además, las personas pueden cometer delitos
        intencionales, cometer errores o no seguir las políticas. Por lo tanto,
        aunque hacemos todos los esfuerzos razonables para proteger su
        información personal, no podemos garantizar su seguridad absoluta.
      </p>
      <p>
        Si la ley aplicable impone algún deber irrenunciable de proteger su
        información personal, usted acepta que la mala conducta intencional
        serán los estándares utilizados para medir nuestro cumplimiento con ese
        deber.
      </p>
      <h2 id="-puedo-actualizar-o-corregir-mi-informaci-n-">
        11. ¿Puedo actualizar o corregir mi información?
      </h2>
      <p>
        Los derechos que tienes para solicitar actualizaciones o correcciones de
        la información que recopila Vitriina dependen de tu relación con
        Vitriina. El personal puede actualizar o corregir su información según
        se detalla en nuestras políticas de empleo internas de la empresa.
      </p>
      <p>
        Los clientes tienen derecho a solicitar la restricción de ciertos usos y
        divulgaciones de información de identificación personal de la siguiente
        manera. Puede comunicarse con nosotros para (1) actualizar o corregir su
        información de identificación personal, (2) cambiar sus preferencias con
        respecto a las comunicaciones y otra información que recibe de nosotros,
        o (3) eliminar la información de identificación personal que se mantiene
        sobre usted en nuestro sistema (sujeto al siguiente párrafo), cancelando
        su cuenta.
      </p>
      <p>
        Dichas actualizaciones, correcciones, cambios y eliminaciones no tendrán
        ningún efecto sobre otra información que mantenemos o información que
        hayamos proporcionado a terceros de acuerdo con esta Política de
        privacidad antes de dicha actualización, corrección, cambio o
        eliminación. Para proteger su privacidad y seguridad, podemos tomar
        medidas razonables (como solicitar una contraseña única) para verificar
        su identidad antes de otorgarle acceso a su perfil o hacer correcciones.
        Usted es responsable de mantener en secreto su contraseña única y la
        información de su cuenta en todo momento.
      </p>
      <p>
        Debe tener en cuenta que tecnológicamente no es posible eliminar todos y
        cada uno de los registros de la información que nos ha proporcionado de
        nuestro sistema. La necesidad de realizar copias de seguridad de
        nuestros sistemas para proteger la información de pérdidas involuntarias
        significa que puede existir una copia de su información en una forma que
        no se pueda borrar y que será difícil o imposible de localizar para
        nosotros. Inmediatamente después de recibir su solicitud, toda la
        información personal almacenada en las bases de datos que usamos
        activamente y otros medios fácilmente buscables se actualizará,
        corregirá, cambiará o eliminará, según corresponda, tan pronto como y en
        la medida en que sea razonable y técnicamente posible.
      </p>
      <p>
        Si es un usuario final y desea actualizar, eliminar o recibir cualquier
        información que tengamos sobre usted, puede hacerlo poniéndose en
        contacto con la organización de la que es cliente.
      </p>
      <h2 id="-cu-nto-tiempo-conservamos-su-informaci-n-">
        12. ¿Cuánto tiempo conservamos su información?
      </h2>
      <p>
        Conservamos su información solo mientras la necesitemos para
        proporcionarle Vitriina y cumplir con los propósitos descritos en esta
        política. Este también es el caso de cualquier persona con la que
        compartamos su información y que lleve a cabo servicios en nuestro
        nombre. Cuando ya no necesitemos usar su información y no sea necesario
        que la conservemos para cumplir con nuestras obligaciones legales o
        reglamentarias, la eliminaremos de nuestros sistemas o la
        despersonalizaremos para que no podamos identificarlo.
      </p>
      <h2 id="-c-mo-protegemos-su-informaci-n-">
        13. ¿Cómo protegemos su información?
      </h2>
      <p>
        Implementamos una variedad de medidas de seguridad para mantener la
        seguridad de su información personal cuando realiza un pedido, ingresa,
        envía o accede a su información personal. Ofrecemos el uso de un
        servidor seguro. Toda la información confidencial / crediticia
        suministrada se transmite a través de la tecnología Secure Socket Layer
        (SSL) y luego se encripta en nuestra base de datos de proveedores de
        pasarela de pago solo para que sea accesible por aquellos autorizados
        con derechos especiales de acceso a dichos sistemas, y deben mantener la
        información confidencial. Después de una transacción, su información
        privada (tarjetas de crédito, números de seguro social, finanzas, etc.)
        nunca se archiva. Sin embargo, no podemos garantizar la seguridad
        absoluta de la información que transmita a Vitriina ni garantizar que su
        información en el servicio no sea accedida, divulgada, alterada o
        destruida por una infracción de cualquiera de nuestras condiciones
        físicas, salvaguardias técnicas o de gestión.
      </p>
      <h2 id="venta-de-negocio">14. Venta de Negocio</h2>
      <p>
        Nos reservamos el derecho de transferir información a un tercero en el
        caso de una venta, fusión u otra transferencia de todos o
        sustancialmente todos los activos de Vitriina o cualquiera de sus
        afiliadas corporativas (como se define en este documento), o la porción
        de Vitriina o cualquiera de sus Afiliadas corporativas con las que se
        relaciona el Servicio, o en el caso de que discontinuemos nuestro
        negocio o presentemos una petición o hayamos presentado una petición
        contra nosotros en caso de quiebra, reorganización o procedimiento
        similar, siempre que el tercero acepte adherirse a los términos de esta
        Política de privacidad.
      </p>
      <p>
        Afiliados podemos divulgar información (incluida la información
        personal) sobre usted a nuestros afiliados corporativos. Para los
        propósitos de esta Política de Privacidad, &quot;Afiliado
        Corporativo&quot; significa cualquier persona o entidad que directa o
        indirectamente controla, está controlada por o está bajo control común
        con Vitriina, ya sea por propiedad o de otra manera. Cualquier
        información relacionada con usted que proporcionemos a nuestros
        afiliados corporativos será tratada por dichos afiliados corporativos de
        acuerdo con los términos de esta política de privacidad.
      </p>
      <h2 id="ley-que-rige">15. Ley que Rige</h2>
      <p>
        Esta Política de privacidad se rige por las leyes de Colombia sin tener
        en cuenta su disposición sobre conflicto de leyes. Usted acepta la
        jurisdicción exclusiva de los tribunales en relación con cualquier
        acción o disputa que surja entre las partes en virtud de esta Política
        de privacidad o en relación con ella, excepto aquellas personas que
        puedan tener derecho a presentar reclamaciones en virtud del Escudo de
        privacidad o el marco suizo-estadounidense.
      </p>
      <p>
        Las leyes de Colombia, excluyendo sus conflictos de leyes, regirán este
        Acuerdo y su uso de la plataforma. Su uso de la plataforma también puede
        estar sujeto a otras leyes locales, estatales, nacionales o
        internacionales.
      </p>
      <p>
        Al usar Vitriina o comunicarse con nosotros directamente, significa que
        acepta esta Política de privacidad. Si no está de acuerdo con esta
        Política de privacidad, no debe interactuar con nuestro sitio web ni
        utilizar nuestros servicios. El uso continuo del sitio web, la
        interacción directa con nosotros o después de la publicación de cambios
        en esta Política de privacidad que no afecten significativamente el uso
        o divulgación de su información personal significará que acepta esos
        cambios.
      </p>
      <h2 id="tu-consentimiento">16. Tu consentimiento</h2>
      <p>
        Hemos actualizado nuestra Política de privacidad para brindarle total
        transparencia sobre lo que se establece cuando visita nuestro sitio y
        cómo se utiliza. Al utilizar nuestra plataforma, registrar una cuenta o
        realizar una compra, por la presente acepta nuestra Política de
        privacidad y acepta sus términos.
      </p>
      <h2 id="enlaces-a-otros-sitios-web">17. Enlaces a otros Sitios Web</h2>
      <p>
        Esta Política de privacidad se aplica solo a los Servicios. Los
        Servicios pueden contener enlaces a otros sitios web que Vitriina no
        opera ni controla. No somos responsables por el contenido, la precisión
        o las opiniones expresadas en dichos sitios web, y dichos sitios web no
        son investigados, monitoreados o verificados por nuestra precisión o
        integridad. Recuerde que cuando utiliza un enlace para ir desde los
        Servicios a otro sitio web, nuestra Política de privacidad deja de estar
        en vigor. Su navegación e interacción en cualquier otro sitio web,
        incluidos aquellos que tienen un enlace en nuestra plataforma, están
        sujetos a las propias reglas y políticas de ese sitio web. Dichos
        terceros pueden utilizar sus propias cookies u otros métodos para
        recopilar información sobre usted.
      </p>
      <h2 id="cookies">18. Cookies</h2>
      <p>
        Vitriina utiliza &quot;cookies&quot; para identificar las áreas de
        nuestro sitio web que ha visitado. Una cookie es una pequeña porción de
        datos que su navegador web almacena en su computadora o dispositivo
        móvil. Usamos cookies para mejorar el rendimiento y la funcionalidad de
        nuestra plataforma, pero no son esenciales para su uso. Sin embargo, sin
        estas cookies, es posible que ciertas funciones, como los videos, no
        estén disponibles o se le solicitará que ingrese sus datos de inicio de
        sesión cada vez que visite la plataforma, ya que no podríamos recordar
        que había iniciado sesión anteriormente. La mayoría de los navegadores
        web se pueden configurar para desactivar el uso de cookies. Sin embargo,
        si desactiva las cookies, es posible que no pueda acceder a la
        funcionalidad de nuestro sitio web correctamente o en absoluto. Nunca
        colocamos información de identificación personal en cookies.
      </p>
      <h2 id="detalles-del-pago">19. Detalles del Pago</h2>
      <p>
        Los datos asociados a tarjetas de crédito, información bancaria, etc.
        son procesados y almacenados por MercadoPago. Por lo tanto, Vitriina no
        almacena ningún dato financiero que pueda comprometer su seguridad.
      </p>
      <h2 id="privacidad-de-los-ni-os">20. Privacidad de los Niños</h2>
      <p>
        Recopilamos información de niños menores de 13 años solo para mejorar
        nuestros servicios. Si usted es padre o tutor y sabe que su hijo nos ha
        proporcionado datos personales sin su permiso, comuníquese con nosotros.
        Si nos damos cuenta de que hemos recopilado datos personales de
        cualquier persona menor de 13 años sin la verificación del
        consentimiento de los padres, tomamos medidas para eliminar esa
        información de nuestros servidores.
      </p>
      <h2 id="cambios-en-nuestra-pol-tica-de-privacidad">
        21. Cambios en nuestra Política de Privacidad
      </h2>
      <p>
        Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que
        debamos realizar cambios en esta Política de privacidad para que
        reflejen con precisión nuestro Servicio y nuestras políticas. A menos
        que la ley exija lo contrario, le notificaremos (por ejemplo, a través
        de nuestro Servicio) antes de realizar cambios en esta Política de
        privacidad y le daremos la oportunidad de revisarlos antes de que entren
        en vigencia.
      </p>
      <p>
        Luego, si continúa utilizando el Servicio, estará sujeto a la Política
        de privacidad actualizada. Si no desea aceptar esta o cualquier Política
        de privacidad actualizada, puede eliminar su cuenta.
      </p>
      <h2 id="servicios-de-terceros">22. Servicios de terceros</h2>
      <p>
        Podemos mostrar, incluir o poner a disposición contenido de terceros
        (incluidos datos, información, aplicaciones y otros servicios de
        productos) o proporcionar enlaces a sitios web o servicios de terceros
        (&quot;Servicios de terceros&quot;). Usted reconoce y acepta que
        Vitriina no será responsable de ningún Servicio de terceros, incluida su
        precisión, integridad, puntualidad, validez, cumplimiento de los
        derechos de autor, legalidad, decencia, calidad o cualquier otro aspecto
        de los mismos. Vitriina no asume ni tendrá ninguna obligación o
        responsabilidad ante usted o cualquier otra persona o entidad por los
        Servicios de terceros. Los Servicios de terceros y los enlaces a los
        mismos se brindan únicamente para su conveniencia y usted accede a ellos
        y los usa completamente bajo su propio riesgo y sujeto a los términos y
        condiciones de dichos terceros.
      </p>
      <h2 id="tecnolog-as-de-seguimiento-api-de-google-maps">
        23. Tecnologías de Seguimiento API de Google Maps
      </h2>
      <p>
        La API de Google Maps es una herramienta sólida que se puede utilizar
        para crear un mapa personalizado, un mapa de búsqueda, funciones de
        registro, mostrar la sincronización de datos en vivo con la ubicación,
        planificar rutas o crear un mashup, solo por nombrar algunos.
      </p>
      <p>
        La API de Google Maps puede recopilar información suya y de su
        dispositivo por motivos de seguridad.
      </p>
      <p>
        La API de Google Maps recopila información que se mantiene de acuerdo
        con su Política de privacidad.
      </p>
      <h2>24. Rastreo de Google Analytics</h2>
      <p>
        En Vitriina, valoramos la privacidad de nuestros usuarios y nos
        comprometemos a mantener la confidencialidad y seguridad de su
        información personal. Esta sección de nuestra Política de Privacidad
        está dedicada a explicar cómo utilizamos Google Analytics para recopilar
        y analizar datos de los visitantes de nuestro sitio web. Por favor, lee
        la siguiente información detenidamente para comprender nuestras
        prácticas en cuanto a la recopilación y el procesamiento de datos.
      </p>
      <h2 className="indented">24.1. ¿Qué es Google Analytics?</h2>
      <p className="indented">
        Google Analytics es un servicio de análisis web proporcionado por Google
        LLC ("Google"). Permite a los propietarios y administradores de sitios
        web rastrear y analizar diversos aspectos del tráfico del sitio, el
        comportamiento de los usuarios y otros datos valiosos. Este servicio nos
        ayuda a comprender cómo los usuarios interactúan con nuestro sitio web,
        qué páginas visitan, cuánto tiempo pasan en cada página, los
        dispositivos que utilizan, su ubicación geográfica y más. Mediante la
        recopilación de estos datos, podemos mejorar el rendimiento de nuestro
        sitio web y adaptarlo para satisfacer mejor las necesidades de nuestros
        visitantes.
      </p>
      <h2 className="indented">
        24.2. Información Recopilada por Google Analytics
      </h2>
      <p className="indented">
        Google Analytics utiliza cookies y otras tecnologías de seguimiento para
        recopilar información sobre tus interacciones con nuestro sitio web. Los
        datos recopilados pueden incluir, entre otros, lo siguiente:
      </p>
      <ul>
        <li>Tu dirección IP</li>
        <li>Información sobre el tipo de dispositivo y navegador</li>
        <li>Proveedor de servicios de Internet</li>
        <li>Sistema operativo</li>
        <li>Páginas visitadas en nuestro sitio web</li>
        <li>Tiempo de permanencia en cada página</li>
        <li>Sitio web de referencia o fuente que te condujo a nuestro sitio</li>
        <li>
          Acciones realizadas en el sitio, como clics, descargas y envío de
          formularios
        </li>
      </ul>
      <h2 className="indented">24.3. Uso de los Datos de Google Analytics</h2>
      <p className="indented">
        La información recopilada a través de Google Analytics se utiliza
        únicamente con fines analíticos. Utilizamos estos datos para obtener
        información sobre el rendimiento de nuestro sitio web y mejorar su
        contenido, diseño y experiencia de usuario. Al comprender las
        preferencias y el comportamiento de nuestros visitantes, podemos
        optimizar el sitio web y proporcionar contenido relevante y atractivo.
      </p>
      <h2 className="indented">24.4. Divulgación a Terceros</h2>
      <p className="indented">
        No vendemos, intercambiamos ni transferimos ninguna información personal
        a terceros, incluidos los datos recopilados a través de Google
        Analytics. La información se utiliza exclusivamente para nuestros
        análisis internos y no se comparte con entidades externas.
      </p>
      <h2 className="indented">24.5. Retención de Datos de Google Analytics</h2>
      <p className="indented">
        A partir de la fecha de esta Política de Privacidad, hemos implementado
        el período estándar de retención de datos de 2 meses para datos de
        usuarios y eventos en Google Analytics. Después de este período, los
        datos se eliminan automáticamente de nuestra cuenta de Google Analytics.
      </p>
      <h2 className="indented">
        24.6. Opción para Desactivar Google Analytics
      </h2>
      <p className="indented">
        Si prefieres que tus datos no sean recopilados y procesados por Google
        Analytics, puedes optar por desactivarlos instalando el Complemento de
        inhabilitación para navegadores de Google Analytics. Este complemento
        evita que Google Analytics JavaScript (ga.js, analytics.js y dc.js)
        comparta información sobre la actividad de las visitas con Google
        Analytics. Ten en cuenta que desactivar Google Analytics no afectará tu
        capacidad para acceder o utilizar nuestro sitio web.
      </p>
      <h2 id="almacenamiento-local">25. Almacenamiento Local</h2>
      <p>
        El almacenamiento local, a veces conocido como almacenamiento DOM,
        proporciona a las aplicaciones web métodos y protocolos para almacenar
        datos del lado del cliente. El almacenamiento web admite el
        almacenamiento de datos persistente, similar a las cookies, pero con una
        capacidad muy mejorada y sin información almacenada en el encabezado de
        solicitud HTTP.
      </p>
      <h2 id="sessions">26. Sessions</h2>
      <p>
        Vitriina utiliza &quot;Sesiones&quot; para identificar las áreas de
        nuestro sitio web que ha visitado. Una sesión es un pequeño fragmento de
        datos que su navegador web almacena en su computadora o dispositivo
        móvil.
      </p>
    </Content>
  );
};
