import { Content } from "../components/Content";
import { PlanCompareTable } from "../components/PlanCompareTable";

export const SubscriptionPlans = () => {
  return (
    <Content>
      <div style={{ position: "relative", top: "40px" }}>
        <p className="xl black centered linespace-xl">Planes de suscripción</p>
        <p
          className="sm centered linespace-xxl"
          style={{
            maxWidth: "600px",
            paddingLeft: "10px",
            paddingRight: "10px",
            textAlign: "center",
          }}
        >
          Elige el plan que más se acomode a las necesidades de tu negocio.
        </p>
        <section className="purple">
          <PlanCompareTable />
        </section>
      </div>
    </Content>
  );
};
