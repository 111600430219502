import { forwardRef, useImperativeHandle, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FADE, SLIDEUP, SLIDE } from "../animations/framer-animations";
import "../../styles/components/Modals.css";
import "../../styles/components/SelectOptionsModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export const SelectOptionsModal = forwardRef((props, ref) => {
  function show(options_, onSelect_) {
    setOptions(options_);
    setOnSelect(() => onSelect_);
    setHidden(false);
  }

  function handleSelect(option) {
    onSelect(option.value);
    setHidden(true);
    setSearch("");
  }

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const [hidden, setHidden] = useState(true);
  const [options, setOptions] = useState([]);
  const [onSelect, setOnSelect] = useState(() => () => {});
  const [search, setSearch] = useState("");

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="modal_container"
          variants={FADE}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="container white semi_rounded select-options-container"
            variants={SLIDEUP}
            initial="initial"
            exit="exit"
            animate="animate"
            transition={{
              delayChildren: 0.3,
              staggerChildren: 0.05,
              duration: 0.2,
            }}
          >
            <input
              className="underlined"
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              autoFocus
            />
            <hr />
            {options
              .filter((o) => !search || o.value.toLowerCase().includes(search))
              .map((option, index) => {
                return (
                  <motion.div
                    key={index}
                    variants={{
                      initial: SLIDE.initial,
                      animate: SLIDE.animate,
                    }}
                    className="select-option semi_rounded"
                    onClick={() => handleSelect(option)}
                  >
                    {option.ES}
                  </motion.div>
                );
              })}
          </motion.div>
          {!hidden && (
            <FontAwesomeIcon
              className="button shadowed"
              style={{
                position: "absolute",
                right: "40px",
                top: "40px",
                fontSize: "40px",
                color: "white",
              }}
              icon={faXmark}
              onClick={() => setHidden(true)}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
});
