import * as errors from "../errors";
import axios from "axios";

const API = process.env.REACT_APP_API_URL;

const fetcher = axios.create({
  baseURL: API + "/auth",
  withCredentials: true,
});

fetcher.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function saveSessionCookie() {
  localStorage.setItem("sessionCookie", "true");
}

export function deleteSessionCookie() {
  localStorage.removeItem("sessionCookie");
}

export async function signup({
  bsns_name,
  username,
  con_name,
  con_email,
  con_phone,
  password,
}) {
  try {
    return (
      await fetcher.post("/signup", {
        bsns_name: bsns_name.trim(),
        username: username.trim(),
        con_name: con_name.trim(),
        con_email: con_email.trim(),
        con_phone: con_phone?.trim(),
        password: password.trim(),
      })
    ).data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function getBasicUserInfo() {
  try {
    const res = await fetcher.get("/log");
    saveSessionCookie();
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function login({ username, password }) {
  try {
    const res = await fetcher.post("/log", {
      username: username,
      password: password,
    });
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function validateTwoStepAuthToken({ _id, value }) {
  try {
    const res = await fetcher.post("/two-step-auth", {
      _type: "two-step-auth",
      _id: _id,
      value: value,
    });
    saveSessionCookie();
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function logout() {
  try {
    const res = await fetcher.get("/logout");
    deleteSessionCookie();
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function getAccountSessions() {
  try {
    const res = await fetcher.get("/sessions");
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function closeSession({ session_id }) {
  errors.validateArgs(session_id);

  try {
    const res = await fetcher.delete(`/sessions/${session_id}`);
    if (res.data.logout) {
      deleteSessionCookie();
      return true;
    }
    return false;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function closeAllSessions() {
  try {
    await fetcher.delete("/sessions");
    deleteSessionCookie();
    return {};
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function turnTwoStepAuth({ value }) {
  errors.validateArgs(value);

  try {
    const res = await fetcher.get(`/turn-two-step-auth?value=${value}`);
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function changePassword({ current_password, new_password }) {
  try {
    const res = await fetcher.post("/change-password", {
      current_password: current_password,
      new_password: new_password,
    });
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function requestPasswordReset({ username }) {
  try {
    const res = await fetcher.get(`/reset-password/${username}`);
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function resetPassword({ password, token }) {
  errors.validateArgs(password, token);

  try {
    const res = await fetcher.post("/reset-password", {
      password: password,
      token: token,
    });
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function finishSignup({ username, token, password }) {
  errors.validateArgs(username, token, password);

  try {
    const res = await fetcher.post("/signup/finish", {
      username: username,
      token: token,
      password: password,
    });
    saveSessionCookie();
    return res.data;
  } catch (err) {
    errors.throwError(err.response);
  }
}

export async function verifyEmail({ token }) {
  errors.validateArgs(token);
  try {
    await fetcher.get(`/email-verification/${token}`);
    return {};
  } catch (err) {
    errors.throwError(err.response);
  }
}
