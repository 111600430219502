import {
  faClipboardCheck,
  faLink,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ImagePlaceholder } from "../components/Image";
import { SVGIcon } from "../components/SVGIcon";
import * as utils from "../api/utils";
import * as constants from "../api/constants";
import useLocationEffect from "../hooks/useLocationEffect";
import "../styles/pages/Product.css";
import { getBusinessHeaderInfo } from "../api/fetchers/files";
import { useAuth } from "../hooks/useAuth";
import { deleteItem } from "../api/fetchers/main";
import { usePopup } from "../hooks/usePopup";
import { useNavigate } from "react-router";

export const ProductPage = ({ data, businessName, onBack, style }) => {
  function handleDeleteProduct() {
    popup.showConfirm(
      "¿Estás seguro de que deseas eliminar este producto?",
      () => {
        deleteMutation.mutate({
          collection_id: data.colUuid,
          item_id: data._id,
        });
      }
    );
  }

  const query = useQuery({
    queryFn: () => getBusinessHeaderInfo({ business_name: businessName }),
    queryKey: `${businessName}-header`,
    staleTime: 600000,
  });

  const auth = useAuth();
  const popup = usePopup();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: deleteItem,
    onSuccess: () => {
      popup.showSuccess("Producto eliminado correctamente. Recarga la página.");
      navigate(-1);
    },
    onError: (err) =>
      popup.showError("Ocurrió un error al eliminar el producto."),
  });

  const [copiedLink, setCopiedLink] = useState(false);

  useEffect(() => {
    setCopiedLink(false);
  }, [data]);

  useLocationEffect((action) => {
    if (typeof onBack === "function" && action === "POP") {
      onBack();
    }
  });

  return (
    <div className="modal-product" style={{ ...style }}>
      {auth.username === businessName && (
        <button
          className="semi_rounded red delete-product-button"
          onClick={handleDeleteProduct}
          disabled={deleteMutation.isLoading}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      )}
      <ImagePlaceholder src={data.image.img} className="product-image" />
      <div className="product-content-container">
        <div className="product-content">
          <div className="product-header">
            <ImagePlaceholder
              className="product-logo"
              src={query.data ? query.data.logo.thumbnail_img : null}
            />
            <p className="bold">{query.data ? query.data.name : null}</p>
            {query.data && query.data.verified ? (
              <SVGIcon
                icon_name="verified_badge"
                style={{ color: "var(--main-color)" }}
              />
            ) : null}
          </div>
          <hr style={{ margin: "20px 0px" }} />
          {data.name ? (
            <p className="s text-light" style={{ marginBottom: "20px" }}>
              Ref. <b className="bold">{data.name}</b>
            </p>
          ) : null}
          {data.price ? (
            <div
              className="semi_rounded"
              style={{ backgroundColor: "var(--color1)", padding: "10px" }}
            >
              <p
                className="m centered"
                style={{ color: "var(--color2)", textAlign: "center" }}
              >
                {utils.formatPrice(data.price)[0]}
                &nbsp;
                <b className="bold">{utils.formatPrice(data.price).slice(1)}</b>
              </p>
            </div>
          ) : null}
        </div>
        <div className="product-contact">
          <hr style={{ margin: "20px 0px" }} />
          <button
            className="semi_rounded transparent font-secondary"
            onClick={() => {
              window.navigator.clipboard.writeText(
                `${window.location.href}/${data._id}`
              );
              setCopiedLink(true);
            }}
          >
            {copiedLink ? (
              <>
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faClipboardCheck}
                />
                COPIADO
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faLink} style={{ marginRight: "6px" }} />
                COPIAR ENLACE
              </>
            )}
          </button>
          <a
            href={
              data
                ? utils.getWhatsAppLink(
                    query.data.wa_number,
                    `${constants.PRODUCT_MESSAGE}${window.location.href}/${data._id} `
                  )
                : null
            }
            target="_blank"
            rel="noreferrer"
          >
            <button className="semi_rounded transparent font-secondary">
              <SVGIcon
                style={{ fontSize: "18px", marginRight: "6px" }}
                icon_name="whatsapp"
              />
              PREGUNTAR
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
