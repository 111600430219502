import React, { useEffect, useState } from "react";
import "../styles/components/BannerGradient.css";

export const BannerGradient = ({ color1, color2, className, style }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (typeof color1 !== "undefined") {
      setOpacity(1);
    }
  }, [color1]);

  return (
    <div
      className={`gradient-banner${className ? " " + className : ""}`}
      style={{
        background: `linear-gradient(32deg, ${color1} 10%, ${color2} 90%)`,
        opacity: opacity,
        ...style,
      }}
    />
  );
};
