import { useParams } from "react-router";
import { Content } from "../../components/Content";
import { useQuery } from "react-query";
import { verifyEmail } from "../../api/fetchers/auth";
import { GlobalLoader } from "../../components/loaders/GlobalLoader";
import { EmailVerified, ExpiredIllustration } from "../../assets/illustrations";
import "../../styles/pages/EmailVerification.css";

export const EmailVerification = () => {
  const { token } = useParams();

  const { isLoading, isSuccess } = useQuery({
    queryFn: () => verifyEmail({ token }),
    enabled: Boolean(token),
  });

  if (isLoading) {
    return (
      <Content key={1}>
        <GlobalLoader />
      </Content>
    );
  }

  if (isSuccess) {
    return (
      <Content key={2}>
        <div className="email-verified-box centered">
          <p className="m black centered linespace-m">
            ¡Tu correo electrónico ha sido verificado!
          </p>
          <p className="sm">Ya puedes cerrar esta pestaña</p>
          <EmailVerified style={{ maxWidth: "90%" }} size={360} />
        </div>
      </Content>
    );
  }

  return (
    <Content key={2}>
      <div className="email-verified-box centered">
        <p className="m black centered linespace-m">
          Parece que este enlace ha expirado...
        </p>
        <ExpiredIllustration style={{ maxWidth: "90%" }} size={360} />
      </div>
    </Content>
  );
};
