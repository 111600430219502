import { getDownloadURL } from "./utils";

export function getBusinessLogo(business_id, token, thumbnail = false) {
  return getDownloadURL(
    `${thumbnail ? "thumbnails/" : ""}businesses/${business_id}/logo`,
    token
  );
}

export function getCollectionCover(business_id, collection_id, token) {
  return getDownloadURL(
    `businesses/${business_id}/${collection_id}/cover`,
    token
  );
}

export function getItemImage(
  business_id,
  collection_id,
  item_id,
  token,
  thumbnail = false
) {
  return getDownloadURL(
    `${
      thumbnail ? "thumbnails/" : ""
    }businesses/${business_id}/${collection_id}/${item_id}`,
    token
  );
}
