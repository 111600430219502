import * as utils from "../api/utils";
import "../styles/components/CodeInput.css";

export const CodeInput = ({ length, state, setter, disabled }) => {
  return (
    <input
      className="code-input m"
      disabled={disabled}
      value={state}
      onChange={(e) => {
        if (utils.isDigit(e.target.value) || e.target.value === "") {
          setter(e.target.value);
        }
      }}
      maxLength={length}
      placeholder={Array(length).fill("•").join("")}
    />
  );
};
