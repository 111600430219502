import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FileInput } from "../../components/FileInput";
import { ImagePlaceholder } from "../../components/Image";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import * as constants from "../../api/constants";
import { usePopup } from "../../hooks/usePopup";
import { getBusinessLogo, uploadLogo } from "../../api/fetchers/files";
import { useAuth } from "../../hooks/useAuth";

export const LogoPage = () => {
  async function onSuccessfulUpload(data) {
    popup.showSuccess("Logo actualizado");
    setFile({});
    queryClient.setQueryData(`${auth.username}-logo`, data);
  }

  function reqUploadLogo() {
    popup.showConfirm(
      "¿Seguro que desea actualizar el logo de su negocio?",
      () => uploadLogoMutation.mutate(file)
    );
  }
  const [file, setFile] = useState({});

  const queryClient = useQueryClient();

  const auth = useAuth();

  const { data, status } = useQuery({
    queryKey: `${auth.username}-logo`,
    queryFn: () =>
      getBusinessLogo({
        business_id: auth.business_id,
      }),
    staleTime: 600000,
    enabled: auth.authState === constants.AUTH_STATE_AUTHENTICATED,
  });

  const uploadLogoMutation = useMutation({
    mutationFn: uploadLogo,
    onSuccess: onSuccessfulUpload,
    onError: () => popup.showError("No se pudo subir la imagen"),
  });

  const popup = usePopup();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Logo</b>
      </p>
      <ImagePlaceholder
        status={status}
        src={data ? data.logo_img : undefined}
        style={{ width: "300px", height: "300px" }}
      />
      <p className="m">
        <b>Actualizar logo</b>
      </p>
      <p className="sm">Sube una imagen para usar como logo de tu negocio.</p>
      <FileInput
        state={file}
        setter={setFile}
        allowExtensions={constants.IMAGE_ALLOWED_EXTENSIONS}
      />
      <button
        className="centered wide semi_rounded"
        onClick={reqUploadLogo}
        disabled={uploadLogoMutation.isLoading || !file.name}
      >
        Actualizar
      </button>
    </AnimatedPanelPage>
  );
};
