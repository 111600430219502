import {
  faBars,
  faBell,
  faEnvelope,
  faFileInvoiceDollar,
  faInbox,
  faRightFromBracket,
  faShop,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
import { Outlet, useNavigate } from "react-router";
import { PanelSidebar } from "../../components/PanelSideBar";
import { SideBar } from "../../components/SideBar";
import { Helmet } from "react-helmet-async";
import { usePopup } from "../../hooks/usePopup";
import { logout } from "../../api/fetchers/auth";
import { useAuth } from "../../hooks/useAuth";
import { Content } from "../../components/Content";

export const AdminPanel = () => {
  function reqLogout() {
    async function requestLogout() {
      await logout();
      popup.showSuccess("Sesión cerrada correctamente.");
      navigate("/");
      auth.clear();
    }

    popup.showConfirm("¿Seguro que quieres cerrar sesión?", requestLogout);
  }

  const [page, setPage] = useState("businesses");

  const sideBarRef = useRef();

  const popup = usePopup();
  const navigate = useNavigate();

  const auth = useAuth();

  return (
    <Content>
      <Helmet title="Panel de administrador" />
      <SideBar
        ref={sideBarRef}
        items={[
          [
            "Solicitudes",
            () => {
              setPage("requests");
              navigate("requests");
            },
          ],
          [
            "Negocios",
            () => {
              setPage("businesses");
              navigate("businesses");
            },
          ],
          ["Facturas"],
          [
            "Enviar notificación",
            () => {
              setPage("send_notif");
              navigate("send-notification");
            },
          ],
          ["Enviar correo electrónico"],
          ["Cerrar sesión", reqLogout],
        ]}
      />
      <p style={{ paddingTop: "30px" }} className="xxl centered linespace-xl">
        <button
          className="semi_rounded mobile"
          onClick={() => {
            sideBarRef.current.show();
          }}
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <b>Panel</b>
      </p>
      <div
        style={{
          display: "flex",
          gap: "30px",
          width: "fit-content",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        className="centered"
      >
        <PanelSidebar
          selected={page}
          items={[
            [
              "requests",
              "Solicitudes",
              () => {
                setPage("requests");
                navigate("requests");
              },
              faInbox,
              true,
            ],
            [
              "businesses",
              "Negocios",
              () => {
                setPage("businesses");
                navigate("businesses");
              },
              faShop,
            ],
            ["bills", "Facturas", undefined, faFileInvoiceDollar, true],
            [
              "send_notif",
              "Enviar notificación",
              () => {
                setPage("send_notif");
                navigate("send-notification");
              },
              faBell,
            ],
            [
              "send_email",
              "Enviar correo electrónico",
              undefined,
              faEnvelope,
              true,
            ],
            ["logout", "Cerrar sesión", reqLogout, faRightFromBracket],
          ]}
        />
        <div style={{ width: "800px" }} className="container form semi_rounded">
          <Outlet />
        </div>
      </div>
    </Content>
  );
};
