import {
  faCalendarDay,
  faFileInvoiceDollar,
  faHandshakeSimpleSlash,
} from "@fortawesome/free-solid-svg-icons";
import { PanelSidebarOption } from "../../components/PanelSideBar";
import { SubscriptionStatus } from "../../components/SubscriptionStatus";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import {
  AUTH_STATE_UNKNOWN,
  SUB_PLAN_STATUS_ACTIVE,
  SUB_PLAN_STATUS_CANCELLED,
} from "../../api/constants";
import { LineLoader } from "../../components/loaders/LineLoader";
import { MercadoPagoSecureCard } from "../../components/MercadoPagoSecureCard";

export const SubscriptionPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <AnimatedPanelPage>
      {auth.authState === AUTH_STATE_UNKNOWN && <LineLoader />}
      <p className="l">
        <b>Suscripción</b>
      </p>
      <SubscriptionStatus
        plan={auth.subscription.plan.id}
        status={auth.subscription.status}
        exp={auth.subscription.plan.exp}
        onButtonClick={
          auth.subscription.plan.id === "free-trial" ||
          auth.subscription.plan.exp !== null ||
          auth.subscription.status === SUB_PLAN_STATUS_CANCELLED
            ? () => navigate("upgrade-to-paid")
            : () => navigate("change-plan")
        }
        buttonText={
          auth.subscription.plan.id === "free-trial"
            ? "Suscribirse"
            : auth.subscription.plan.exp !== null ||
              auth.subscription.status === SUB_PLAN_STATUS_CANCELLED
            ? "Suscribirse de nuevo"
            : "Cambiar"
        }
      />

      {auth.subscription.preapproval?.init_point &&
        auth.subscription.plan.id !== "free-trial" &&
        auth.subscription.status === SUB_PLAN_STATUS_ACTIVE && (
          <>
            <p className="m" style={{ marginTop: "12px" }}>
              <b>Método de Pago</b>
            </p>
            <MercadoPagoSecureCard
              text={
                <b>
                  Tu suscripción es administrada por{" "}
                  <b style={{ color: "#00bcff" }}>MercadoPago</b>. Presiona el
                  botón para cambiar tu método de pago.
                </b>
              }
              buttonText="Cambiar método de pago"
              buttonRef={auth.subscription.preapproval.init_point}
            />
          </>
        )}
      <hr />
      <PanelSidebarOption
        icon={faCalendarDay}
        text="¿Cuándo será mi siguiente cobro?"
        onClick={() => navigate("next-payment")}
        disabled={
          auth.subscription.status !== "active" ||
          auth.subscription.plan.id === "free-trial" ||
          auth.subscription.plan.exp !== null
        }
      />
      <PanelSidebarOption
        icon={faFileInvoiceDollar}
        text="Facturas"
        onClick={() => navigate("bills")}
        disabled={!auth.subscription.preapproval?.id}
      />
      <PanelSidebarOption
        icon={faHandshakeSimpleSlash}
        text="Cancelar suscripción"
        onClick={() => navigate("cancel")}
        disabled={
          auth.subscription.plan.exp !== null ||
          auth.subscription.status !== "active"
        }
      />
    </AnimatedPanelPage>
  );
};
