import "../styles/components/CountedTextArea.css";

export const CountedTextArea = ({
  placeholder,
  state,
  setter,
  maxLength,
  ...extraProps
}) => {
  return (
    <div style={{ position: "relative" }}>
      <textarea
        style={{ position: "relative" }}
        placeholder={placeholder}
        state={state}
        onChange={(e) => setter(e.target.value)}
        maxLength={maxLength}
        {...extraProps}
      />
      <p className="counted_textarea_counter s">
        {state.length}/{maxLength}
      </p>
    </div>
  );
};
