import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/BackButton.css";

export const BackButton = ({
  text,
  icon = faArrowLeft,
  onClick,
  style,
  className,
}) => {
  return (
    <button
      className={`back-button${className ? " " + className : ""}`}
      onClick={onClick}
      style={style}
    >
      <FontAwesomeIcon
        icon={icon}
        style={
          text
            ? {
                marginRight: "10px",
              }
            : undefined
        }
      />
      {text ? text : null}
    </button>
  );
};
