import React from "react";
import "../../styles/components/Loaders.css";

export const Loader = ({ className, style, size }) => {
  const _size = size || 12;

  return (
    <div className={className} style={style}>
      <span className="loader-1" style={{ width: _size, height: _size }} />
    </div>
  );
};
