import axios from "axios";
import * as errors from "../errors";

const API = process.env.REACT_APP_API_URL;

const fetcher = axios.create({
  baseURL: API + "/notifications",
  withCredentials: true,
});

export async function getNotifications() {
  try {
    const res = await fetcher.get("/");
    return res.data;
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function discardNotification(_id) {
  try {
    await fetcher.delete(`/${_id}`);
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}

export async function sendCustomNotification({
  custom_message,
  notifiers,
  link,
}) {
  try {
    await fetcher.post("/custom", {
      custom_message,
      notifiers,
      link,
    });
  } catch (err) {
    throw new errors.throwError(err.response);
  }
}
