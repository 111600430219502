import * as constants from "./constants";

export function isValidBusinessName(businessName) {
  let length = businessName.length;

  if (
    length < constants.BUSINESS_NAME_MIN_LENGTH ||
    length > constants.BUSINESS_NAME_MAX_LENGTH
  ) {
    return false;
  }

  for (let i = 0; i < businessName.length; i++) {
    if (
      !constants.BUSINESS_NAME_ALLOWED_CHARACTERS.includes(
        businessName.charAt(i)
      )
    ) {
      return false;
    }
  }

  return true;
}

export function isValidUsername(username) {
  let length = username.length;

  return (
    length >= constants.USERNAME_MIN_LENGTH &&
    length <= constants.USERNAME_MAX_LENGTH
  );
}

export function isValidPassword(password) {
  let length = password.length;

  return (
    length >= constants.PASSWORD_MIN_LENGTH &&
    length <= constants.PASSWORD_MAX_LENGTH
  );
}

export function isValidCollectionName(collectionName) {
  let length = collectionName.length;

  if (constants.RESERVED_COLLECTION_NAMES.includes(collectionName)) {
    return false;
  }

  for (let i = 0; i < collectionName.length; i++) {
    if (
      !constants.COLLECTION_NAME_ALLOWED_CHARACTERS.includes(
        collectionName.charAt(i)
      )
    ) {
      return false;
    }
  }

  return (
    length >= constants.MIN_COLLECTION_NAME_LENGTH &&
    length <= constants.MAX_COLLECTION_NAME_LENGTH
  );
}

export function onChangeCollectionNameHandler(e, setter) {
  if (
    [...e.target.value].every((char) =>
      constants.COLLECTION_NAME_ALLOWED_CHARACTERS.includes(char)
    )
  ) {
    setter(e.target.value);
  }
}

export function onChangeUsernameHandler(e, setter) {
  const val = e.target.value.trim();
  if (
    [...val].every((char) =>
      constants.USERNAME_ALLOWED_CHARACTERS.includes(char)
    )
  ) {
    setter(val);
  }
}
