import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { cloneElement, useState } from "react";
import "../styles/components/DropdownSelect.css";
import { AnimatePresence, motion } from "framer-motion";
import { FADE_SLIDEUP } from "./animations/framer-animations";

export const DropdownSelectOption = ({
  text,
  value,
  icon,
  onClick = () => {},
}) => {
  return (
    <div className="dropdown-select-option" onClick={() => onClick(value)}>
      <p>
        {icon && (
          <FontAwesomeIcon icon={icon} style={{ marginRight: "12px" }} />
        )}
        {text}
      </p>
    </div>
  );
};

export const DropdownSelect = ({
  children,
  setter,
  text,
  icon,
  searchBar = false,
  defaultText,
  style,
  onClick,
}) => {
  function handleChange(value) {
    setter(value);
    setOpen(false);
  }

  const [open, setOpen] = useState(null);
  const [search, setSearch] = useState("");

  return (
    <div style={{ position: "relative" }}>
      <div
        className="dropdown-select"
        style={style}
        onClick={() => {
          setOpen(!open);
          try {
            onClick();
          } catch {}
        }}
      >
        <p>
          {icon && (
            <FontAwesomeIcon icon={icon} style={{ marginRight: "12px" }} />
          )}
          {text || defaultText}
        </p>
        <FontAwesomeIcon className="dropdown-select-icon" icon={faCaretDown} />
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            className="dropdown-options-container"
            variants={FADE_SLIDEUP}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {searchBar && (
              <input
                className="dropdown-search-bar"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
            {Array.isArray(children)
              ? children.map((item, idx) =>
                  cloneElement(item, { onClick: handleChange, key: idx })
                )
              : typeof children !== "undefined" &&
                cloneElement(children, { onClick: handleChange })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
