import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ProductPage } from "../../pages/Product";
import { AnimatePresence, motion } from "framer-motion";
import { FADE, SLIDEUP } from "../animations/framer-animations";
import "../../styles/components/Modals.css";

export const ProductModal = forwardRef((props, ref) => {
  function handleClose() {
    setHidden(true);
  }

  function show(data, businessName) {
    setInfo({
      data: data,
      businessName: businessName,
    });
    setHidden(false);
  }

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const [info, setInfo] = useState({});

  const [hidden, setHidden] = useState(true);

  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className="modal_container"
          style={{ zIndex: 3 }}
          variants={FADE}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.4 }}
        >
          <motion.div
            variants={SLIDEUP}
            initial="initial"
            exit="exit"
            animate="animate"
            transition={SLIDEUP.transition}
          >
            {Object.entries(info).length > 0 ? (
              <ProductPage
                data={info.data}
                businessName={info.businessName}
                onBack={handleClose}
              />
            ) : null}
          </motion.div>
          {!hidden && (
            <FontAwesomeIcon
              className="button shadowed"
              style={{
                position: "absolute",
                right: "40px",
                top: "40px",
                fontSize: "40px",
                color: "white",
              }}
              icon={faXmark}
              onClick={handleClose}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
});
