function handler(e) {
  e.preventDefault();
  e.returnValue = " ";
}

export default function preventLeaving() {
  window.addEventListener("beforeunload", handler);

  return () => window.removeEventListener("beforeunload", handler); // cleaner
}
