import {
  faClock,
  faDiceD6,
  faInfoCircle,
  faLink,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { PanelSidebarOption } from "../../components/PanelSideBar";
import { LinkCard } from "../../components/LinkCard";
import { useAuth } from "../../hooks/useAuth";

export const GeneralPage = () => {
  function getLink() {
    return window.location.host + "/b/" + auth.username;
  }

  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>General</b>
      </p>
      <LinkCard title="Enlace a tu página" link={getLink()}>
        Este enlace llevará a tus clientes a tu página de negocio con tu
        catálogo.
      </LinkCard>
      <PanelSidebarOption
        icon={faDiceD6}
        text="Logo"
        onClick={() => navigate("logo")}
      />
      <PanelSidebarOption
        icon={faLink}
        text="Tus enlaces"
        onClick={() => navigate("links")}
      />
      <PanelSidebarOption
        icon={faLocationDot}
        text="Ubicación"
        onClick={() => navigate("location")}
      />
      <PanelSidebarOption icon={faClock} text="Horario de atención" disabled />
      <PanelSidebarOption
        icon={faInfoCircle}
        text="Acerca del negocio"
        onClick={() => navigate("about")}
      />
    </AnimatedPanelPage>
  );
};
