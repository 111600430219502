import Landing1 from "../assets/images/Landing-1.jpg";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/pages/Home.css";
import * as constants from "../api/constants";
import { useNavigate } from "react-router";
import { Content } from "../components/Content";
import { PlanCompareTable } from "../components/PlanCompareTable";

const Feature = ({ text }) => {
  return (
    <p className="sm">
      <FontAwesomeIcon
        icon={faCheck}
        style={{ color: "var(--color8)", marginRight: "16px" }}
      />
      {text}
    </p>
  );
};

export const Home = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <div className="landing_banner linespace-xxl">
        <p className="centered sm" style={{ textAlign: "center" }}>
          Crea un catálogo virtual para tu negocio
        </p>
        <p className="centered xxl bold" style={{ textAlign: "center" }}>
          Comienza a exponer tus productos
          <br />
          de forma fácil y rápida
        </p>
        <button
          style={{ marginTop: "70px" }}
          className="centered sm transparent white wide semi_rounded bold"
          onClick={() => navigate(constants.SIGNUP_ROUTE)}
        >
          ¡ÚNETE GRATIS!
        </button>
      </div>
      <p className="l bold centered linespace-xxl">¿Qué es {constants.NAME}?</p>
      <div
        className="centered linespace-xxl"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <img
          src={Landing1}
          alt="Landing-1"
          style={{ maxWidth: "100%", width: "500px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
            margin: "40px",
            marginLeft: "40px",
          }}
        >
          <Feature text="Crea un catálogo de productos online para tu negocio" />
          <Feature text="Comparte un enlace y deja de enviar imágenes" />
          <Feature text="Ideal para pequeños emprendimientos" />
        </div>
      </div>
      <section className="purple">
        <p className="xl black centered linespace-l text-white">Planes</p>
        <p
          className="sm centered text-white linespace-l"
          style={{
            maxWidth: "600px",
            paddingLeft: "10px",
            paddingRight: "10px",
            textAlign: "center",
          }}
        >
          Elige el plan que más se acomode a las necesidades de tu negocio.
        </p>
        <PlanCompareTable />
        <button
          className="sm centered white wide semi_rounded bold"
          style={{ marginTop: "36px" }}
          onClick={() => navigate(constants.SIGNUP_ROUTE)}
        >
          Regístrate ahora
        </button>
      </section>
    </Content>
  );
};
