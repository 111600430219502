import { formatDatetime, formatPrice } from "../api/utils";
import { StatusTag } from "./StatusTag";

const STATUS_FROM_ID = {
  scheduled: "Programado",
  processed: "Procesado",
  recycling: "En proceso",
  cancelled: "Cancelado",
};

const TAG_STATUS_FROM_ID = {
  scheduled: "info",
  processed: "success",
  recycling: "warning",
  cancelled: "error",
};

export const BillRecord = ({ id, status, debit_date, transaction_amount }) => {
  return (
    <tr style={{ height: "55px" }}>
      <td>{id}</td>
      <td>
        <StatusTag status={TAG_STATUS_FROM_ID[status]}>
          {STATUS_FROM_ID[status]}
        </StatusTag>
      </td>
      <td style={{ minWidth: "123px" }}>{formatDatetime(debit_date)}</td>
      <td>{formatPrice(transaction_amount)}</td>
    </tr>
  );
};
