import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { FormErrorMsg } from "../../components/FormMessages";
import { InstagramInput } from "../../components/InstagramInput";
import { PhoneInput } from "../../components/PhoneInput";
import * as constants from "../../api/constants";
import * as errors from "../../api/errors";
import { usePopup } from "../../hooks/usePopup";
import { getBusinessInfo, updateBusiness } from "../../api/fetchers/main";
import { useAuth } from "../../hooks/useAuth";
import { LineLoader } from "../../components/loaders/LineLoader";

export const LinksPage = () => {
  function isValidWANumber() {
    return (
      wa_number.length === constants.PHONE_NUMBER_LENGTH ||
      wa_number.length === 0
    );
  }

  function onWAUpdate() {
    popup.showConfirm(
      "¿Seguro que quiere actualizar el número de WhatsApp de su negocio?",
      () =>
        wa_mutation.mutate({
          wa_number: wa_number ? `57${wa_number}` : "",
        })
    );
  }

  function onIGUpdate() {
    popup.showConfirm(
      "¿Seguro que quiere actualizar la página de Instagram de su negocio?",
      () => ig_mutation.mutate({ ig_user })
    );
  }

  function fetchInfo() {
    return getBusinessInfo({
      business_id: auth.business_id,
    });
  }

  const popup = usePopup();
  const auth = useAuth();
  const queryClient = useQueryClient();

  const { data, isLoading, isSuccess } = useQuery({
    queryFn: fetchInfo,
    queryKey: `basic-business-info-${auth.business_id}`,
    onSuccess: (data) => {
      if (data.wa_number) {
        setWaNumber(data.wa_number.slice(2));
      }
      if (data.ig_user) {
        setIGUser(data.ig_user);
      }
    },
    staleTime: 600000,
    enabled: auth.authState === constants.AUTH_STATE_AUTHENTICATED,
  });

  const [wa_number, setWaNumber] = useState(isSuccess ? data.wa_number.slice(2) : "");
  const [ig_user, setIGUser] = useState(isSuccess ? data.ig_user : "");

  const wa_mutation = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      popup.showSuccess("Número actualizado");
      queryClient.setQueryData(`basic-business-info-${auth.business_id}`, {
        ...data, // Keep the old data
        wa_number: `57${wa_number}`,})
    },
    onError: (e) => {
      if (e instanceof errors.UnauthorizedError) {
        popup.showError(
          "Su sesión ha caducado, por favor inicie sesión de nuevo"
        );
      } else {
        popup.showError("No se pudo actualizar el número, intente más tarde");
      }
    },
  });

  const ig_mutation = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => popup.showSuccess("Página de Instagram actualizada"),
    onError: (e) => {
      if (e instanceof errors.UnauthorizedError) {
        popup.showError(
          "Su sesión ha caducado, por favor inicie sesión de nuevo"
        );
      } else {
        popup.showError("No se pudo actualizar su negocio, intente más tarde");
      }
    },
  });

  return (
    <AnimatedPanelPage>
      {(isLoading || ig_mutation.isLoading || wa_mutation.isLoading) && <LineLoader />}
      <p className="l linespace-l">
        <b>Tus Enlaces</b>
      </p>
      <p className="m">
        <b>WhatsApp</b>
      </p>
      <PhoneInput
        numberSetter={setWaNumber}
        numberState={wa_number}
        codeState={57}
      />
      {!isValidWANumber() && (
        <FormErrorMsg text="Ingresa un número de 10 dígitos" />
      )}
      <button
        className="left wide linespace-l semi_rounded"
        disabled={wa_mutation.isLoading || !isValidWANumber()}
        onClick={onWAUpdate}
      >
        Actualizar
      </button>
      <p className="m">
        <b>Instagram</b>
      </p>
      <InstagramInput state={ig_user} setter={setIGUser} />
      <button
        className="left wide linespace-l semi_rounded"
        disabled={ig_mutation.isLoading}
        onClick={onIGUpdate}
      >
        Actualizar
      </button>
    </AnimatedPanelPage>
  );
};
