import { FormInfoMsg } from "../../components/FormMessages";
import { PaymentForm } from "../../components/PaymentForm";
import { PaymentMethod } from "../../components/PaymentMethod";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";

export const ManagePaymentMethodPage = () => {
  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Método de Pago</b>
      </p>
      <p className="m">
        <b>Actual</b>
      </p>
      <PaymentMethod cardBrand="visa" last4="1234" changeButton={false} />
      <hr />
      <p className="m">
        <b>Cambiar</b>
      </p>
      <PaymentForm />
      <FormInfoMsg text="No se realizará ningún cobro al realizar el cambio." />
    </AnimatedPanelPage>
  );
};
