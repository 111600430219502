import {
  faDesktop,
  faGlobe,
  faMobile,
  faQuestionCircle,
  faRightFromBracket,
  faTablet,
  faTv,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { SLIDE } from "./animations/framer-animations";
import "../styles/components/SessionCard.css";
import { usePopup } from "../hooks/usePopup";
import { closeSession } from "../api/fetchers/auth";
import { useAuth } from "../hooks/useAuth";

export const SessionCard = ({
  _id,
  ip_address,
  device_type,
  device_os,
  device_brand,
  device_model,
  onClose,
  location,
}) => {
  function handleCloseSession() {
    popup.showConfirm(
      "¿Seguro que desea cerrar la sesión del dispositivo?",
      () => mutation.mutate({ session_id: _id })
    );
  }

  const popup = usePopup();
  const auth = useAuth();

  const mutation = useMutation({
    mutationFn: closeSession,
    onSuccess: (data) => {
      popup.showSuccess("Sesión cerrada correctamente.");
      if (typeof onClose === "function") {
        onClose();
      }
      if (data) {
        auth.clear();
      }
    },
    onError: () => popup.showError("No se pudo cerrar la sesión"),
  });

  const device_name =
    device_type !== "desktop" && device_brand
      ? `${device_brand} ${device_model}`
      : device_os;

  const icon =
    device_type === "desktop"
      ? faDesktop
      : device_type === "smartphone"
      ? faMobile
      : device_type === "tablet"
      ? faTablet
      : device_type === "tv"
      ? faTv
      : faQuestionCircle;

  return (
    <motion.div
      variants={{ initial: SLIDE.initial, animate: SLIDE.animate }}
      exit={SLIDE.exit}
      transition={{
        type: "spring",
        ease: "easeInOut",
        duration: 0.2,
      }}
      className="session_card card semi_rounded"
      style={{ originY: 0 }}
    >
      <button className="red" onClick={handleCloseSession}>
        <FontAwesomeIcon icon={faRightFromBracket} />
      </button>
      <FontAwesomeIcon icon={icon} />
      <div>
        <p className="s">
          <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faGlobe} />
          {ip_address ? ip_address : "IP desconocida."}
        </p>
        <p className="sm">
          <b>{device_name ? device_name : "Dispositivo desconocido."}</b>
        </p>
        <p className="s">
          {location ? `Cerca de ${location}` : "Ubicación desconocida"}
          <FontAwesomeIcon
            style={{ marginLeft: "8px", color: "var(--warning-color)" }}
            icon={faWarning}
          />
        </p>
      </div>
    </motion.div>
  );
};
