import { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";

export const LocationTracker = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      ReactGA.initialize("G-C9QV9DGGNX");
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    } catch {
      console.error("Error initializing GoogleAnalytics");
    }
  }, [location.pathname]);

  return <></>;
};
