export class HTTPError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

// HTTP Status 400
export class BadRequestError extends HTTPError {
  constructor(message) {
    super(message, 400);
  }
}

// HTTP 401 Status
export class UnauthorizedError extends HTTPError {
  constructor(message) {
    super(message, 401);
  }
}
// HTTP 403 Status
export class ForbiddenError extends HTTPError {
  constructor(message) {
    super(message, 403);
  }
}

// HTTP 404 Status
export class NotFound extends HTTPError {
  constructor(message) {
    super(message, 404);
  }
}

// HTTP 406 Status
export class NotAcceptable extends HTTPError {
  constructor(message) {
    super(message, 406);
  }
}

// HTTP 409 Status
export class Conflict extends HTTPError {
  constructor(message) {
    super(message, 409);
  }
}

export function throwError(response) {
  if (response.status === 400) {
    throw new BadRequestError(response.data);
  } else if (response.status === 401) {
    throw new UnauthorizedError(response.data);
  } else if (response.status === 403) {
    throw new ForbiddenError(response.data);
  } else if (response.status === 404) {
    throw new NotFound(response.data);
  } else if (response.status === 406) {
    throw new NotAcceptable(response.data);
  } else if (response.status === 409) {
    throw new Conflict(response.data);
  } else {
    throw new Error(response.data);
  }
}

export function validateArgs(...args) {
  args.forEach((arg) => {
    if (arg === undefined) {
      throw new BadRequestError();
    }
  });
  return true;
}
