import { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router";

export default function useLocationEffect(callback) {
  const location = useLocation();

  const action = useNavigationType();

  const initial = useRef(true);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      callback(action);
    }
  }, [location, callback, action]);
}
