import {
  faCircle,
  faCirclePlus,
  faEarth,
  faFileImage,
  faImage,
  faLock,
  faPlus,
  faThumbTack,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { CheckBox } from "../../components/CheckBox";
import {
  DropdownSelect,
  DropdownSelectOption,
} from "../../components/DropdownSelect";
import { EditableText } from "../../components/EditableText";
import { FileInput } from "../../components/FileInput";
import { LineLoader } from "../../components/loaders/LineLoader";
import * as constants from "../../api/constants";
import { usePopup } from "../../hooks/usePopup";
import {
  deleteCollection,
  deleteItem,
  getCollectionItems,
  updateCollection,
  uploadCollectionItem,
} from "../../api/fetchers/main";
import { updateCollectionCover } from "../../api/fetchers/files";
import { useAuth } from "../../hooks/useAuth";
import { FormErrorMsg, FormWarningMsg } from "../../components/FormMessages";
import {
  isValidCollectionName,
  onChangeCollectionNameHandler,
} from "../../api/validators";
import { LinkCard } from "../../components/LinkCard";

function getFakeID() {
  return "*" + Math.random();
}

const SIMULTANEOUS_REQUESTS = parseInt(
  process.env.REACT_APP_SIMULTANEOUS_REQUESTS
);

const Item = forwardRef(
  ({ _id, col_id, name, price, defaultFile, image }, ref) => {
    function hasValidId() {
      return _id[0] !== "*";
    }

    async function upload(onSuccess) {
      if (!(imageFile instanceof File) && !hasValidId()) {
        return;
      }

      await uploadMutation.mutateAsync(
        {
          _id: hasValidId() ? _id : undefined,
          col_id: col_id,
          image: imageFile.name ? imageFile : undefined,
          name: nameInp !== name ? nameInp : undefined,
          price: priceInp !== price ? priceInp : undefined,
          tags: "[]",
        },
        {
          onSuccess: onSuccess,
        }
      );
    }

    async function reqDeleteItem(onSuccess) {
      if (hasValidId()) {
        await deleteMutation.mutateAsync(
          { collection_id: col_id, item_id: _id },
          {
            onSuccess: onSuccess,
          }
        );
      }
    }

    function handleImageInputChange(event) {
      if (event.target.files.length > 0) {
        handleChange();
        setImageFile(event.target.files[0]);
      }
    }

    function handleChange() {
      if (!updated) {
        setUpdated(true);
      }
    }

    function handleGetImage() {
      popup.showImage(image.img);
    }

    useImperativeHandle(ref, () => ({
      upload: upload,
      delete: reqDeleteItem,
      isSelected: selected,
      isUpdated: updated,
    }));

    const [updated, setUpdated] = useState(defaultFile ? true : false);
    const [imageFile, setImageFile] = useState(defaultFile ? defaultFile : {});
    const imageInputRef = useRef();

    const uploadMutation = useMutation({
      mutationFn: uploadCollectionItem,
      onSuccess: () => setUpdated(false),
    });

    const deleteMutation = useMutation({
      mutationFn: deleteItem,
    });

    const [nameInp, setNameInp] = useState(name ? name : "");
    const [priceInp, setPriceInp] = useState(price ? price : "");

    const [selected, setSelected] = useState(false);

    const popup = usePopup();

    return (
      <tr>
        <td>
          <CheckBox state={selected} setter={setSelected} />
        </td>
        <td>
          <FontAwesomeIcon
            className={uploadMutation.isLoading ? "fa-bounce" : ""}
            fontSize={8}
            color={updated ? "#37a1de" : "#37de93"}
            icon={uploadMutation.isLoading ? faUpload : faCircle}
          />
        </td>
        <td>
          <EditableText
            state={nameInp}
            setter={setNameInp}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            ref={imageInputRef}
            className="hidden"
            type="file"
            onChange={handleImageInputChange}
          />
          {imageFile.name ? (
            <FontAwesomeIcon
              className="button"
              icon={faFileImage}
              onClick={() => popup.showImage(imageFile)}
            />
          ) : hasValidId() ? (
            <FontAwesomeIcon
              className="button"
              icon={faImage}
              onClick={handleGetImage}
            />
          ) : (
            <FontAwesomeIcon
              className="button"
              icon={faCirclePlus}
              onClick={() => imageInputRef.current.click()}
            />
          )}
        </td>
        <td>
          <EditableText
            state={priceInp}
            setter={setPriceInp}
            onChange={handleChange}
            inputType="number"
          />
        </td>
        <td>
          <FontAwesomeIcon className="button" icon={faCirclePlus} />
        </td>
      </tr>
    );
  }
);

const ItemList = forwardRef(({ col_id, updateColDataCallback }, ref) => {
  async function uploadUpdatedItems() {
    async function _upload({ i, idxs, uploaded, count, simultaneous = 2 }) {
      if (i >= idxs.length) {
        return;
      }

      const promises = [];

      const incrementer = () => uploaded++;

      for (let j = 0; j < simultaneous; j++) {
        if (i + j >= idxs.length) {
          break;
        }
        promises.push(itemRefs.current[idxs[i + j]].upload(incrementer));
      }

      await Promise.all(promises);

      popup.showProgressBar(
        uploaded / count,
        `${uploaded} de ${count} productos subidos`
      );
      await _upload({
        i: i + simultaneous,
        idxs,
        uploaded,
        count,
        simultaneous,
      });
    }

    let count = 0;
    let idxs = [];
    itemRefs.current.forEach(async (item, idx) => {
      if (item.isUpdated) {
        count++;
        idxs.push(idx);
      }
    });
    if (count === 0) {
      return;
    }
    let uploaded = 0;
    popup.showProgressBar(0, `${uploaded} de ${count} productos subidos`);

    await _upload({
      i: 0,
      idxs,
      uploaded,
      count,
      simultaneous: SIMULTANEOUS_REQUESTS,
    });
  }

  function addEmptyItem() {
    setItems([
      {
        _id: getFakeID(),
      },
      ...items,
    ]);
  }

  function addManyItems(newItems) {
    setItems([...newItems, ...items]);
  }

  async function removeSelectedItems() {
    async function _remove({ i, idxs, removed, count, simultaneous = 2 }) {
      if (i >= idxs.length) {
        return;
      }

      const promises = [];

      const incrementer = () => removed++;

      for (let j = 0; j < simultaneous; j++) {
        if (i + j >= idxs.length) {
          break;
        }
        promises.push(itemRefs.current[idxs[i + j]].delete(incrementer));
      }

      await Promise.all(promises);

      popup.showProgressBar(
        removed / count,
        `${removed} de ${count} productos subidos`
      );
      await _remove({
        i: i + simultaneous,
        idxs,
        removed,
        count,
        simultaneous,
      });
    }

    let count = 0;
    let idxs = [];
    itemRefs.current.forEach(async (item, idx) => {
      if (item.isSelected) {
        count++;
        idxs.push(idx);
      }
    });
    if (count === 0) {
      return;
    }

    var removed = 0;
    popup.showProgressBar(0, `${removed} de ${count} productos eliminados`);

    await _remove({
      i: 0,
      idxs,
      removed,
      count,
      simultaneous: SIMULTANEOUS_REQUESTS,
    });

    const newItems = items.filter((item, idx) => !idxs.includes(idx));

    setItems(newItems);
  }

  useImperativeHandle(ref, () => ({
    uploadSelectedItems: () => {}, //uploadSelectedItems, unimplemented
    uploadUpdatedItems: uploadUpdatedItems,
    removeSelectedItems: removeSelectedItems,
    addEmptyItem: addEmptyItem,
    addManyItems: addManyItems,
  }));

  const ITEMS_PER_QUERY = 5;

  const itemRefs = useRef([]);
  const [items, setItems] = useState([]);

  const [page, setPage] = useState(1);
  const [itemsRemaining, setItemsRemaining] = useState(true);

  const itemsMutation = useMutation({
    mutationFn: getCollectionItems,
    onSuccess: (data) => {
      try {
        updateColDataCallback(data.pop()); // Last JSON object is collection's data
      } catch {}

      if (data.length < ITEMS_PER_QUERY) {
        setItemsRemaining(false);
      }
      setItems([...items, ...data]);
    },
  });

  const auth = useAuth();

  useEffect(
    () => {
      if (auth.business_id) {
        itemsMutation.mutate({
          business_id: auth.business_id,
          collection_id: col_id,
          page: page,
          items: ITEMS_PER_QUERY,
          sendCredentials: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, auth.business_id]
  );

  const popup = usePopup();

  return (
    <div className="table_container">
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Nombre</th>
            <th>Imagen</th>
            <th>Precio</th>
            <th>Etiquetas</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => {
            return (
              <Item
                ref={(el) => (itemRefs.current[idx] = el)}
                key={item._id}
                _id={item._id}
                col_id={col_id}
                name={item.name}
                price={item.price}
                defaultFile={item.defaultFile}
                image={item.image}
              />
            );
          })}
          {!itemsMutation.isLoading && itemsRemaining ? (
            <tr>
              <td
                className="button"
                colSpan={6}
                onClick={() => setPage(page + 1)}
              >
                Mostrar más...
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
});

export const EditCollectionPage = () => {
  function handleUpdateCover() {
    popup.showConfirm("¿Seguro que desea actualizar el cover?", () =>
      updateCoverMutation.mutate({
        col_id: col_id,
        cover,
      })
    );
  }

  function handleImagesInputChange(event) {
    if (event.target.files.length > 0) {
      const array = [];
      for (const file of event.target.files) {
        array.push({
          _id: getFakeID(),
          defaultFile: file,
        });
      }
      itemListRef.current.addManyItems(array);
    }
  }

  function handleVisibilityChange(value) {
    popup.showConfirm(
      <>
        ¿Seguro que quiere cambiar la visibilidad de esta colección a{" "}
        <b>{value === "public" ? "Pública" : "Privada"}</b>?
      </>,
      () =>
        updateCollectionMutation.mutate({
          collection_id: col_id,
          state:
            value === "public"
              ? constants.COLLECTION_STATE_PUBLIC
              : constants.COLLECTION_STATE_PRIVATE,
        })
    );
  }

  function handleNameUpdate() {
    popup.showConfirm(
      <>
        ¿Seguro que quiere cambiar el nombre de esta colección a <b>{name}</b>?
      </>,
      () =>
        updateCollectionMutation.mutate({
          collection_id: col_id,
          name: name,
        })
    );
  }

  function handlePinClick() {
    updateCollectionMutation.mutate(
      {
        collection_id: col_id,
        pinned: !pinned,
      },
      {
        onSuccess: () => setPinned(!pinned),
      }
    );
  }

  function colDataUpdater({ state, name, pinned }) {
    setVisibility(
      state === constants.COLLECTION_STATE_PUBLIC ? "public" : "private"
    );
    setName(name);
    setPinned(pinned);
  }

  const popup = usePopup();
  const auth = useAuth();
  const queryClient = useQueryClient();

  const { col_id } = useParams();

  const updateCoverMutation = useMutation({
    mutationFn: updateCollectionCover,
    onSuccess: () => popup.showSuccess("Cover actualizado correctamente."),
  });

  const updateCollectionMutation = useMutation({
    mutationFn: updateCollection,
    onSuccess: () => {
      popup.showSuccess("Colección actualizada correctamente.");
      queryClient.invalidateQueries({
        queryKey: `${auth.username}-collections-panel`,
      });
    },
  });

  const [fetchSuccess, setFetchSuccess] = useState(0);

  const [cover, setCover] = useState({});

  const itemListRef = useRef();

  const imagesRef = useRef();

  const [visibility, setVisibility] = useState("");
  const [name, setName] = useState("");
  const [pinned, setPinned] = useState();

  useEffect(() => {
    setFetchSuccess(fetchSuccess + 1);
    if (fetchSuccess >= 2) {
      handleVisibilityChange(visibility);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  const navigate = useNavigate();

  const deleteCollectionMutation = useMutation({
    mutationFn: deleteCollection,
    onSuccess: () => {
      popup.showSuccess("La colección ha sido eliminada correctamente.");
      navigate(constants.MY_BUSINESS_ROUTE + "/catalog/collections");
    },
  });

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Detalles de la Colección</b>
      </p>
      {(!visibility ||
        updateCollectionMutation.isLoading ||
        updateCoverMutation.isLoading) && <LineLoader />}
      <LinkCard
        title="Enlace a esta colección"
        link={window.location.host + "/b/" + auth.username + "/" + name}
      >
        Este enlace llevará a tus clientes a esta colección.
      </LinkCard>
      <p className="m">
        <b>Nombre</b>
      </p>
      <input
        placeholder="Nombre de colección"
        value={name}
        onChange={(e) => onChangeCollectionNameHandler(e, setName)}
      />
      <button
        className="semi_rounded left"
        onClick={handleNameUpdate}
        disabled={!(name && isValidCollectionName(name))}
      >
        Actualizar nombre
        <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faUpload} />
      </button>
      {name && !isValidCollectionName(name) && (
        <FormErrorMsg text="Nombre inválido." />
      )}
      <FormWarningMsg text="Al cambiar el nombre, el anterior enlace a esta colección dejará de funcionar." />
      <p className="m">
        <b>Visibilidad</b>
      </p>
      <DropdownSelect
        setter={setVisibility}
        text={
          visibility === "public"
            ? "Pública"
            : visibility === "private"
            ? "Privada"
            : undefined
        }
        icon={
          visibility === "public"
            ? faEarth
            : visibility === "private"
            ? faLock
            : undefined
        }
        defaultText="Cargando..."
      >
        <DropdownSelectOption text="Pública" value="public" icon={faEarth} />
        <DropdownSelectOption text="Privada" value="private" icon={faLock} />
      </DropdownSelect>
      <button
        className="semi_rounded left"
        onClick={handlePinClick}
        disabled={
          typeof pinned === "undefined" || updateCollectionMutation.isLoading
        }
      >
        <FontAwesomeIcon icon={faThumbTack} style={{ marginRight: "10px" }} />
        {pinned
          ? "Desanclar colección del inicio"
          : "Anclar colección al inicio"}
      </button>
      <p className="m">
        <b>Actualizar Cover</b>
      </p>
      <FileInput state={cover} setter={setCover} />
      <button
        className="linespace-l semi_rounded left"
        onClick={handleUpdateCover}
      >
        Actualizar cover
        <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faUpload} />
      </button>
      <p className="m linespace-m">
        <b>Productos</b>
      </p>
      <div className="card_display" style={{ gap: "10px" }}>
        <input
          ref={imagesRef}
          className="hidden"
          type="file"
          multiple
          onChange={handleImagesInputChange}
        />
        <button
          className="semi_rounded transparent"
          onClick={() => imagesRef.current.click()}
        >
          <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faPlus} />{" "}
          Agregar
        </button>
        <button
          className="semi_rounded red"
          onClick={async () => {
            popup.showConfirm(
              <>
                ¿Seguro que quieres eliminar&nbsp;<b>permanentemente</b> los
                productos seleccionados?
              </>,
              () => {
                itemListRef.current.removeSelectedItems();
              }
            );
          }}
        >
          <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faTrash} />{" "}
          Eliminar seleccionados
        </button>
        <button
          className="semi_rounded"
          onClick={() => itemListRef.current.uploadUpdatedItems()}
        >
          <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faUpload} />{" "}
          Subir todo
        </button>
      </div>
      <ItemList
        ref={itemListRef}
        col_id={col_id}
        updateColDataCallback={colDataUpdater}
      />
      <p className="m linespace-m">
        <b>Eliminar Colección</b>
      </p>
      <p className="s linespace-m">
        Eliminar esta colección eliminará todos los productos que contiene.
      </p>
      <FormWarningMsg
        text={
          <>
            Esta acción es <b>completamente irreversible</b>, asegúrate que esta
            es la colección que deseas eliminar.
          </>
        }
      />
      <button
        className="semi_rounded red"
        disabled
        onClick={() =>
          popup.showConfirm(
            <>
              ¿Seguro que quieres eliminar&nbsp;<b>permanentemente</b> esta
              colección?
            </>,
            () => {
              deleteCollectionMutation.mutate({ collection_id: col_id });
            }
          )
        }
      >
        <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faTrash} />{" "}
        Eliminar esta colección
      </button>
    </AnimatedPanelPage>
  );
};
