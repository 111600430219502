/** Taken from: https://iconscout.com/illustration/empty-box-4085812
 */
export const EmptyBoxIllustration = ({ width = 480, height = 460 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 650 512"
      width={width}
      height={height}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    >
      <circle cx="337.969" cy="256" r="167.695" fill="#dbe8ec"></circle>
      <path
        fill="#dbe8ec"
        d="M564.583 220.903v-18.11a13.02 13.02 0 00-13.02-13.02h-45.96a13.02 13.02 0 01-13.02-13.021v-18.11a13.02 13.02 0 0113.02-13.02h1.451a13.02 13.02 0 0013.021-13.021v-18.11a13.02 13.02 0 00-13.02-13.021h-333.92a13.02 13.02 0 00-13.02 13.02v18.11a13.02 13.02 0 0013.02 13.021 13.02 13.02 0 0113.021 13.021v18.11a13.02 13.02 0 01-13.02 13.02h-50.354a13.02 13.02 0 00-13.02 13.022v18.11a13.02 13.02 0 0013.02 13.02h19.758a13.02 13.02 0 0113.021 13.021v18.11a13.02 13.02 0 01-13.02 13.02h-.049a13.02 13.02 0 00-13.02 13.022v18.11a13.02 13.02 0 0013.02 13.02h2.859a13.02 13.02 0 0113.02 13.021v18.11a13.02 13.02 0 01-13.02 13.02H98.437a13.02 13.02 0 00-13.02 13.022v18.11a13.02 13.02 0 0013.02 13.02h415.608a13.02 13.02 0 0013.02-13.02V379.4a13.02 13.02 0 00-13.02-13.021h-11.29a13.02 13.02 0 01-13.02-13.021v-18.11a13.02 13.02 0 0113.02-13.02h26.62a13.02 13.02 0 0013.021-13.022v-18.11a13.02 13.02 0 00-13.02-13.02H521.3a13.02 13.02 0 01-13.02-13.021v-18.11a13.02 13.02 0 0113.02-13.02h30.263a13.02 13.02 0 0013.02-13.022z"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M384.775 92.159L417.077 92.159"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M384.775 101.47L417.077 101.47"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M384.775 110.781L417.077 110.781"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.153 289.908L151.456 289.908"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.153 299.218L151.456 299.218"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.153 308.529L151.456 308.529"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M505.663 375.236L537.966 375.236"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M505.663 384.546L537.966 384.546"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M505.663 393.857L537.966 393.857"
      ></path>
      <circle cx="546.78" cy="202.347" r="13.807" fill="#b9d4db"></circle>
      <circle cx="510.344" cy="212.312" r="7.159" fill="#b9d4db"></circle>
      <circle cx="528.523" cy="168.562" r="10.228" fill="#b9d4db"></circle>
      <circle cx="164.38" cy="389.402" r="16.428" fill="#b9d4db"></circle>
      <circle cx="131.897" cy="397.598" r="8.557" fill="#b9d4db"></circle>
      <circle cx="121.847" cy="369.192" r="4.936" fill="#b9d4db"></circle>
      <circle cx="146.321" cy="356.549" r="11.833" fill="#b9d4db"></circle>
      <circle cx="250.43" cy="135.463" r="16.428" fill="#b9d4db"></circle>
      <circle cx="251.096" cy="101.968" r="8.557" fill="#b9d4db"></circle>
      <circle cx="308.091" cy="106.968" r="7.406" fill="#b9d4db"></circle>
      <circle cx="281.147" cy="99.771" r="4.936" fill="#b9d4db"></circle>
      <circle cx="286.883" cy="126.713" r="11.833" fill="#b9d4db"></circle>
      <path
        fill="#e06714"
        d="M483.963 291.561L374.753 350.388 374.753 216.949 483.963 182.855 483.963 291.561z"
      ></path>
      <path
        fill="#f9ae2b"
        d="M374.753 216.95l-128.92-13.043-26.422-38.98a3 3 0 012.483-4.684h121.445a3 3 0 012.66 1.612z"
      ></path>
      <path
        fill="#f9ae2b"
        d="M245.832 337.346l-45.024-9.368a3 3 0 01-2.389-2.937V197.73a3 3 0 013.611-2.938l43.802 9.114zm172.386 27.513l-43.465-14.47v-133.44l45.36 15.102a3 3 0 012.053 2.846v127.115a3 3 0 01-3.948 2.847z"
      ></path>
      <path
        fill="#409cb5"
        d="M374.753 350.388L245.832 337.346 245.832 203.907 374.753 216.949 374.753 350.388z"
      ></path>
      <path
        fill="#f9ae2b"
        d="M341.97 386.091l-131.544-5.248 35.406-43.497 128.921 13.042-30.408 34.683a3 3 0 01-2.375 1.02z"
      ></path>
      <path
        fill="#ee781d"
        d="M374.753 216.949L483.963 182.855 350.473 170.429 374.753 216.949z"
      ></path>
      <path
        fill="#25788e"
        d="M245.832 337.346L374.753 277.148 374.753 216.949 245.832 203.907 245.832 337.346z"
      ></path>
      <path
        fill="#dbe8ec"
        d="M253.562 293.863s14.085-6.162 17.826-1.76 12.104 6.381 12.104 6.381l7.263-12.324 19.806 9.243 10.784 14.745-7.042 17.386s-14.305-.22-21.127 2.64-24.649 3.082-24.649 3.082l-8.142-15.625s-10.344-15.185-6.823-23.768z"
      ></path>
      <path
        fill="#bad5db"
        d="M283.492 298.484a94.822 94.822 0 013.931 3.712c1.286 1.257 2.524 2.555 3.766 3.849 1.216 1.316 2.465 2.604 3.644 3.951 1.19 1.338 2.4 2.658 3.55 4.03l-2.536 1.254a266.56 266.56 0 01-1.65-7.194c-.52-2.406-.965-4.828-1.43-7.245-.848-4.854-1.638-9.72-2.012-14.68 1.796 4.638 3.176 9.371 4.5 14.117.619 2.383 1.26 4.76 1.826 7.155a266.657 266.657 0 011.605 7.205l.004.021a1.455 1.455 0 01-2.54 1.234c-1.146-1.375-2.23-2.8-3.337-4.208-1.117-1.399-2.164-2.856-3.244-4.286-1.053-1.452-2.11-2.9-3.12-4.389a94.805 94.805 0 01-2.957-4.526z"
      ></path>
      <path
        fill="#bad5db"
        d="M310.561 295.403c-.84 1.812-1.785 3.552-2.727 5.295-.967 1.725-1.94 3.447-2.967 5.132-.997 1.705-2.07 3.358-3.115 5.03-1.055 1.666-2.169 3.291-3.25 4.94l-1.528-2.305c2.004-.412 4.016-.786 6.031-1.143 2.024-.316 4.035-.692 6.069-.959 2.026-.3 4.064-.546 6.105-.776 2.048-.193 4.093-.402 6.166-.469-1.94.736-3.908 1.329-5.873 1.937a174.09 174.09 0 01-5.934 1.63c-1.98.535-3.982.96-5.971 1.446-1.998.445-4 .873-6.008 1.263l-.031.006a1.484 1.484 0 01-1.497-2.31c1.135-1.612 2.237-3.246 3.398-4.84 1.17-1.587 2.312-3.193 3.531-4.747 1.19-1.574 2.432-3.112 3.681-4.646 1.274-1.517 2.545-3.036 3.92-4.484z"
      ></path>
      <path
        fill="#bad5db"
        d="M314.303 327.534c-1.625-.77-3.162-1.659-4.708-2.536a126.449 126.449 0 01-4.519-2.792c-2.98-1.904-5.865-3.938-8.697-6.044l2.166-.428c-1.712 2.79-3.486 5.542-5.39 8.216-.93 1.35-1.92 2.664-2.913 3.976-1.016 1.297-2.033 2.595-3.154 3.829.576-1.565 1.255-3.067 1.935-4.569.703-1.487 1.41-2.972 2.176-4.422 1.49-2.924 3.108-5.77 4.789-8.58a1.488 1.488 0 012.04-.512l.113.075.013.01c2.847 2.085 5.64 4.243 8.338 6.53a126.506 126.506 0 014 3.496c1.293 1.219 2.596 2.425 3.81 3.751z"
      ></path>
      <path
        fill="#bad5db"
        d="M253.562 293.863c7.603 2.855 15.02 6.097 22.408 9.396 3.678 1.684 7.364 3.352 11.008 5.106 3.663 1.714 7.292 3.499 10.92 5.287l.01.006a1.451 1.451 0 01.138 2.526c-2.395 1.524-4.84 2.97-7.261 4.454-2.417 1.49-4.886 2.9-7.326 4.353-2.464 1.417-4.917 2.85-7.41 4.22q-3.722 2.084-7.522 4.045 3.382-2.618 6.848-5.104c2.297-1.679 4.633-3.295 6.96-4.928 2.35-1.595 4.67-3.236 7.044-4.794 2.369-1.566 4.713-3.17 7.108-4.695l.148 2.532c-3.656-1.728-7.311-3.46-10.932-5.262-3.64-1.761-7.238-3.61-10.845-5.442-7.179-3.733-14.33-7.523-21.296-11.7z"
      ></path>
      <path
        fill="#bad5db"
        d="M259.485 316.225a43.3 43.3 0 007.385-3.625 14.867 14.867 0 005.273-5.289 12.243 12.243 0 001.143-7.223 42.463 42.463 0 00-1.898-7.986 18.612 18.612 0 014.214 7.54 12.25 12.25 0 01-.814 9.082 13.855 13.855 0 01-6.821 5.955 18.881 18.881 0 01-8.482 1.546zm22.493-8.645a33.235 33.235 0 01-1.054 6.067 56.083 56.083 0 01-1.85 5.764 55.108 55.108 0 01-2.451 5.535 32.835 32.835 0 01-3.255 5.228 32.829 32.829 0 011.05-6.069 55.094 55.094 0 011.854-5.762 56.065 56.065 0 012.455-5.533 33.233 33.233 0 013.251-5.23z"
      ></path>
      <path
        fill="#c0d8dd"
        d="M252.831 366.415s-10.73 11.01-15.852 8.34-13.616-1.356-13.616-1.356l-2.09 14.152-21.828-1.108-15.541-9.602-.02-18.757s13.335-5.181 18.579-10.4 21.676-12.132 21.676-12.132l13.425 11.41s15.299 10.176 15.267 19.453z"
      ></path>
      <path
        fill="#9fc6cc"
        d="M223.363 373.4c-3.326-1.37-6.576-2.88-9.812-4.418-1.608-.788-3.229-1.55-4.822-2.367-1.597-.808-3.203-1.6-4.784-2.438l1.251-1.41c2.863 4.003 5.627 8.074 8.339 12.183 2.678 4.131 5.325 8.284 7.739 12.6-3.212-3.76-6.19-7.683-9.135-11.629-2.913-3.967-5.773-7.973-8.535-12.046l-.007-.01a.97.97 0 011.259-1.4c1.58.84 3.134 1.728 4.697 2.6 1.567.864 3.106 1.781 4.659 2.673 3.085 1.822 6.156 3.67 9.15 5.661z"
      ></path>
      <path
        fill="#9fc6cc"
        d="M199.445 386.443c.428-3.939 1.045-7.837 1.726-11.722.67-3.887 1.45-7.751 2.255-11.609l1.523 1.04-5.147 3.346c-1.74 1.078-3.457 2.191-5.215 3.242a260.92 260.92 0 01-5.284 3.138c-1.786 1.009-3.566 2.026-5.4 2.964 1.584-1.317 3.22-2.553 4.85-3.797a260.788 260.788 0 014.964-3.623c1.658-1.203 3.357-2.343 5.033-3.518l5.102-3.415a.993.993 0 011.522 1.04c-.846 3.85-1.718 7.694-2.7 11.514-.971 3.822-2.006 7.631-3.23 11.4z"
      ></path>
      <path
        fill="#9fc6cc"
        d="M183.884 358.084c1.767.213 3.509.522 5.253.821 1.74.322 3.475.658 5.202 1.027 3.457.725 6.889 1.548 10.307 2.426l-1.227.805c.282-1.612.53-3.23.839-4.838.305-1.608.597-3.219.943-4.82.666-3.21 1.393-6.408 2.307-9.576-.121 3.295-.43 6.56-.8 9.816-.173 1.63-.399 3.252-.612 4.875-.21 1.624-.478 3.238-.715 4.858a.981.981 0 01-1.112.829l-.099-.02-.016-.004c-3.42-.868-6.827-1.79-10.209-2.81a188.744 188.744 0 01-5.056-1.593c-1.675-.573-3.352-1.137-5.005-1.796z"
      ></path>
      <path
        fill="#9fc6cc"
        d="M252.831 366.415c-4.051-.012-8.097-.126-12.142-.228-4.045-.119-8.087-.291-12.128-.468q-12.12-.583-24.222-1.433h-.008a.968.968 0 01-.72-1.526c1.646-2.314 3.342-4.592 5.013-6.887 1.666-2.299 3.386-4.56 5.076-6.841 1.718-2.263 3.413-4.541 5.169-6.776 1.751-2.239 3.474-4.498 5.27-6.704-1.494 2.421-3.061 4.79-4.6 7.18-1.534 2.393-3.128 4.743-4.7 7.109-1.6 2.346-3.17 4.713-4.793 7.043-1.619 2.332-3.212 4.683-4.857 6.997l-.727-1.526q12.114.684 24.208 1.662c4.031.338 8.061.681 12.088 1.078 4.026.414 8.053.816 12.073 1.32z"
      ></path>
      <path
        fill="#9fc6cc"
        d="M238.926 347.927a36.164 36.164 0 00-5.781 5.881 14.522 14.522 0 00-3.087 7.09 10.01 10.01 0 00.308 3.78 13.716 13.716 0 001.514 3.61 35.608 35.608 0 005.099 6.468 20.255 20.255 0 01-6.407-5.617 12.22 12.22 0 01-2.502-8.45 13.964 13.964 0 013.885-7.881 20.515 20.515 0 016.971-4.881zm-17.584 16.476a46.845 46.845 0 01-.91-6.027 82.542 82.542 0 01-.266-12.086 46.33 46.33 0 01.648-6.06 46.326 46.326 0 01.912 6.026 82.547 82.547 0 01.26 12.087 46.846 46.846 0 01-.644 6.06z"
      ></path>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/page-not-found-4085802
 */
export const NotFoundIllustration = ({
  width = 720,
  height = 640,
  className,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 650 512"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <path
        fill="#dbe8ec"
        d="M596.677 246.038v-18.19a13.08 13.08 0 00-13.08-13.08h-36.166a13.08 13.08 0 01-13.079-13.08v-18.19c0-7.224 15.856-13.08 23.08-13.08h1.457a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08H116.982a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08 13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08h-50.58a13.08 13.08 0 00-13.079 13.08v18.19a13.08 13.08 0 0013.08 13.08h19.846a13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08h-.048a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08h2.872a13.08 13.08 0 0113.079 13.08v18.19a13.08 13.08 0 01-13.08 13.08H71.949a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08h483.963a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08h-11.34a13.08 13.08 0 01-13.08-13.08v-18.19a13.08 13.08 0 0113.08-13.08h26.74a13.08 13.08 0 0013.079-13.08v-18.19a13.08 13.08 0 00-13.08-13.08H563.2a13.08 13.08 0 01-13.08-13.08v-18.19a13.08 13.08 0 0113.08-13.08h20.398a13.08 13.08 0 0013.08-13.08z"
      ></path>
      <path
        fill="#f9ae2b"
        d="M221.782 214.165a25.03 25.03 0 01-25.008 25.02h-93.45a19.282 19.282 0 01-4.1-38.125 22.246 22.246 0 0122.169-20.78 21.592 21.592 0 016.725 1.074 35.102 35.102 0 0165.342 8.05 24.978 24.978 0 0128.323 24.76zm0 0"
      ></path>
      <path
        fill="#409cb5"
        d="M384.28 361.08l-18.578-27.62a79.998 79.998 0 10-23.114 14.348l18.11 26.804a13.594 13.594 0 0023.582-13.532z"
      ></path>
      <circle cx="312.733" cy="273.513" r="57.048" fill="#ee781d"></circle>
      <path
        fill="#e06714"
        d="M312.733 216.465a57.462 57.462 0 00-8.139.588 57.043 57.043 0 010 112.92 57.046 57.046 0 108.139-113.508z"
      ></path>
      <circle cx="282.177" cy="274.625" r="8.829" fill="#f9ae2b"></circle>
      <circle cx="341.279" cy="262.647" r="6.112" fill="#e06714"></circle>
      <circle cx="307.149" cy="250.639" r="4.075" fill="#e06714"></circle>
      <circle cx="308.658" cy="304.906" r="4.075" fill="#e06714"></circle>
      <circle cx="332.207" cy="289.982" r="5.513" fill="#f9ae2b"></circle>
      <circle cx="270.216" cy="326.179" r="3.132" fill="#3086a3"></circle>
      <circle cx="372.913" cy="239.928" r="3.132" fill="#3086a3"></circle>
      <path
        fill="#3086a3"
        d="M371.01 307.043a62.008 62.008 0 01-6.951 11.388 67.64 67.64 0 01-9.426 9.625 70.097 70.097 0 01-11.328 7.438 64.123 64.123 0 01-26.11 7c-2.27.02-4.534.104-6.794-.034a61.376 61.376 0 01-6.737-.752 59.123 59.123 0 01-24.675-10.04 78.472 78.472 0 0025.087 6.946 71.87 71.87 0 0025.512-2.09 75.04 75.04 0 0012.046-4.417 81.43 81.43 0 0011.106-6.518 83.736 83.736 0 009.85-8.445 94.246 94.246 0 008.42-10.101zM246.835 286.85a62.375 62.375 0 01.722-35.164 65.547 65.547 0 017.638-16.267 64.862 64.862 0 015.437-7.204 67.573 67.573 0 016.324-6.448 72.553 72.553 0 017.149-5.522c1.28-.795 2.513-1.664 3.838-2.383l1.963-1.118 2.02-1.013c1.336-.691 2.745-1.235 4.117-1.847 1.414-.516 2.796-1.112 4.239-1.543a62.08 62.08 0 018.719-2.226 65.923 65.923 0 0117.852-.694 61.347 61.347 0 0117.143 4.308 75.384 75.384 0 00-17.262-2.348 72.22 72.22 0 00-17.103 1.791 68.887 68.887 0 00-16.002 5.942 71.922 71.922 0 00-7.328 4.364 70.115 70.115 0 00-6.693 5.28 69.086 69.086 0 00-19.106 27.927 72.035 72.035 0 00-3.667 34.165z"
      ></path>
      <path
        fill="#409cb5"
        d="M203.269 332.512v23.165c0 20.119 1.22 21.643 22.861 23.776v8.536h-71.327v-8.536c22.86-2.133 24.385-3.962 24.385-24.081v-22.86H98.716v-9.145c28.958-41.15 60.354-85.654 90.836-128.024h13.717V317.88h29.872v14.633zm-24.08-105.162c-19.204 28.042-40.542 59.133-61.574 90.53h61.573zm325.237 105.162v23.165c0 20.119 1.22 21.643 22.862 23.776v8.536H455.96v-8.536c22.861-2.133 24.385-3.962 24.385-24.081v-22.86h-80.47v-9.145c28.957-41.15 60.353-85.654 90.835-128.024h13.716V317.88H534.3v14.633zm-24.08-105.162c-19.204 28.042-40.541 59.133-61.573 90.53h61.572z"
      ></path>
      <path
        fill="#f9ae2b"
        d="M552.439 143.699a32.97 32.97 0 01-32.94 32.954H396.41a25.398 25.398 0 01-5.4-50.215 29.302 29.302 0 0129.2-27.37 28.44 28.44 0 018.857 1.415 46.234 46.234 0 0186.066 10.602 32.9 32.9 0 0137.305 32.614zm-.173 257.676a25.03 25.03 0 01-25.009 25.02h-93.45a19.282 19.282 0 01-4.099-38.125 22.246 22.246 0 0122.168-20.78 21.592 21.592 0 016.725 1.074 35.102 35.102 0 0165.342 8.05 24.978 24.978 0 0128.323 24.76zm0 0"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.225 119.327L151.528 119.327"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.225 128.637L151.528 128.637"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M119.225 137.948L151.528 137.948"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M273.595 395.257L305.898 395.257"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M273.595 404.568L305.898 404.568"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M273.595 413.878L305.898 413.878"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M536.115 271.982L568.417 271.982"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M536.115 281.293L568.417 281.293"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M536.115 290.603L568.417 290.603"
      ></path>
      <circle cx="80.914" cy="399.628" r="16.428" fill="#b9d4db"></circle>
      <circle cx="101.589" cy="373.267" r="8.557" fill="#b9d4db"></circle>
      <circle cx="144.12" cy="411.536" r="7.406" fill="#b9d4db"></circle>
      <circle cx="126.92" cy="389.583" r="4.936" fill="#b9d4db"></circle>
      <circle cx="115.301" cy="414.559" r="11.833" fill="#b9d4db"></circle>
      <circle cx="308.282" cy="162.289" r="16.428" fill="#b9d4db"></circle>
      <circle cx="275.694" cy="178.717" r="7.406" fill="#b9d4db"></circle>
      <circle cx="266.615" cy="150.456" r="11.833" fill="#b9d4db"></circle>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/no-messages-4085820
 */
export const OpenEnvelope = ({ width = 260, height = 205 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 650 512"
      width={width}
      height={height}
    >
      <defs>
        <clipPath id="a">
          <path
            fill="none"
            d="M339.944 265.7H465.808V388.609H339.944z"
            transform="rotate(-74.43 402.876 327.154)"
          ></path>
        </clipPath>
      </defs>
      <circle cx="328.792" cy="256" r="173.618" fill="#dbe8ec"></circle>
      <path
        fill="#dbe8ec"
        d="M583.904 219.7v-18.192a13.08 13.08 0 00-13.08-13.08H524.66a13.08 13.08 0 01-13.08-13.079v-18.19a13.08 13.08 0 0113.08-13.08h1.457a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08H154.21a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08 13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08h-50.58a13.08 13.08 0 00-13.079 13.08v18.19a13.08 13.08 0 0013.08 13.08h19.846a13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08h-.048a13.08 13.08 0 00-13.079 13.08v18.19a13.08 13.08 0 0013.08 13.08h2.87a13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08H79.176a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08h453.963a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08h-11.34a13.08 13.08 0 01-13.08-13.08v-18.19a13.08 13.08 0 0113.08-13.08h26.74a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08h-8.112a13.08 13.08 0 01-13.08-13.08v-18.19a13.08 13.08 0 0113.08-13.08h30.399a13.08 13.08 0 0013.08-13.08z"
      ></path>
      <circle cx="169.838" cy="267.885" r="13.807" fill="#b9d4db"></circle>
      <circle cx="182.315" cy="295.366" r="7.159" fill="#b9d4db"></circle>
      <circle cx="162.895" cy="316.811" r="7.159" fill="#b9d4db"></circle>
      <circle cx="147.832" cy="289.924" r="10.228" fill="#b9d4db"></circle>
      <circle cx="431.47" cy="171.627" r="13.807" fill="#b9d4db"></circle>
      <circle cx="401.32" cy="173.011" r="7.159" fill="#b9d4db"></circle>
      <circle cx="388.617" cy="147.018" r="7.159" fill="#b9d4db"></circle>
      <circle cx="419.175" cy="143.012" r="10.228" fill="#b9d4db"></circle>
      <path
        fill="#e06714"
        d="M413.07 306.394H209.696c-4.926 0-7.065-87.542-3.178-90.567l101.688-73.39a5.174 5.174 0 016.356 0l101.688 73.39c3.887 3.025 1.748 90.567-3.178 90.567z"
      ></path>
      <path
        fill="#ee781d"
        d="M204.51 365.469V221.227a5.174 5.174 0 019.258-3.178l88.173 72.121a5.174 5.174 0 010 6.356l-88.173 72.121a5.174 5.174 0 01-9.258-3.178zm213.745 0V221.227a5.174 5.174 0 00-9.258-3.178l-88.173 72.121a5.174 5.174 0 000 6.356l88.173 72.121a5.174 5.174 0 009.258-3.178z"
      ></path>
      <path
        fill="#f98232"
        d="M413.07 370.653H209.696a5.174 5.174 0 01-3.178-9.257l101.688-73.39a5.174 5.174 0 016.356 0l101.688 73.39a5.174 5.174 0 01-3.178 9.257z"
      ></path>
      <circle cx="204.51" cy="201.764" r="35.702" fill="#f9ae2b"></circle>
      <path fill="#409cb5" d="M202.651 178.363H206.37V211.387H202.651z"></path>
      <path
        fill="#409cb5"
        d="M204.51 225.166c4.487 0 4.495-6.973 0-6.973-4.486 0-4.494 6.973 0 6.973z"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M482.112 208.448L514.414 208.448"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M482.112 217.759L514.414 217.759"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M482.112 227.069L514.414 227.069"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M147.388 368.22L179.691 368.22"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M147.388 377.53L179.691 377.53"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M147.388 386.841L179.691 386.841"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M237.62 128.212L269.923 128.212"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M237.62 137.523L269.923 137.523"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M237.62 146.833L269.923 146.833"
      ></path>
      <circle cx="523.862" cy="342.229" r="13.807" fill="#b9d4db"></circle>
      <circle cx="487.426" cy="352.194" r="7.159" fill="#b9d4db"></circle>
      <circle cx="505.605" cy="308.444" r="10.228" fill="#b9d4db"></circle>
      <path
        fill="#3086a3"
        d="M475.96 285.464l-27.699 22.697-54.737 44.85-15.218 31.06a1.844 1.844 0 01-2.066.988l-.088-.025a1.844 1.844 0 01-1.35-1.76l.958-29.436.342-10.541 97.761-60.859a1.847 1.847 0 012.098 3.026zm0 0"
      ></path>
      <path
        fill="#409cb5"
        d="M476.577 284.503l-.008.028a1.842 1.842 0 01-.854 1.102l-19.713 11.412-79.903 46.262-33.643-22.908a1.85 1.85 0 01.566-3.315l131.251-34.823.054-.016.1-.023.108-.02.098-.01.122-.007h.102l.014.004.1.007.11.013c.07.012.14.027.21.045a1.879 1.879 0 01.266.098 1.194 1.194 0 01.117.058 1.093 1.093 0 01.121.07l.122.085a.447.447 0 01.07.059.069.069 0 01.028.022l.07.062.016.005.06.06a1.792 1.792 0 01.139.172.748.748 0 01.058.085 1.765 1.765 0 01.15.298 1.43 1.43 0 01.052.147c.015.043.025.087.036.131.01.044.024.114.034.172a1.498 1.498 0 01.013.153 1.411 1.411 0 01.002.187 1.024 1.024 0 00-.01.115 2.01 2.01 0 01-.058.27zm0 0"
      ></path>
      <g clipPath="url(#a)">
        <path
          fill="#409cb5"
          d="M449.1 389.879a1.844 1.844 0 01-2.284 1.311 1.886 1.886 0 01-.542-.25l-57.201-38.925 66.929-54.97 17.617-14.437a1.864 1.864 0 01.68-.356c.038-.01.077-.02.117-.027l.107-.017.099-.012.121-.007h.102l.015.005.1.007.11.013c.07.012.14.027.208.045a1.892 1.892 0 01.268.098 1.195 1.195 0 01.116.058 1.052 1.052 0 01.122.07l.122.085a.55.55 0 01.07.057.08.08 0 01.027.024l.07.062.016.005.06.06a1.788 1.788 0 01.14.172.722.722 0 01.058.085 1.75 1.75 0 01.15.298c.019.048.037.097.052.147a2.545 2.545 0 01.07.303 1.45 1.45 0 01.013.153 1.422 1.422 0 01.002.186.935.935 0 00-.01.115 1.951 1.951 0 01-.048.269l-.007.025zm0 0"
        ></path>
        <path
          fill="#4469a0"
          d="M406.094 363.597l-28.34 21.142a1.847 1.847 0 01-1.6.299 1.8 1.8 0 01-.682-.359 1.84 1.84 0 01-.48-2.233l14.084-30.43zm0 0"
        ></path>
      </g>
      <path
        fill="#4469a0"
        d="M389.073 352.015l17.021 11.582-4.125 3.078-14.449-11.307zm0 0"
      ></path>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/access-denied-4085803
 */
export const AccessDenied = ({ width = 720, height = 640, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 650 512"
      width={width}
      height={height}
      style={style}
    >
      <circle cx="239.068" cy="149.642" r="82.339" fill="#dbe8ec"></circle>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M185.297 122.789L217.6 122.789"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M185.297 132.1L217.6 132.1"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M185.297 141.41L217.6 141.41"
      ></path>
      <path
        fill="#dbe8ec"
        d="M608.904 269.613v-18.191a13.08 13.08 0 00-13.08-13.08H549.66a13.08 13.08 0 01-13.08-13.08v-18.19a13.08 13.08 0 0113.08-13.08h1.457a13.08 13.08 0 0013.08-13.08v-18.19a13.08 13.08 0 00-13.08-13.08H129.21a13.08 13.08 0 00-13.08 13.08v18.19a13.08 13.08 0 0013.08 13.08 13.08 13.08 0 0113.08 13.08v18.19a13.08 13.08 0 01-13.08 13.08H78.63a13.08 13.08 0 00-13.079 13.08v18.19a13.08 13.08 0 0013.08 13.08h19.846a13.08 13.08 0 0113.08 13.08v18.191a13.08 13.08 0 01-13.08 13.08h-.048a13.08 13.08 0 00-13.079 13.079v18.191a13.08 13.08 0 0013.08 13.08h2.87a13.08 13.08 0 0113.08 13.079v18.191a13.08 13.08 0 01-13.08 13.08H54.176a13.08 13.08 0 00-13.08 13.079v18.191a13.08 13.08 0 0013.08 13.08h503.963a13.08 13.08 0 0013.08-13.08v-18.191a13.08 13.08 0 00-13.08-13.08h-11.34a13.08 13.08 0 01-13.08-13.079v-18.191a13.08 13.08 0 0113.08-13.08h26.74a13.08 13.08 0 0013.08-13.079v-18.191a13.08 13.08 0 00-13.08-13.08h-8.112a13.08 13.08 0 01-13.08-13.079v-18.191a13.08 13.08 0 0113.08-13.08h30.399a13.08 13.08 0 0013.08-13.08z"
      ></path>
      <rect
        width="269.073"
        height="394.56"
        x="208.484"
        y="51.907"
        fill="#3086a3"
        rx="9"
      ></rect>
      <rect
        width="236.57"
        height="355.949"
        x="224.735"
        y="71.213"
        fill="#dbe8ec"
        rx="9"
      ></rect>
      <circle cx="343.021" cy="168.219" r="44.343" fill="#f9ae2b"></circle>
      <path
        fill="#409cb5"
        d="M335.57 164.352v-9.22a6.999 6.999 0 017.064-6.999h.769a6.996 6.996 0 017.064 6.998v3.106a.796.796 0 00.786.79l3.72.017a.791.791 0 00.795-.79v-2.609a12.175 12.175 0 00-12.192-12.178h-1.115a12.177 12.177 0 00-12.187 12.178v8.953zm0 0"
      ></path>
      <path
        fill="#3086a3"
        d="M357.528 190.865a3.522 3.522 0 003.513-3.514v-19.984a3.522 3.522 0 00-3.513-3.513h-29.014a3.522 3.522 0 00-3.514 3.513v19.984a3.522 3.522 0 003.514 3.514zm0 0"
      ></path>
      <path
        fill="#409cb5"
        d="M343.02 170.708a3.777 3.777 0 00-3.764 3.766 3.705 3.705 0 001.53 2.888v4.419a2.234 2.234 0 004.469 0v-4.419a3.718 3.718 0 001.53-2.888 3.777 3.777 0 00-3.764-3.766zM224.737 377.521v42.1a7.541 7.541 0 007.54 7.54h221.488a7.541 7.541 0 007.542-7.54v-42.1zm236.57-298.767a7.541 7.541 0 00-7.542-7.54H232.277a7.541 7.541 0 00-7.541 7.54v15.083h236.57z"
      ></path>
      <circle cx="343.021" cy="71.213" r="12.669" fill="#f9ae2b"></circle>
      <circle cx="343.021" cy="402.341" r="12.669" fill="#f9ae2b"></circle>
      <rect
        width="73"
        height="25.481"
        x="250.112"
        y="228.072"
        fill="#f9ae2b"
        rx="3"
      ></rect>
      <rect
        width="88.686"
        height="117.1"
        x="347.847"
        y="228.072"
        fill="#ee781d"
        rx="3"
      ></rect>
      <rect
        width="73"
        height="25.481"
        x="250.112"
        y="273.881"
        fill="#f9ae2b"
        rx="3"
      ></rect>
      <rect
        width="73"
        height="25.481"
        x="250.112"
        y="319.691"
        fill="#f9ae2b"
        rx="3"
      ></rect>
      <path
        fill="#f9ae2b"
        d="M392.19 311.563h-25.555a2.81 2.81 0 01-2.434-4.215l12.778-22.131 12.777-22.132a2.81 2.81 0 014.868 0l12.777 22.132 12.778 22.13a2.81 2.81 0 01-2.434 4.216z"
      ></path>
      <rect
        width="2.812"
        height="23.77"
        x="390.784"
        y="273.378"
        fill="#409cb5"
        rx="1.406"
      ></rect>
      <circle
        cx="392.19"
        cy="303.385"
        r="3.363"
        fill="#409cb5"
        transform="rotate(-84.345 392.19 303.385)"
      ></circle>
      <path
        fill="#ee781d"
        d="M174.052 378.793v-23.535a17.865 17.865 0 0118.032-17.862h1.963a17.858 17.858 0 0118.031 17.862v7.928a2.032 2.032 0 002.006 2.017l9.496.043a2.02 2.02 0 002.027-2.017v-6.659a31.076 31.076 0 00-31.12-31.084h-2.844a31.08 31.08 0 00-31.108 31.084v22.852zm0 0"
      ></path>
      <path
        fill="#f9ae2b"
        d="M230.1 446.467a8.991 8.991 0 008.968-8.968V386.49a8.991 8.991 0 00-8.969-8.969h-74.057a8.991 8.991 0 00-8.968 8.97v51.008a8.991 8.991 0 008.968 8.968zm0 0"
      ></path>
      <path
        fill="#ee781d"
        d="M193.07 395.018a9.642 9.642 0 00-9.608 9.611 9.456 9.456 0 003.907 7.372v11.278a5.702 5.702 0 0011.404 0v-11.278a9.49 9.49 0 003.906-7.372 9.642 9.642 0 00-9.608-9.611zm0 0"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M62.051 428.996L94.354 428.996"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M62.051 438.306L94.354 438.306"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M62.051 447.617L94.354 447.617"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M492.833 284.036L525.136 284.036"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M492.833 293.346L525.136 293.346"
      ></path>
      <path
        fill="none"
        stroke="#b9d4db"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M492.833 302.657L525.136 302.657"
      ></path>
      <circle cx="136.379" cy="303.918" r="16.428" fill="#b9d4db"></circle>
      <circle cx="137.046" cy="270.423" r="8.557" fill="#b9d4db"></circle>
      <circle cx="194.04" cy="275.423" r="7.406" fill="#b9d4db"></circle>
      <circle cx="167.096" cy="268.226" r="4.936" fill="#b9d4db"></circle>
      <circle cx="172.833" cy="295.168" r="11.833" fill="#b9d4db"></circle>
      <circle cx="531.923" cy="425.872" r="16.428" fill="#b9d4db"></circle>
      <circle cx="497.151" cy="433.968" r="8.557" fill="#b9d4db"></circle>
      <circle cx="497.151" cy="399.329" r="11.833" fill="#b9d4db"></circle>
      <circle cx="533.263" cy="134.526" r="16.428" fill="#b9d4db"></circle>
      <circle cx="500.676" cy="150.954" r="7.406" fill="#b9d4db"></circle>
      <circle cx="491.597" cy="122.693" r="11.833" fill="#b9d4db"></circle>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/bad-gateway-2100981
 */

export const BadGateway = ({ width = 720, height = 640, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 463.38 424.63"
      style={style}
      width={width}
      height={height}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect
            width="97.06"
            height="98.1"
            x="287.79"
            y="291.11"
            fill="#c1e3f7"
            rx="13"
            transform="rotate(43.96 336.334 340.208)"
          ></rect>
          <rect
            width="114.32"
            height="114.32"
            x="279.15"
            y="283"
            fill="none"
            stroke="#008ef8"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="13"
            transform="rotate(43.96 336.334 340.208)"
          ></rect>
          <path
            fill="#ffcd52"
            d="M319.79 423.47h-18.21V245.26h18.73l-.52 178.21z"
          ></path>
          <ellipse
            cx="296.11"
            cy="389.9"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <ellipse
            cx="296.11"
            cy="389.9"
            fill="none"
            stroke="#ff5061"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="#ff5061"
            d="M313 404.89c2.8-6.46.69-10.42.69-15 0-9.63-3.22-20.54-9-27.38a52.49 52.49 0 00-2.46 15.13c.03 9.64 5.02 20.41 10.77 27.25z"
          ></path>
          <ellipse
            cx="326.39"
            cy="377.72"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="#ff5061"
            d="M278.07 391c0 3.81-1.54 6.85.57 12 9-7.21 15.83-19.32 15.83-31.78a55.47 55.47 0 00-1.9-13c-9.05 7.1-14.5 20.28-14.5 32.78z"
          ></path>
          <ellipse
            cx="271.28"
            cy="369.22"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#ff5061"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M296.11 423.99v-51.13m0 20.03l-7.97-9.12m7.97 20l-11.48-13.13m11.48 24.34l-11.86-13.56m11.86-8.51l9.67-11.05m-9.67 21.93l11.86-13.56M296.11 415l11.86-13.56"
          ></path>
          <ellipse
            cx="271.28"
            cy="369.22"
            fill="none"
            stroke="#008ef8"
            strokeMiterlimit="10"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#008ef8"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M271.28 352.18v71.95m0-51.92l-9.65-11.03m9.65 21.91l-11.86-13.57m11.86 24.78l-11.86-13.56m11.86-8.51l9.66-11.05m-9.66 21.93l11.86-13.56m-11.86 24.77l11.86-13.56"
          ></path>
          <ellipse
            cx="326.39"
            cy="377.72"
            fill="none"
            stroke="#439916"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#439916"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M326.39 360.68v63.45m0-43.42l-9.65-11.03m9.65 21.91l-11.86-13.56m11.86 24.77l-11.86-13.56m11.86-8.51l9.67-11.05m-9.67 21.93l11.86-13.56m-11.86 24.77l11.86-13.56"
          ></path>
          <path
            fill="#f46534"
            d="M213.14 0a78.7 78.7 0 011.2 7.89c.31 2.63.49 5.27.65 7.92s.23 5.29.21 7.94a79 79 0 01-.35 8 78.74 78.74 0 01-1.21-7.89c-.3-2.63-.48-5.27-.64-7.92s-.23-5.29-.21-7.94.08-5.33.35-8z"
          ></path>
          <path
            fill="#f46534"
            d="M229.85 15c-2.62.55-5.25.91-7.88 1.2s-5.28.49-7.92.65-5.29.22-7.94.21a78.84 78.84 0 01-8-.36c2.62-.55 5.25-.91 7.88-1.2s5.28-.49 7.92-.65 5.29-.22 7.94-.2a76 76 0 018 .35zm4.22 4.44a38 38 0 011.07 5.3c.24 1.77.41 3.55.51 5.33s.11 3.56.06 5.35a36.47 36.47 0 01-.49 5.38 36.56 36.56 0 01-1.06-5.3c-.25-1.77-.42-3.55-.51-5.33s-.12-3.56-.07-5.35a36.35 36.35 0 01.49-5.38z"
          ></path>
          <path
            fill="#f46534"
            d="M245.33 29.54a38 38 0 01-5.3 1.07c-1.77.24-3.55.41-5.33.51s-3.56.11-5.35.07a36.37 36.37 0 01-5.38-.5 36.29 36.29 0 015.3-1.06 63.386 63.386 0 0110.67-.57 38 38 0 015.39.48zM15 221c.55 2.63.91 5.26 1.2 7.89s.49 5.28.65 7.92.22 5.29.21 7.94a78.84 78.84 0 01-.36 8c-.55-2.62-.91-5.25-1.2-7.88s-.49-5.28-.65-7.92-.22-5.29-.2-7.94A76.2 76.2 0 0115 221z"
          ></path>
          <path
            fill="#f46534"
            d="M31.72 236a78.7 78.7 0 01-7.89 1.2c-2.63.3-5.27.49-7.92.65s-5.29.23-7.94.21a79 79 0 01-8-.35 78.74 78.74 0 017.89-1.21c2.63-.3 5.27-.48 7.92-.64s5.29-.23 7.94-.21 5.33.09 8 .35zm4.22 4.44a36.56 36.56 0 011.06 5.3c.24 1.77.42 3.55.51 5.33s.12 3.56.07 5.35a36.35 36.35 0 01-.49 5.38 36.33 36.33 0 01-1.09-5.3c-.24-1.77-.42-3.54-.51-5.32s-.11-3.57-.06-5.35a36.61 36.61 0 01.51-5.39z"
          ></path>
          <path
            fill="#f46534"
            d="M47.19 250.55a36.29 36.29 0 01-5.3 1.06c-1.77.24-3.54.42-5.32.51s-3.57.12-5.36.07a36.6 36.6 0 01-5.38-.49 36.6 36.6 0 015.3-1.07c1.77-.24 3.55-.41 5.33-.51s3.56-.11 5.35-.06a36.35 36.35 0 015.38.49zm384.01-55.01c.55 2.62.91 5.25 1.2 7.89s.49 5.27.65 7.91.23 5.29.21 7.95a79 79 0 01-.35 8 79.18 79.18 0 01-1.21-7.89c-.3-2.64-.49-5.28-.64-7.92s-.23-5.29-.21-7.94a78.83 78.83 0 01.35-8z"
          ></path>
          <path
            fill="#f46534"
            d="M447.91 210.54a78.74 78.74 0 01-7.89 1.21c-2.63.3-5.27.48-7.91.65s-5.29.22-7.94.2a76.2 76.2 0 01-8-.35 76.33 76.33 0 017.89-1.2c2.64-.31 5.28-.49 7.92-.65s5.29-.22 7.94-.21 5.33.08 7.99.35zm4.22 4.46a36.29 36.29 0 011.06 5.3c.25 1.77.42 3.54.51 5.32s.12 3.57.07 5.35a36.61 36.61 0 01-.49 5.39 38 38 0 01-1.07-5.3c-.24-1.77-.41-3.55-.5-5.33s-.12-3.56-.07-5.35a36.35 36.35 0 01.49-5.38z"
          ></path>
          <path
            fill="#f46534"
            d="M463.38 225.08a37.57 37.57 0 01-5.29 1.07c-1.78.24-3.55.41-5.33.51s-3.56.11-5.35.06a36.35 36.35 0 01-5.38-.49 36.16 36.16 0 015.29-1.06c1.78-.25 3.55-.42 5.33-.51s3.57-.12 5.35-.07a36.35 36.35 0 015.38.49z"
          ></path>
          <path fill="#c1e3f7" d="M57.51 65.01h204.48v358.46H57.51z"></path>
          <path fill="#008ef8" d="M82.57 91.65h153.84v174.58H82.57z"></path>
          <path
            fill="#0234c1"
            d="M246.92 81.65H72.57L57.51 65.01h204.48l-15.07 16.64z"
          ></path>
          <path fill="#008ef8" d="M82.57 276.95h153.84v119.88H82.57z"></path>
          <path
            fill="#0234c1"
            d="M72.57 406.83h174.35l15.07 16.64H57.51l15.06-16.64z"
          ></path>
          <path
            fill="#0234c1"
            d="M72.57 408.65L57 423.95V64.49l15.57 17.16v327zm173.84 0l15.58 15.3V64.49l-15.58 17.16v327z"
          ></path>
          <circle cx="242.06" cy="272.39" r="14.83" fill="#ffcd52"></circle>
          <path fill="#ffcd52" d="M121.15 325.55h76.68v17.28h-76.68z"></path>
          <circle cx="159.49" cy="155.83" r="6.7" fill="#ffcd52"></circle>
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M95.45 149.13l34.56-41.28M95.45 334.19l34.56-41.28m55.18 85.26l34.56-41.28m-34.56-79.91l34.56-41.28M98.33 180.97l64.8-73.12m-10.34 144.01l64.8-73.12M89.33 313.55l18.72-22.09m96.97 88.16l18.72-22.09"
          ></path>
          <ellipse
            cx="56.78"
            cy="389.19"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <ellipse
            cx="56.78"
            cy="389.19"
            fill="none"
            stroke="#ff5061"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="#ff5061"
            d="M73.67 404.18c2.8-6.46.68-10.42.68-15 0-9.63-3.22-20.54-9-27.38a52.88 52.88 0 00-2.45 15.13c.04 9.64 5.02 20.41 10.77 27.25z"
          ></path>
          <ellipse
            cx="87.07"
            cy="377.01"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="#ff5061"
            d="M38.75 390.25c0 3.81-1.55 6.85.56 12 9-7.21 15.83-19.32 15.83-31.78a55.47 55.47 0 00-1.9-13c-9.04 7.14-14.49 20.32-14.49 32.78z"
          ></path>
          <ellipse
            cx="31.95"
            cy="368.51"
            fill="#fff"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#ff5061"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M56.78 423.29v-51.14m0 20.03l-7.96-9.11m7.96 19.99L45.3 389.93m11.48 24.34l-11.86-13.56m11.86-8.51l9.67-11.05m-9.67 21.93l11.87-13.56m-11.87 24.77l11.87-13.56"
          ></path>
          <ellipse
            cx="31.95"
            cy="368.51"
            fill="none"
            stroke="#439916"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#439916"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M31.95 351.47v71.95m0-51.92l-9.65-11.03m9.65 21.91l-11.86-13.57m11.86 24.78l-11.86-13.56m11.86-8.51l9.67-11.05m-9.67 21.93l11.86-13.56m-11.86 24.77l11.86-13.56"
          ></path>
          <ellipse
            cx="87.07"
            cy="377.01"
            fill="none"
            stroke="#ffcd52"
            strokeLinecap="round"
            strokeLinejoin="round"
            rx="19.68"
            ry="34.1"
          ></ellipse>
          <path
            fill="none"
            stroke="#ffcd52"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M87.06 359.97v63.45m.01-43.42l-9.65-11.03m9.65 21.91l-11.86-13.56m11.86 24.77l-11.86-13.56m11.86-8.51l9.66-11.05m-9.66 21.93l11.86-13.56m-11.86 24.78l11.86-13.57"
          ></path>
          <path
            fill="none"
            stroke="#008ef8"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M393.59 87l-88.87-19.13a7.46 7.46 0 01-5.89-7.29V19.49a7.46 7.46 0 017.46-7.49h88.87a7.46 7.46 0 017.45 7.46v60.28a7.44 7.44 0 01-9.02 7.26zm-86.44-18.6l3.49 9.75 15.71-5.62-19.2-4.13z"
          ></path>
          <path
            fill="#008ef8"
            d="M325.7 72.76l.65-.23-19.2-4.13 1.24 3.45 17.31.91zm25.07-11.79h-2.85l-3.79-36.88 9.56-2.05-2.92 38.93z"
          ></path>
          <circle cx="349.19" cy="66.16" r="2.24" fill="#008ef8"></circle>
          <path
            fill="none"
            stroke="#545454"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.11 423.42h389.82"
          ></path>
          <path
            fill="#ff5061"
            d="M348.57 115.34h-75.78l-37.89 65.63 37.89 65.62h75.78l37.89-65.62-37.89-65.63z"
          ></path>
          <path
            fill="none"
            stroke="#ffcd52"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
            d="M282 142.58l57.36 72.72m0-72.72L282 215.3"
          ></path>
        </g>
      </g>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/new-messages-2080979
 */
export const EmailVerified = ({ size = 720, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      viewBox="0 0 823.67 597"
      width={size}
      height={size}
      style={style}
    >
      <defs>
        <linearGradient
          id="a"
          x1="184.47"
          x2="188.3"
          y1="186.62"
          y2="176.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.36" stopColor="#00272e"></stop>
          <stop offset="0.75" stopColor="#3f80a1" stopOpacity="0.25"></stop>
          <stop offset="1" stopColor="#69b9eb" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="d"
          x1="343.3"
          x2="455.64"
          y1="346.98"
          y2="41.5"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="b"
          x1="393.2"
          x2="383.08"
          y1="139.61"
          y2="633.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#68e1fd"></stop>
          <stop offset="0.01" stopColor="#67ddf9"></stop>
          <stop offset="0.12" stopColor="#5cb3ce"></stop>
          <stop offset="0.24" stopColor="#538ea9"></stop>
          <stop offset="0.37" stopColor="#4c718b"></stop>
          <stop offset="0.5" stopColor="#465a73"></stop>
          <stop offset="0.63" stopColor="#424a63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3f3d56"></stop>
        </linearGradient>
        <linearGradient
          id="e"
          x1="393.88"
          x2="383.76"
          y1="139.62"
          y2="633.98"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="f"
          x1="397.21"
          x2="387.09"
          y1="139.69"
          y2="634.05"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="c"
          x1="683.35"
          x2="704.34"
          y1="150.83"
          y2="150.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ecc4d7"></stop>
          <stop offset="0.42" stopColor="#efd4d1"></stop>
          <stop offset="1" stopColor="#f2eac9"></stop>
        </linearGradient>
        <linearGradient
          id="g"
          x1="694.47"
          x2="690.14"
          y1="139.15"
          y2="187.7"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="h"
          x1="664.6"
          x2="709.11"
          y1="121.91"
          y2="121.91"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="i"
          x1="764.44"
          x2="824"
          y1="85.12"
          y2="85.12"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="j"
          x1="810.3"
          x2="804.28"
          y1="78.07"
          y2="31.33"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="k"
          x1="30.23"
          x2="89.78"
          y1="-160.53"
          y2="-160.53"
          gradientTransform="scale(-1 1) rotate(-32 72.11 1144.583)"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="l"
          x1="76.08"
          x2="70.06"
          y1="-167.26"
          y2="-214"
          gradientTransform="scale(-1 1) rotate(-32 72.11 1144.583)"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="m"
          x1="533.17"
          x2="540.88"
          y1="470.11"
          y2="502.23"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="n"
          x1="723.21"
          x2="736.06"
          y1="491.76"
          y2="509.74"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="o"
          x1="770.23"
          x2="694.43"
          y1="222.33"
          y2="178.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
          <stop offset="0.98" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <path
        fill="#68e1fd"
        d="M131.3 125c-24.34 5.16-49.64 10.76-69.18 26.17-16.5 13-42.21 37-50 56.5-33 82.37 4.31 182.6 76.73 233.86 34.06 24.11 76.51 35.78 107.12 64.16 19.36 17.95 32.92 41.71 53.52 58.21 30.1 24.09 70.74 29.42 109.2 32 26.72 1.81 54.48 2.59 79.27-7.57 17.92-7.34 33.1-19.93 48-32.34l98.71-82.43c15.86-13.23 30.69-34.3 51.34-34.16 185.86 1.33 198.39-217.4-86.86-314.28-25.14-8.54-42.38-32.28-66-44.45-48.79-25.15-104.94-17-154.15.4-62.48 22.15-132.86 30.14-197.7 43.93z"
        opacity="0.18"
      ></path>
      <path
        fill="#000405"
        d="M779.07 477.86c7.78.13 17.8 2.07 19 9.75.65 4-1.64 7.89-4.12 11.12-15.51 20.27-41.21 30.52-66.57 33.27s-50.92-1.1-76.24-4.22q-54.88-6.78-110.2-9c-26.22-1.06-53.8-1.72-76.38-15.08-4.19-2.47-8.31-5.56-10.32-10-4-8.83 2.32-19.58 11-23.87s18.88-3.87 28.56-3.35c95.06 5.12 190.05 11.38 285.27 11.38z"
        opacity="0.05"
      ></path>
      <path
        fill="#68e1fd"
        d="M779.4 126.4s-33.83 43.75-40.25 51.08c-11.71 13.32-19.26 30.79-19.26 30.79l-17 72.34-19-5.07-41.31-11S657.26 225 650.15 213c-9.48-16-1.28-26 .88-32.87 4-12.73-16.43-36-16.43-36L621.51 85.5l19.76-2.64 14.34 47.53 24.57 21.93 17.5.93s13.2 2.72 22.8-1.72c9.18-4.26 41.61-40.83 41.61-40.83l13.23 12zm-162.07 74.19v260.56A24.86 24.86 0 01592.46 486H202.77a24.86 24.86 0 01-24.86-24.87V200.59a24.85 24.85 0 0124.86-24.87h389.69a24.87 24.87 0 0124.87 24.87z"
      ></path>
      <path
        fill="#68e1fd"
        d="M610.59 183.67H183.98l3.46-2.65a6.12 6.12 0 01.5-.39L390.88 25.07l6.41-4.91 206.47 158.28z"
      ></path>
      <path
        fill="#68e1fd"
        d="M617.33 200.59l-5.19 3.75-37.79 27.37L390.88 364.6 214.43 228.71l-36.52-28.12.47-4.83a24.69 24.69 0 016.18-12.09 23.32 23.32 0 012.88-2.65 6.12 6.12 0 01.5-.39 24.74 24.74 0 0114.83-4.91h389.69a24.88 24.88 0 0124.75 22.47 23.81 23.81 0 01.12 2.4z"
      ></path>
      <path
        fill="url(#a)"
        d="M187.77 181a25 25 0 00-2.88 2.65h-.58z"
        opacity="0.61"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#d)"
        d="M617.66 200.59l-5.19 3.76-37.79 27.36L391.21 364.6 214.76 228.72l-36.52-28.13.47-4.82a24.73 24.73 0 016.18-12.1 25 25 0 012.88-2.65l.5-.38L391.21 25.07l6.41-4.91 206.47 158.28a24.83 24.83 0 0113.45 19.76 23.61 23.61 0 01.12 2.39z"
        opacity="0.61"
        transform="translate(-.33)"
      ></path>
      <path
        fill="#fff"
        d="M574.35 181.34v50.38l-67.71 49L491.11 292l-100.23 72.6-94.27-72.6-14.59-11.24-67.59-52v-47.42a14.77 14.77 0 0114.77-14.77h330.39a14.77 14.77 0 0114.76 14.77z"
      ></path>
      <path
        fill="url(#b)"
        d="M525.06 225.51h-267a5.62 5.62 0 01-5.62-5.62 5.62 5.62 0 015.62-5.62h267a5.62 5.62 0 015.62 5.62 5.62 5.62 0 01-5.62 5.62z"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#e)"
        d="M525.06 258.76h-267a5.62 5.62 0 01-5.62-5.62 5.62 5.62 0 015.62-5.62h267a5.62 5.62 0 015.62 5.62 5.62 5.62 0 01-5.62 5.62z"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#f)"
        style={{ isolation: "isolate" }}
        d="M506.64 280.76L491.11 292h-194.5l-14.6-11.24h224.63z"
      ></path>
      <path
        fill="#000405"
        d="M390.88 364.6L228.02 486.02H557.1L390.88 364.6z"
        opacity="0.05"
      ></path>
      <path
        fill="#68e1fd"
        d="M250.4 90.2c-8.2-17.59-39-14.7-39-14.7 19-11.21 15.36-39.68 15.13-41.36 8.31 24.82 37 19.34 39.57 18.8-19.05 4.7-15.7 37.26-15.7 37.26zM197 126.29c-5.21-11.18-24.8-9.34-24.8-9.34 12.4-7.37 9.59-26.34 9.59-26.34v.11c5.32 15.69 23.52 12.21 25.14 11.87-12.06 3-9.93 23.7-9.93 23.7z"
      ></path>
      <path
        fill="#3f3d56"
        d="M721.56 92.7a12.36 12.36 0 018.56-2.7 9 9 0 016.2 2.14 9.88 9.88 0 012.42 5.33 30.59 30.59 0 01.55 7.46c-.22 4.59-1.41 9.45.57 13.6a9.73 9.73 0 001.88 2.66 5.23 5.23 0 003.48 1.73 2.79 2.79 0 002.86-2.24 10.62 10.62 0 01-16.36 3.91l1.74 3.53a20.27 20.27 0 01-4.16-1.58c-4.25-2.31-6.63-7-7.76-11.74-1-4.35-1.2-8.84-2.14-13.21-.73-3.35-.55-6.47 2.16-8.89z"
      ></path>
      <path
        fill="#68e1fd"
        d="M720.28 97.5a7 7 0 012.54.39 4 4 0 012.45 3.35 3.78 3.78 0 01-2.08 3.57 6.34 6.34 0 01-2.7.47c-.84 0-1.69 0-2.53-.08a1.47 1.47 0 01-.69-.17 1.52 1.52 0 01-.47-.67 15.11 15.11 0 01-1.23-3.43c-.69-3.68 1.79-3.46 4.71-3.43z"
      ></path>
      <path
        fill="url(#c)"
        d="M704.34 132.61s-.16 2.89-.36 6.65c-.17 3.17-.38 7-.55 10.18a21.39 21.39 0 01-6.47 14.2c-2.26 2.31-7.65 6.62-11.76 5.09-2-.74-2.07-3.26-1.65-4.77a29.62 29.62 0 001.53-8.53 29.93 29.93 0 00-.63-7c-2.58-6.43 19.89-15.82 19.89-15.82z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#g)"
        d="M704.34 132.61s-.16 2.89-.36 6.65c-1.58 3.52-3.06 6.39-3.87 7.82-1.63 2.89-8.33 7.44-15 8.35a29.93 29.93 0 00-.63-7c-2.61-6.43 19.86-15.82 19.86-15.82z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#h)"
        d="M684.77 94.32a21.49 21.49 0 00-20 24.27 72.68 72.68 0 006.62 25.22c7 12 24.74 2.13 27.55-2.81s13.59-27.22 9.06-33.53c-3.12-4.26-11.77-13.61-23.23-13.15z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="#3f3d56"
        d="M678.14 82.13a37.22 37.22 0 0122.94.67c7.27 2.76 13.54 8.44 16.31 15.71a28.57 28.57 0 01-.64 20.7 40.44 40.44 0 01-13 16.43c-1.47-4.5 3.06-8.5 4.82-12.89a3.63 3.63 0 00.25-2.62c-.64-1.63-3.08-1.81-4.47-.75s-2 2.85-2.51 4.52c1.35-7.15 2.35-15.55-2.71-20.79-3-3.1-7.53-4.26-11.85-4.3a29 29 0 00-21.19 9.11c-1.59 1.71-3.85 3.81-5.87 2.63a4 4 0 01-1.42-1.73c-5.97-11.82 9.71-24.05 19.34-26.69z"
      ></path>
      <path
        fill="#3f3d56"
        d="M687.8 95.34c-5-2.2-10.25-4-15.68-4s-11.06 1.84-14.65 5.92a20.57 20.57 0 00-4 7.77 14 14 0 00-.7 5.54 23.88 23.88 0 001 4c.94 3.24 1.34 7-.71 9.72a4.38 4.38 0 01-3.34 1.86 2.72 2.72 0 01-2.74-2.33c-.88 2.49.56 5.41 2.85 6.72a9.36 9.36 0 007.67.37 8.31 8.31 0 002.83-1.55 9.74 9.74 0 002-2.75c2.09-3.79 3.14-8 4.7-12.09s3.82-8 7.51-10.3a19.4 19.4 0 019.54-2.37c3.31-.11 6.64.16 9.94-.14s.91-2.26-.51-3.29a31.53 31.53 0 00-5.71-3.08z"
      ></path>
      <path
        fill="url(#i)"
        d="M822.44 59.49c.85.66-7.11 6.86-7.11 6.86s-3.72 6.41-7.5 8.19-32.18 48.15-32.18 48.15l-11.21-10.16L788.62 80s8-15.33 7.11-22c0 0 .48-7.88 2.89-4.22 1.7 2.61.61 6.72 2.62 7.16a1.09 1.09 0 00.64-.14 22.63 22.63 0 004.55-3.64c4-3.81 8.78-9.11 8.78-9.11s2.62-1.11 2.29 0-5.14 8.6-5.41 9c.45-.41 7.83-7 9-6.49s-5.72 8.1-6.12 8.54c.43-.3 8.3-5.78 8.89-5.29 1.11.93-6 7.1-6.59 7.57.45-.24 4.36-2.53 5.17-1.89z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#j)"
        d="M814 64.24a4 4 0 01-1.85.15 51 51 0 00-6.06-.1c-1.36.06-4.17 1-4.24-1.11 0-.56.14-1.11.11-1.67 0-.24-.06-.48-.1-.72a22.63 22.63 0 004.55-3.64 6.63 6.63 0 011.72.87q2 1.29 3.94 2.55c.7.46 1.4.92 2.07 1.42a1.8 1.8 0 01.68.77 1.29 1.29 0 01-.82 1.48z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#k)"
        d="M618.73 7.42c-1.08.12 2.42 9.58 2.42 9.58s-.22 7.41 2.05 10.91 1.95 57.15 1.95 57.15l14.88-2-3.38-40.41s1.29-17.24 5.56-22.43c0 0 3.74-6.95-.23-5.11-2.82 1.32-4.07 5.38-6 4.7a1.06 1.06 0 01-.47-.46 22 22 0 01-2-5.49c-1.41-5.35-2.65-12.37-2.65-12.37S629.26-.81 629 .3s-.17 10-.16 10.51c-.17-.57-2.95-10.09-4.25-10.26s.59 9.9.71 10.48c-.22-.48-4-9.28-4.78-9.18-1.43.21 1.41 9.23 1.62 9.9-.28-.44-2.39-4.43-3.41-4.33z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#l)"
        d="M623.37 15.91a4 4 0 001.49 1.09 50.74 50.74 0 015.2 3.12c1.13.77 3 3.07 4.19 1.29.32-.46.47-1 .79-1.47a6.47 6.47 0 01.47-.57 22 22 0 01-2-5.49 6.49 6.49 0 00-1.92-.16l-4.69.09c-.84 0-1.68 0-2.52.11a1.79 1.79 0 00-1 .3 1.29 1.29 0 00-.01 1.69z"
        transform="translate(-.33)"
      ></path>
      <path
        fill="url(#m)"
        d="M566 479.21c-4 8.17-6.91 14.52-6.91 14.52l-50.33 2.4s8.34-22.07 21.91-16.37c4.59 1.92 21.22-1.69 21.22-1.69l.52-1.67z"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#3f3d56"
        d="M744.05 481.68l-7.71 4.32-66-69.38-7.28-92.62-60.18 54.59s1.61 37.59-19.43 67c-3.77 5.27-11.44 20.58-17.24 32.54-.18.37-.36.75-.53 1.12l-1.13-.23-3.93-.82-8.53-1.76 27.66-111.8 62.85-100.06 41.31 11 19 5.07s12.82 25.69 0 92.18l-8.5 38.06s34.26 18.27 44.82 54.41l4.81 16.36z"
      ></path>
      <path
        fill="url(#n)"
        d="M744.38 481.68l7.83 8.19-19.87 20.72h-26s5.54-9.15 16.62-10.6 13.73-14 13.73-14z"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#fff"
        d="M216.14 74.06l-4.39 1.44c19-11.21 15.36-39.68 15.13-41.36.3 1.35 4.72 20.14 2.31 26.31-3.01 7.71-13.05 13.61-13.05 13.61zm-31.4 34.38c-2.32 3.21-11.54 8.16-12.17 8.49 12.4-7.37 9.59-26.34 9.59-26.34v.11c.49 1.3 4.84 14.6 2.58 17.74z"
        opacity="0.22"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#fff"
        opacity="0.22"
        style={{ isolation: "isolate" }}
        d="M178.65 214.27l203.67 156.71 8.56-6.38-212.97-164.01.74 13.68zm218.97 155.7l219.71-158.14v-10.79l-226.45 164 6.74 4.93z"
      ></path>
      <path
        fill="#fff"
        d="M617.54 198.64l-.6 1.68-4.47 4.47c3.14-5.42-5.73-18-5.73-18l-3.54-2.65-10.63-8L391.21 25.52l6.41-4.91 206.47 158.27a24.86 24.86 0 0113.45 19.76z"
        opacity="0.22"
        transform="translate(-.33)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#o)"
        d="M779.73 126.4s-33.83 43.75-40.25 51.08c-11.71 13.32-19.26 30.79-19.26 30.79l-17 72.34-19-5.07s17.74-66.33 24.87-80.41 66.54-72.44 66.54-72.44z"
        opacity="0.61"
        transform="translate(-.33)"
      ></path>
      <path
        fill="#25233a"
        d="M744.04 481.66c-.35-1-11.64-32.36-28.59-45.51-17.21-13.36-27.21-31.8-27.21-31.8-4.05-35.4-4.33-128.81-4.33-128.81l19 5.07s12.82 25.69 0 92.18l-8.5 38.06s34.26 18.31 44.82 54.45zM602.88 378.57s1.61 37.59-19.43 67c-3.77 5.27-11.44 20.58-17.24 32.54l-1.66.89-3.93-.82 32.62-102.89s39.51-46.81 46.74-51.29 23.08 0 23.08 0z"
      ></path>
    </svg>
  );
};

/**
 * Taken from: https://iconscout.com/illustration/calendar-2080964
 */
export const ExpiredIllustration = ({ size = 720, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      viewBox="0 0 730.56 597"
      width={size}
      height={size}
      style={style}
    >
      <defs>
        <linearGradient
          id="d"
          x1="637.47"
          x2="466.49"
          y1="283.45"
          y2="605.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.02" stopOpacity="0"></stop>
          <stop offset="0.32" stopColor="#17161f" stopOpacity="0.36"></stop>
          <stop offset="0.76" stopColor="#343247" stopOpacity="0.82"></stop>
          <stop offset="0.99" stopColor="#3f3d56"></stop>
        </linearGradient>
        <linearGradient
          id="a"
          x1="405.81"
          x2="386.77"
          y1="-69.41"
          y2="192.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01"></stop>
          <stop offset="0.1" stopOpacity="0.77"></stop>
          <stop offset="0.99" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="e"
          x1="398.24"
          x2="329.99"
          y1="275.01"
          y2="-88.98"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="f"
          x1="307.07"
          x2="-264.01"
          y1="341.96"
          y2="343.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopOpacity="0"></stop>
          <stop offset="0.62" stopColor="#292838" stopOpacity="0.66"></stop>
          <stop offset="0.99" stopColor="#3f3d56"></stop>
        </linearGradient>
        <linearGradient
          id="g"
          x1="635"
          x2="479.85"
          y1="314.35"
          y2="316.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#68e1fd"></stop>
          <stop offset="0.01" stopColor="#67ddf9"></stop>
          <stop offset="0.12" stopColor="#5cb3ce"></stop>
          <stop offset="0.24" stopColor="#538ea9"></stop>
          <stop offset="0.37" stopColor="#4c718b"></stop>
          <stop offset="0.5" stopColor="#465a73"></stop>
          <stop offset="0.63" stopColor="#424a63"></stop>
          <stop offset="0.79" stopColor="#404059"></stop>
          <stop offset="0.99" stopColor="#3f3d56"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="432.31"
          x2="434.19"
          y1="125.53"
          y2="154.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#183866"></stop>
          <stop offset="1" stopColor="#1a7fc1"></stop>
        </linearGradient>
        <linearGradient
          id="h"
          x1="432.31"
          x2="434.19"
          y1="199.4"
          y2="227.94"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="i"
          x1="446.11"
          x2="447.98"
          y1="199.4"
          y2="227.94"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="j"
          x1="459.9"
          x2="461.77"
          y1="199.4"
          y2="227.94"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="k"
          x1="432.31"
          x2="434.19"
          y1="348.17"
          y2="376.71"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="l"
          x1="446.11"
          x2="447.98"
          y1="348.17"
          y2="376.71"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="m"
          x1="459.9"
          x2="461.77"
          y1="348.17"
          y2="376.71"
          xlinkHref="#b"
        ></linearGradient>
        <linearGradient
          id="n"
          x1="703.16"
          x2="610.33"
          y1="232.81"
          y2="196.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ecc4d7"></stop>
          <stop offset="0.16" stopColor="#edcbd5"></stop>
          <stop offset="0.42" stopColor="#efd4d1"></stop>
          <stop offset="0.7" stopColor="#f2eac9"></stop>
        </linearGradient>
        <linearGradient
          id="c"
          x1="584"
          x2="600.93"
          y1="260.94"
          y2="260.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ecc4d7"></stop>
          <stop offset="0.42" stopColor="#efd4d1"></stop>
          <stop offset="1" stopColor="#f2eac9"></stop>
        </linearGradient>
        <linearGradient
          id="o"
          x1="590.37"
          x2="604.5"
          y1="318.78"
          y2="318.78"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="p"
          x1="638.94"
          x2="600.41"
          y1="248.77"
          y2="294.02"
          xlinkHref="#c"
        ></linearGradient>
        <linearGradient
          id="q"
          x1="129.91"
          x2="114.49"
          y1="489.49"
          y2="523.36"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="r"
          x1="166.69"
          x2="119.52"
          y1="513.04"
          y2="506.99"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="s"
          x1="168.06"
          x2="109.09"
          y1="465.56"
          y2="502.46"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="t"
          x1="157.73"
          x2="94.22"
          y1="423.43"
          y2="497.22"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="u"
          x1="94.15"
          x2="160.05"
          y1="553.37"
          y2="571.71"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="v"
          x1="142.27"
          x2="162.11"
          y1="536.62"
          y2="631.72"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="w"
          x1="151.14"
          x2="150.53"
          y1="515.96"
          y2="563.45"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="x"
          x1="125.42"
          x2="174.1"
          y1="444.63"
          y2="498.46"
          xlinkHref="#a"
        ></linearGradient>
      </defs>
      <path
        fill="#68e1fd"
        d="M158.65 127.55C115.48 150.61 71 174.76 40.91 213.38 22.84 236.6 10.8 264.24 4.46 293-6.4 342.09 1.64 399 39 432.76c13 11.8 28.9 20.13 45.3 26.53a243 243 0 00106.91 15.85c29.87-2.33 59.69-10.2 89.41-6.45 36.25 4.56 67.9 25.76 101.67 39.72A265.45 265.45 0 00515 526.51c11.36-1.38 22.86-3.59 32.85-9.17 11.86-6.63 20.65-17.46 30-27.36a261.75 261.75 0 0173.46-54.69c14.43-7.22 29.72-13.21 42.25-23.37 33-26.7 38.65-74.48 36.73-116.85s-11.47-89.14-45.38-114.87c-7.57-5.74-16.09-10.19-23.43-16.24C637 143.78 629.68 110 614 82.39 578.84 20.65 500.39-6.38 429.76 1.26c-101.21 10.95-184.1 79.82-271.11 126.29z"
        opacity="0.18"
      ></path>
      <path
        fill="#68e1fd"
        d="M688.92 295.5c-.92 8.5-11.41 22.77-25.92 38.81-23.78 26.24-58.33 57.19-79.11 75.22-10.72 9.31-17.77 15.18-17.77 15.18V263.17s25.16-32.22 43.44-34.29 55.17 19.31 55.17 19.31 27.27 17.75 24.19 47.31z"
      ></path>
      <path
        fill="url(#d)"
        d="M663 334.31c-23.78 26.24-58.33 57.19-79.11 75.22-1.55-2.47-1.86-5.83-2-8.87-.55-15.19-.42-30.4-.28-45.6.13-14.76.26-29.51 1.19-44.24.88-13.72 2.56-27.75 8.84-40a15.34 15.34 0 013.45 4.48C603 288.89 608 303.88 613 318.76q3.71 11 7.42 22.09A53 53 0 00635.05 329c3.8-4.21 7.19-8.76 10.68-13.22 2.44-3.12 7.2-12 11-8.66 6.16 5.37 6.66 17.66 6.27 27.19z"
        style={{ isolation: "isolate" }}
      ></path>
      <path fill="#68e1fd" d="M175 50.48H584.5899999999999V524.85H175z"></path>
      <path
        fill="url(#a)"
        style={{ isolation: "isolate" }}
        d="M175 50.48H584.5899999999999V524.85H175z"
      ></path>
      <path
        fill="url(#e)"
        d="M528.3 73.41L175 105.08c1.59 0 0-44 0-54.6h372.5v3.72a19.21 19.21 0 01-19.2 19.21z"
        style={{ isolation: "isolate" }}
      ></path>
      <path fill="#68e1fd" d="M175 105.08H584.5899999999999V579.45H175z"></path>
      <path
        fill="url(#f)"
        style={{ isolation: "isolate" }}
        d="M175 105.08H233.23V579.45H175z"
      ></path>
      <path fill="#fff" d="M197.86 193.74H268.61V264.49H197.86z"></path>
      <path fill="#fff" d="M272.54 193.74H343.29V264.49H272.54z"></path>
      <path fill="#fff" d="M347.23 193.74H417.98V264.49H347.23z"></path>
      <path fill="#fff" d="M421.91 193.74H492.66V264.49H421.91z"></path>
      <path fill="#fff" d="M197.86 267.6H268.61V338.35H197.86z"></path>
      <path fill="#fff" d="M272.54 267.6H343.29V338.35H272.54z"></path>
      <path fill="#fff" d="M347.23 267.6H417.98V338.35H347.23z"></path>
      <path fill="#fff" d="M421.91 267.6H492.66V338.35H421.91z"></path>
      <path fill="#fff" d="M197.86 341.46H268.61V412.21H197.86z"></path>
      <path fill="#fff" d="M272.54 341.46H343.29V412.21H272.54z"></path>
      <path fill="#fff" d="M347.23 341.46H417.98V412.21H347.23z"></path>
      <path fill="#fff" d="M421.91 341.46H492.66V412.21H421.91z"></path>
      <path fill="#fff" d="M197.86 415.32H268.61V486.07H197.86z"></path>
      <path fill="#fff" d="M272.54 415.32H343.29V486.07H272.54z"></path>
      <path fill="#fff" d="M347.23 415.32H417.98V486.07H347.23z"></path>
      <path fill="#fff" d="M421.91 415.32H492.66V486.07H421.91z"></path>
      <path
        fill="#fff"
        d="M197.86 489.18H268.61V559.9300000000001H197.86z"
      ></path>
      <path
        fill="#fff"
        d="M272.54 489.18H343.29V559.9300000000001H272.54z"
      ></path>
      <path
        fill="#fff"
        d="M347.23 489.18H417.98V559.9300000000001H347.23z"
      ></path>
      <path
        fill="#fff"
        d="M495.34 193.74H566.0899999999999V264.49H495.34z"
      ></path>
      <path
        fill="#fff"
        d="M495.34 119.88H566.0899999999999V190.63H495.34z"
      ></path>
      <path fill="#fff" d="M421.91 119.88H492.66V190.63H421.91z"></path>
      <path
        fill="#fff"
        d="M495.34 267.6H566.0899999999999V338.35H495.34z"
      ></path>
      <path
        fill="#fff"
        d="M495.34 341.46H566.0899999999999V412.21H495.34z"
      ></path>
      <path
        fill="#fff"
        d="M495.34 415.32H566.0899999999999V486.07H495.34z"
      ></path>
      <path
        fill="#68e1fd"
        d="M206.73 31.3v38.36c0 2.07 2.63 3.75 5.86 3.75 3.24 0 5.86-1.68 5.86-3.75V31.3c0-2.07-2.62-3.75-5.86-3.75-3.23 0-5.86 1.68-5.86 3.75zm56.02 0v38.36c0 2.07 2.62 3.75 5.86 3.75s5.86-1.68 5.86-3.75V31.3c0-2.07-2.62-3.75-5.86-3.75s-5.86 1.68-5.86 3.75zm56.02 0v38.36c0 2.07 2.62 3.75 5.86 3.75s5.86-1.68 5.86-3.75V31.3c0-2.07-2.62-3.75-5.86-3.75s-5.86 1.68-5.86 3.75zm56.02 0v38.36c0 2.07 2.62 3.75 5.86 3.75 3.23 0 5.86-1.68 5.86-3.75V31.3c0-2.07-2.63-3.75-5.86-3.75-3.24 0-5.86 1.68-5.86 3.75zm56.01 0v38.36c0 2.07 2.63 3.75 5.87 3.75 3.23 0 5.86-1.68 5.86-3.75V31.3c0-2.07-2.63-3.75-5.86-3.75-3.24 0-5.87 1.68-5.87 3.75zm56.02 0v38.36c0 2.07 2.63 3.75 5.86 3.75 3.24 0 5.87-1.68 5.87-3.75V31.3c0-2.07-2.63-3.75-5.87-3.75-3.23 0-5.86 1.68-5.86 3.75zm54.82 0v38.36c0 2.07 2.63 3.75 5.86 3.75 3.24 0 5.86-1.68 5.86-3.75V31.3c0-2.07-2.62-3.75-5.86-3.75-3.23 0-5.86 1.68-5.86 3.75z"
      ></path>
      <path
        fill="url(#g)"
        style={{ isolation: "isolate" }}
        d="M584.6 50.48H593.9200000000001V579.45H584.6z"
      ></path>
      <path
        fill="url(#b)"
        d="M438.25 130.25a5.63 5.63 0 11-5.62-5.62 5.63 5.63 0 015.62 5.62z"
      ></path>
      <path
        fill="url(#h)"
        d="M438.25 204.12a5.63 5.63 0 11-5.62-5.62 5.63 5.63 0 015.62 5.62z"
      ></path>
      <path
        fill="url(#i)"
        d="M452 204.12a5.63 5.63 0 11-5.62-5.62 5.63 5.63 0 015.62 5.62z"
      ></path>
      <path
        fill="url(#j)"
        d="M465.83 204.12a5.63 5.63 0 11-5.62-5.62 5.63 5.63 0 015.62 5.62z"
      ></path>
      <path
        fill="url(#k)"
        d="M438.25 352.9a5.63 5.63 0 11-5.62-5.63 5.62 5.62 0 015.62 5.63z"
      ></path>
      <circle cx="209.74" cy="352.9" r="5.62" fill="#ff2235"></circle>
      <path
        fill="url(#l)"
        d="M452 352.9a5.63 5.63 0 11-5.62-5.63 5.62 5.62 0 015.62 5.63z"
      ></path>
      <path
        fill="url(#m)"
        d="M465.83 352.9a5.63 5.63 0 11-5.62-5.63 5.62 5.62 0 015.62 5.63z"
      ></path>
      <path
        fill="url(#n)"
        d="M688.25 201.7c-1.57 6.42.53 15.32-2.45 21.21-5 9.9-19.62 16.47-21.78 27.34-.13.63-16.4 8.47-24.79 5.79-4.39-1.39-6.62-5.67-3.24-15.35 1.06-3 7-4.12 8.6-6.91l-.33-.25c-8.12-6.13-9-19.12-7.32-27.79s5.53-17 9.66-24.92c3.18-6.1 10.36-9.41 17.24-9.3 8.65.15 17.78 3.61 22.28 11 3.44 5.63 3.69 12.76 2.13 19.18z"
      ></path>
      <path
        fill="#3f3d56"
        d="M648.29 155c-4.43 2.34-9.14 5.1-14.1 4.35a6.71 6.71 0 002.82 6.2l-3.08.69a.84.84 0 00-.64.36.82.82 0 00.07.68 10.14 10.14 0 003.59 4.37c-.55 1.18-2.54.92-3.22 2a2.41 2.41 0 000 2.05 11.1 11.1 0 003.4 4.77c-1.84 2-.69 5.7 1.85 6.61s5.59-.82 6.46-3.39a5.34 5.34 0 01-.76 3.31c8.39 1.32 17.36 2.56 25.08-1a6.58 6.58 0 00-.58 2.54 12.08 12.08 0 005.76.36 33.37 33.37 0 01-1.09 12.26c-.69 2.48-1.68 4.88-2.12 7.41A10.06 10.06 0 00673 216c1.33-.12 2.27-1.37 2.78-2.6a13.38 13.38 0 011.65-3.62c2-2.34 6.16-1.48 7.77 1.11s1 6.07-.54 8.68a13.19 13.19 0 01-8.64 6c.29 2.79.59 5.58.88 8.36 5.27-5 11.17-9.29 15.91-14.76s8.33-12.47 7.72-19.69c-.37-4.45-2.31-8.68-2.46-13.15-.07-2 .23-4.05.15-6.07a16.46 16.46 0 00-10.85-14.53c-2.55-.87-5.36-1.12-7.62-2.59-2.81-1.82-4.27-5.21-7.06-7.07-3-2-7.66-2.82-11.15-3.81-4.54-1.26-9.26.65-13.25 2.74z"
      ></path>
      <path
        fill="url(#c)"
        d="M585.38 249.62a3 3 0 00-1.38 2 2 2 0 001.61 1.78 5.89 5.89 0 002.57-.12l5.08-1a17.36 17.36 0 00-6.59 6.5 1.88 1.88 0 00.09 2.05c.68.86 1.83.52 2.75.16l4.41-1.76-3 2.91a13.6 13.6 0 00-2.42 2.84 5.89 5.89 0 00-.64 1.59 1.39 1.39 0 001.75 1.64 13.45 13.45 0 005-2.64 34 34 0 01-3.61 4.27 7.18 7.18 0 00-2.08 5 1 1 0 00.19.56.92.92 0 00.55.26c1.63.27 3.11-.92 4.34-2l4.53-4.11a10.13 10.13 0 001.91-2.07 2.85 2.85 0 00.49-1.51 2 2 0 00-3.07-1.51c.13-.87.47-1.68.68-2.53a3.58 3.58 0 00-.16-2.54 1.73 1.73 0 00-2.2-.83 9.67 9.67 0 001-3.33 3.21 3.21 0 00-1.47-3 2.28 2.28 0 01-.74-.47c-.4-.49-.08-1.19.09-1.8a2.91 2.91 0 00-3-3.89c-2.62.31-4.77 1.93-6.68 3.55z"
      ></path>
      <path
        fill="#68e1fd"
        d="M667.43 287.19c11.5-10.16 25.74-2.46 20.74 12.05-10.81 31.39-33.44 103.31-54.93 103.31-28 0-39.32-49.65-39.32-49.65l12.93-26.17s18.29 40.13 27.5 29.27 14.65-52 32.55-68.34z"
      ></path>
      <path
        fill="url(#o)"
        d="M602.61 325a62.5 62.5 0 01-.12-7 52.75 52.75 0 00-8.66-28.65 4.14 4.14 0 00-.71 2.9l.15 26.56a30.78 30.78 0 01-.44 6.58c-.34 1.58-.92 3.09-1.37 4.65a27.35 27.35 0 00-1 10 12.15 12.15 0 001.43 5.17 5.76 5.76 0 004.32 2.92c.59 0 3-5 3.35-5.68q1.6-3 3-6.05c.69-1.54 2.16-3.81 1.9-5.54-.29-1.94-1.61-3.73-1.85-5.86z"
      ></path>
      <path
        fill="url(#p)"
        d="M656.05 240.56c-2.42 2.72-4.82 5.45-7.24 8.18a28.68 28.68 0 01-6.52 6 13.17 13.17 0 01-3.06 1.32c-4.39-1.39-6.62-5.67-3.24-15.35 1.06-3 7-4.12 8.6-6.91l-.33-.25a15.12 15.12 0 003.61 1.18c2.6.39 9-1.63 9.79 2.22.28 1.33-.66 2.58-1.61 3.61z"
      ></path>
      <path
        fill="#68e1fd"
        d="M365.28 450l10.08 15.66a3.13 3.13 0 005 .35l27.76-32a3.12 3.12 0 00-2.35-5.17h-.23a3.13 3.13 0 00-2.4 1.12L381.42 456a3.11 3.11 0 01-5-.29l-5.91-9a3.12 3.12 0 00-3.08-1.37 3.11 3.11 0 00-2.15 4.66zm-121.37 79.19l12.69 12.49a3.16 3.16 0 01-2.22 5.41h-.54a3.19 3.19 0 01-2.24-.92l-12.35-12.36a3.17 3.17 0 00-4.47 0l-12.35 12.36a3.16 3.16 0 01-2.23.92h-.55a3.16 3.16 0 01-2.22-5.41l12.7-12.49a3.15 3.15 0 000-4.48l-12.82-12.82a3.16 3.16 0 012.23-5.39 3.14 3.14 0 012.21.9l13 12.82a3.16 3.16 0 004.43 0l13-12.82a3.14 3.14 0 012.21-.9 3.16 3.16 0 012.24 5.39l-12.82 12.82a3.15 3.15 0 00.1 4.48z"
      ></path>
      <path
        fill="#68e1fd"
        d="M130.74 489.92L133.84 489.92 135.39 559.98 133.84 548.44 130.74 548.44 130.74 489.92z"
      ></path>
      <path fill="#68e1fd" d="M133.84 489.92H136.94V548.44H133.84z"></path>
      <path
        fill="#68e1fd"
        d="M108.63 498.15c-1.75 5.41 3.78 16.12 9.14 21.66s9 4.27 14.46 2.69c1.75-5.41 3.24-9.1-2.13-14.64s-16.01-11.29-21.47-9.71z"
      ></path>
      <path
        fill="#68e1fd"
        d="M159.54 498.42c-5.41-1.76-16.12 3.78-21.66 9.14s-4.27 9-2.69 14.46c5.41 1.75 9.1 3.24 14.64-2.13s11.3-16.01 9.71-21.47zm-25.7-22.63H120c0 9.83 6.19 17.8 13.83 17.8l3.26-8.73z"
      ></path>
      <path
        fill="#68e1fd"
        d="M133.84 475.79h13.83c0 9.83-6.19 17.8-13.83 17.8zm-26.2-20.05c-1.75 5.41 3.78 16.13 9.15 21.66s9 4.28 14.45 2.69c1.75-5.41 3.24-9.1-2.13-14.64s-16.01-11.29-21.47-9.71z"
      ></path>
      <path
        fill="#68e1fd"
        d="M158.56 456c-5.42-1.75-16.13 3.78-21.66 9.15s-4.28 9-2.7 14.45c5.41 1.75 9.11 3.24 14.64-2.13s11.3-16 9.72-21.47z"
      ></path>
      <path
        fill="#68e1fd"
        d="M133.59 438.73c-5.12 2.48-9 13.88-9.32 21.59s3.66 16.78 8.58 19.63c5.12-2.48 8.29-11.28 8.58-19s-2.92-19.37-7.84-22.22z"
      ></path>
      <path
        fill="#68e1fd"
        style={{ isolation: "isolate" }}
        d="M106.49 539.51L111.37 578.95 133.94 578.95 143.16 556.51 133.94 538.41 106.49 539.51z"
      ></path>
      <path
        fill="#68e1fd"
        style={{ isolation: "isolate" }}
        d="M161.4 539.51L156.52 578.95 133.94 578.95 133.94 538.41 161.4 539.51z"
      ></path>
      <path
        fill="#68e1fd"
        d="M144 538.68l-.25.12-7.66 3.8-2.09 1.06h-30.49a3.47 3.47 0 01-3.47-3.47v-8.82a3.47 3.47 0 013.47-3.47H134z"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#68e1fd"
        d="M137.42 527.9h30.44v15.76h-30.44a3.47 3.47 0 01-3.47-3.47v-8.82a3.47 3.47 0 013.47-3.47z"
        transform="rotate(180 150.9 535.78)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#q)"
        d="M108.63 498.15c-1.75 5.41 3.78 16.12 9.14 21.66s9 4.27 14.46 2.69c1.75-5.41 3.24-9.1-2.13-14.64s-16.01-11.29-21.47-9.71z"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#r)"
        d="M159.54 498.42c-5.41-1.76-16.12 3.78-21.66 9.14s-4.27 9-2.69 14.46c5.41 1.75 9.1 3.24 14.64-2.13s11.3-16.01 9.71-21.47z"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#s)"
        d="M147.48 478.69c-1.07 8.45-6.77 14.9-13.64 14.9v-15.23c.11.41.24.82.36 1.25 4.95 1.6 8.47 2.99 13.28-.92z"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="url(#t)"
        d="M107.64 455.74c-1.75 5.41 3.78 16.13 9.15 21.66s9 4.28 14.45 2.69c1.75-5.41 3.24-9.1-2.13-14.64s-16.01-11.29-21.47-9.71z"
        style={{ isolation: "isolate" }}
      ></path>
      <g style={{ isolation: "isolate" }}>
        <path
          fill="url(#u)"
          style={{ isolation: "isolate" }}
          d="M133.95 543.66L133.95 578.95 111.37 578.95 107 543.66 133.95 543.66z"
        ></path>
        <path
          fill="url(#v)"
          style={{ isolation: "isolate" }}
          d="M160.89 543.66L156.52 578.95 133.95 578.95 133.95 543.66 160.89 543.66z"
        ></path>
        <path
          fill="url(#w)"
          d="M167.85 531.77v8.82a3.47 3.47 0 01-3.47 3.47H134V528.3h30.43a3.47 3.47 0 013.42 3.47z"
          style={{ isolation: "isolate" }}
        ></path>
      </g>
      <path
        fill="#1d2741"
        d="M114 579l-57.36 11a3.5 3.5 0 00.73 7h647.86a3.51 3.51 0 00.49-7l-83.61-10.52h-.48l-506.9-.5a3.64 3.64 0 00-.73.02z"
        opacity="0.18"
      ></path>
      <path
        fill="url(#x)"
        d="M148.84 477.48c-.47.45-.92.86-1.36 1.21-4.81 3.91-8.33 2.52-13.28.92-.12-.43-.25-.84-.36-1.25-1.38-4.79-2.05-8.26 3.06-13.2 5.53-5.37 16.24-10.9 21.66-9.15 1.58 5.46-4.18 16.11-9.72 21.47z"
        style={{ isolation: "isolate" }}
      ></path>
    </svg>
  );
};
