import * as constants from "../../api/constants";
import { useState } from "react";
import { FormErrorMsg, FormInfoMsg } from "../../components/FormMessages";
import { Loader } from "../../components/loaders/Loader";
import { isEmail } from "../../api/utils";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { useMutation } from "react-query";
import { isValidBusinessName } from "../../api/validators";
import { usePopup } from "../../hooks/usePopup";
import { addBusiness } from "../../api/fetchers/main";
import {
  DropdownSelect,
  DropdownSelectOption,
} from "../../components/DropdownSelect";
import {
  faAt,
  faCoins,
  faCreditCard,
  faEnvelope,
  faSackDollar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { TaggedInput } from "../../components/TaggedInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AddBusinessPage = () => {
  async function requestAddBusiness() {
    if (!isEmail(email)) {
      setErrorMsg("Ingrese un email válido.");
      return;
    }

    if (!name || !username) {
      setErrorMsg("Llene todos los campos.");
      return;
    }

    if (!isValidBusinessName(name)) {
      setErrorMsg("El nombre del negocio no es válido.");
      return;
    }

    setErrorMsg("");

    mutation.mutate({
      name: name,
      username: username,
      email: email,
      role: selectedPlan,
    });
  }

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const popup = usePopup();

  const [errorMsg, setErrorMsg] = useState("");

  const mutation = useMutation({
    mutationFn: addBusiness,
    onSuccess: () => popup.showSuccess("Se añadió el negocio correctamente."),
    onError: () =>
      popup.showError(
        "No se pudo añadir el negocio. Por favor vuelva a intentar."
      ),
  });

  const [selectedPlan, setSelectedPlan] = useState("");

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Añadir Negocio</b>
      </p>
      <TaggedInput>
        <FontAwesomeIcon icon={faUser} />
        <input
          className="form"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nombre del negocio*"
        />
      </TaggedInput>
      <TaggedInput>
        <FontAwesomeIcon icon={faAt} />
        <input
          className="form"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Usuario*"
        />
      </TaggedInput>
      <DropdownSelect
        setter={setSelectedPlan}
        text={
          selectedPlan === constants.BASIC_PLAN.role
            ? constants.BASIC_PLAN.name
            : selectedPlan === constants.PREMIUM_PLAN.role
            ? constants.PREMIUM_PLAN.name
            : undefined
        }
        icon={faCreditCard}
        defaultText="Seleccionar plan...*"
      >
        <DropdownSelectOption
          text={constants.BASIC_PLAN.name}
          value={constants.BASIC_PLAN.role}
          icon={faCoins}
        />
        <DropdownSelectOption
          text={constants.PREMIUM_PLAN.name}
          value={constants.PREMIUM_PLAN.role}
          icon={faSackDollar}
        />
      </DropdownSelect>
      <TaggedInput>
        <FontAwesomeIcon icon={faEnvelope} />
        <input
          className="form"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo electrónico*"
        />
      </TaggedInput>
      {email && !isEmail(email) && (
        <FormErrorMsg text="Ingrese un email válido." />
      )}
      <FormInfoMsg text="Se enviará un correo electrónico al usuario para la creación de contraseña. Además, el negocio será Público por defecto." />
      {errorMsg ? <FormErrorMsg text={errorMsg} /> : null}
      <button
        className="wide centered semi_rounded"
        disabled={
          mutation.isLoading ||
          !selectedPlan ||
          !name ||
          !username ||
          !isEmail(email)
        }
        onClick={requestAddBusiness}
      >
        {mutation.isLoading ? <Loader /> : null}
        Añadir
      </button>
    </AnimatedPanelPage>
  );
};
