import { getCardBrand, isDigit } from "../api/utils";
import { SVGIcon } from "./SVGIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/CardNumberInput.css";

export const CardNumberInput = ({ state, setter, disabled = false }) => {
  function handleChange(e) {
    const value = e.target.value.replaceAll(" ", "");
    if (!isDigit(value) && value !== "") return;
    if (value.length >= 20) return;
    setter(value);
  }

  function spacedNumber(number) {
    let spaced = "";
    for (let i = 0; i < number.length; i++) {
      if (i % 4 === 0 && i > 0) spaced += " ";
      spaced += number[i];
    }
    return spaced;
  }

  const brand = getCardBrand(state);

  return (
    <div className="card-number-input-container">
      <input
        className="card-number-input underlined"
        inputMode="numeric"
        value={spacedNumber(state)}
        placeholder="Número de tarjeta"
        onChange={handleChange}
        disabled={disabled}
      />
      {brand ? (
        <SVGIcon className="card-brand-icon" icon_name={brand} size={32} />
      ) : (
        <FontAwesomeIcon
          className="card-brand-icon"
          icon={faCreditCard}
          style={{ fontSize: "24px" }}
        />
      )}
    </div>
  );
};
