import { useState } from "react";
import { Helmet } from "react-helmet-async";
import * as constants from "../../api/constants";
import { FormInfoMsg, FormWarningMsg } from "../../components/FormMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { isDigit, isEmail } from "../../api/utils";
import { CheckBox } from "../../components/CheckBox";
import { useMutation } from "react-query";
import { usePopup } from "../../hooks/usePopup";
import { signup } from "../../api/fetchers/auth";
import { Content } from "../../components/Content";
import {
  isValidBusinessName,
  isValidUsername,
  isValidPassword,
} from "../../api/validators";
import { PasswordInput } from "../../components/PasswordInput";
import { PasswordStrengthLabel } from "../../components/PasswordStrengthLabel";
import { useNavigate } from "react-router";
import { GlobalLoader } from "../../components/loaders/GlobalLoader";

const Mandatory = () => {
  return (
    <b
      style={{
        display: "inline-flex",
        alignItems: "center",
        fontSize: "9px",
        padding: "4px 8px",
        backgroundColor: "var(--main-color)",
        color: "white",
        borderRadius: "10px",
        marginLeft: "10px",
      }}
    >
      <FontAwesomeIcon icon={faCertificate} />
      &nbsp; Obligatorio
    </b>
  );
};

export const Signup = () => {
  function validForm() {
    return (
      isValidBusinessName(name) &&
      isValidUsername(username) &&
      contactName &&
      contactEmail &&
      isEmail(contactEmail) &&
      isValidPassword(password) &&
      passwordConfirmation === password
    );
  }

  function handleSendForm() {
    mutation.mutate({
      bsns_name: name,
      username: username,
      con_name: contactName,
      con_email: contactEmail,
      con_phone: contactPhone ? contactPhone : undefined,
      password: password,
    });
  }

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [privacyAgreement, setPrivacyAgreement] = useState(false);

  const popup = usePopup();

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: signup,
    onSuccess: (data) =>
      popup.showSuccess(
        <>
          <b>¡Te has registrado!</b>
          <br />
          <br />A continuación, puedes iniciar sesión con tu usuario y
          contraseña. También hemos enviado un correo electrónico a{" "}
          <b className="text-purple">{data.email}</b> para verificar tu cuenta.
        </>,
        () => navigate(constants.LOGIN_ROUTE)
      ),
    onError: (err) => {
      if (err.message === "email") {
        popup.showError("Ya existe una cuenta con este correo electrónico.");
      } else if (err.message === "username") {
        popup.showError("Ya existe una cuenta con este nombre de usuario.");
      } else {
        popup.showError(
          "Ha ocurrido un error al procesar tu solicitud. Inténtalo de nuevo más tarde."
        );
      }
    },
  });

  return (
    <Content>
      <Helmet title="Registrarse" />
      {mutation.isLoading && <GlobalLoader />}
      <div className="centered_content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "500px",
            maxWidth: "100%",
            padding: "20px",
          }}
        >
          <p className="l bold">Registrarse</p>
          <p className="m bold">Información básica</p>
          <hr style={{ width: "100%", marginTop: "-10px" }} />
          <p className="bold">
            Nombre del negocio
            <Mandatory />
          </p>
          <p>Este es el nombre que aparecerá en la página del negocio.</p>
          <input
            className="underlined"
            placeholder={`(ejemplo: "${constants.NAME}")`}
            value={name}
            onChange={(e) => {
              const val = e.target.value;
              if (
                Array.from(val).some(
                  (c) => !constants.BUSINESS_NAME_ALLOWED_CHARACTERS.includes(c)
                )
              )
                return;
              setName(val);
            }}
            maxLength={constants.BUSINESS_NAME_MAX_LENGTH}
          />
          {name && !isValidBusinessName(name) && (
            <FormWarningMsg
              text={`Escoge un nombre de mínimo ${constants.BUSINESS_NAME_MIN_LENGTH} caracteres`}
            />
          )}
          <p className="bold">
            Nombre único del negocio
            <Mandatory />
          </p>
          <p>
            Con este nombre iniciarás sesión y aparecerá en el enlace a tu
            página.
          </p>
          <input
            className="underlined"
            placeholder={`(ejemplo: "${constants.NAME.toLowerCase()}")`}
            value={username}
            onChange={(e) => {
              const val = e.target.value;
              if (
                Array.from(val).some(
                  (c) => !constants.USERNAME_ALLOWED_CHARACTERS.includes(c)
                )
              )
                return;
              setUsername(val);
            }}
            maxLength={constants.USERNAME_MAX_LENGTH}
          />
          {username && !isValidUsername(username) ? (
            <FormWarningMsg
              text={`Escoge un nombre de usuario de mínimo ${constants.USERNAME_MIN_LENGTH} caracteres`}
            />
          ) : (
            username && (
              <FormInfoMsg
                text={
                  <>
                    El enlace a tu página será&nbsp;
                    <b>
                      {window.location.host}/b/{username}
                    </b>
                  </>
                }
              />
            )
          )}
          <p className="m bold">Contacto</p>
          <hr style={{ width: "100%", marginTop: "-10px" }} />
          <p>
            Ingresa tu nombre completo, y un correo electrónico para
            contactarte.
          </p>
          <p className="bold">
            Nombre
            <Mandatory />
          </p>
          <input
            className="underlined"
            placeholder="Tu nombre completo"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            maxLength={60}
          />
          <p className="bold">
            Correo electrónico <Mandatory />
          </p>
          <input
            className="underlined"
            placeholder={"ejemplo@" + window.location.host}
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            maxLength={60}
          />
          {contactEmail && !isEmail(contactEmail) ? (
            <FormWarningMsg text="Ingresa un correo electrónico válido" />
          ) : null}
          <p className="bold">Número de teléfono</p>
          <input
            className="underlined"
            placeholder="Celular"
            value={contactPhone}
            onChange={(e) =>
              isDigit(e.target.value) || e.target.value === ""
                ? setContactPhone(e.target.value)
                : null
            }
            maxLength={20}
          />
          <p className="m bold">
            Contraseña <Mandatory />
          </p>
          <hr style={{ marginTop: "-10px" }} />
          <p>Ingresa una contraseña para iniciar sesión.</p>
          <FormInfoMsg
            text={`La contraseña debe contener mínimo ${constants.PASSWORD_MIN_LENGTH} caracteres. Puedes incluir letras, números y caracteres especiales.`}
          />
          <PasswordInput
            placeholder="Contraseña"
            style={{ width: "100%" }}
            state={password}
            setter={setPassword}
            inputClassName="underlined"
          />
          <PasswordInput
            placeholder="Confirma contraseña"
            style={{ width: "100%" }}
            state={passwordConfirmation}
            setter={setPasswordConfirmation}
            inputClassName="underlined"
          />
          {password && password.length < constants.PASSWORD_MIN_LENGTH ? (
            <FormWarningMsg
              text={`La contraseña debe tener al menos ${constants.PASSWORD_MIN_LENGTH} caracteres.`}
            />
          ) : (
            password &&
            passwordConfirmation !== password && (
              <FormWarningMsg text="Las contraseñas no coinciden" />
            )
          )}
          <p>
            Seguridad:{" "}
            <PasswordStrengthLabel
              password={password}
              style={{ fontSize: "14px", marginLeft: "5px" }}
            />
          </p>
          <hr />
          {!validForm() && (
            <FormWarningMsg text="Debes llenar todos los campos obligatorios." />
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <CheckBox state={privacyAgreement} setter={setPrivacyAgreement} />
            <p className="s">
              He leído y acepto las{" "}
              <a
                className="link bold"
                href={constants.PRIVACY_POLICY_ROUTE}
                rel="noreferrer"
                target="_blank"
              >
                Políticas de Privacidad y Tratamiento de Datos
              </a>
              .
            </p>
          </div>
          <FormInfoMsg
            text={`Al enviar este formulario, aceptas que ${constants.NAME} almacene y procese tu información
              personal para los fines descritos en las Políticas de Privacidad y Tratamiento de Datos.`}
          />
          <button
            className="semi_rounded wide right"
            disabled={!(validForm() && privacyAgreement) || mutation.isLoading}
            onClick={handleSendForm}
          >
            Registrarse <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </Content>
  );
};
