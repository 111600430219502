import { useRef, useState } from "react";
import { SideBar } from "../../components/SideBar";
import { PanelSidebar } from "../../components/PanelSideBar";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGear,
  faShieldHalved,
  faRightFromBracket,
  faImages,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Outlet } from "react-router";
import { Helmet } from "react-helmet-async";
import { NotificationButton } from "../../components/NotificationButton";
import { useMutation } from "react-query";
import { SpinLoader } from "../../components/loaders/SpinLoader";
import "../../styles/pages/business_panel/BusinessPanel.css";
import { usePopup } from "../../hooks/usePopup";
import { logout } from "../../api/fetchers/auth";
import { useAuth } from "../../hooks/useAuth";
import { Content } from "../../components/Content";

export const BusinessPanel = () => {
  function reqLogout() {
    function requestLogout() {
      logoutM.mutate();
      popup.showSuccess("Sesión cerrada correctamente.");
      navigate("/");
      auth.clear();
    }

    popup.showConfirm("¿Seguro que quieres cerrar sesión?", requestLogout);
  }

  const sideBarRef = useRef();
  const navigate = useNavigate();

  const [page, setPage] = useState("general");

  const popup = usePopup();

  const auth = useAuth();

  if (auth.authState === "unknown") {
    auth.refetch();
  }

  const logoutM = useMutation({
    mutationFn: logout,
  });

  return (
    <Content>
      <Helmet title="Panel del negocio" />
      <NotificationButton />
      <SideBar
        ref={sideBarRef}
        items={[
          [
            "General",
            () => {
              setPage("requests");
              navigate("general");
            },
          ],
          [
            "Tu Catálogo",
            () => {
              setPage("catalog");
              navigate("catalog");
            },
          ],
          [
            "Suscripción",
            () => {
              setPage("subscription");
              navigate("subscription");
            },
          ],
          [
            "Privacidad y Seguridad",
            () => {
              setPage("security");
              navigate("privacy");
            },
          ],
          ["Cerrar sesión", reqLogout],
        ]}
      />
      {logoutM.isLoading ? (
        <div className="modal_container">
          <SpinLoader />
        </div>
      ) : null}
      <p
        style={{ alignItems: "center", paddingTop: "30px" }}
        className="xxl centered linespace-xl"
      >
        <button
          className="semi_rounded mobile"
          onClick={() => {
            sideBarRef.current.show();
          }}
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <b>Panel</b>
      </p>
      <div
        style={{
          display: "flex",
          gap: "30px",
          width: "fit-content",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        className="centered"
      >
        <PanelSidebar
          selected={page}
          items={[
            [
              "general",
              "General",
              () => {
                setPage("general");
                navigate("general");
              },
              faGear,
            ],
            [
              "catalog",
              "Tu Catálogo",
              () => {
                setPage("catalog");
                navigate("catalog");
              },
              faImages,
            ],
            [
              "verification",
              "Verificación",
              () => {
                setPage("verification");
              },
              "verified_badge",
              true,
            ],
            [
              "subscription",
              "Suscripción",
              () => {
                setPage("subscription");
                navigate("subscription");
              },
              faDollar,
            ],
            [
              "security",
              "Privacidad y Seguridad",
              () => {
                setPage("security");
                navigate("privacy");
              },
              faShieldHalved,
            ],
            ["logout", "Cerrar sesión", reqLogout, faRightFromBracket],
          ]}
        />
        <div style={{ width: "800px" }} className="container semi_rounded">
          <Outlet />
        </div>
      </div>
    </Content>
  );
};
