import React from "react";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";

export const RequestsPage = () => {
  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Solicitudes</b>
      </p>
    </AnimatedPanelPage>
  );
};
