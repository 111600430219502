import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SUB_PLAN_NAMES,
  SUB_PLAN_STATUS_ACTIVE,
  SUB_PLAN_STATUS_INACTIVE,
} from "../api/constants";
import "../styles/components/SubscriptionStatus.css";
import { formatDatetime } from "../api/utils";

export const SubscriptionStatus = ({
  plan,
  status,
  exp,
  onButtonClick,
  buttonText = "Cambiar",
}) => {
  return (
    <div className="container semi_rounded subscription-status-container">
      <div className="subscription-info-container">
        <div className="subscription-status">
          <p className="bold">{SUB_PLAN_NAMES[plan]}</p>
          <FontAwesomeIcon
            icon={faCircle}
            style={{
              color: status === SUB_PLAN_STATUS_ACTIVE ? "#00ff8c" : "#ff0062",
              fontSize: "10px",
            }}
          />
          <p className="text-light st">
            {status === SUB_PLAN_STATUS_ACTIVE ? (
              <>Activo</>
            ) : status === SUB_PLAN_STATUS_INACTIVE ? (
              <>Inactivo</>
            ) : (
              <>Cancelado</>
            )}
          </p>
        </div>
        {exp && (
          <p className="text-light st">
            <b>Hasta:</b>{" "}
            {formatDatetime(exp, {
              includeHour: false,
            })}
          </p>
        )}
      </div>
      {buttonText && (
        <button className="semi_rounded" onClick={onButtonClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};
