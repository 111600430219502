import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/Tooltip.css";
import { useState } from "react";

export const InfoTooltip = ({ children, style, containerStyle }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <span className="tooltip-container" style={containerStyle}>
      <FontAwesomeIcon
        icon={faCircleInfo}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip && (
        <span className="tooltip-component" style={style}>
          {children}
        </span>
      )}
    </span>
  );
};
