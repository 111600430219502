import { cloneElement, useState } from "react";
import { CheckBox } from "../../components/CheckBox";
import { FormInfoMsg } from "../../components/FormMessages";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { useMutation } from "react-query";
import { cancelSubscription } from "../../api/fetchers/payments";
import { usePopup } from "../../hooks/usePopup";
import { formatDatetime } from "../../api/utils";
import { GlobalLoader } from "../../components/loaders/GlobalLoader";
import { cancelSubscriptionForm } from "../../api/fetchers/forms";
import { CONTACT_ROUTE } from "../../api/constants";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const CancelReasonsContainer = ({ reasonsState, reasonsSetter, children }) => {
  return (
    <>
      {children.map((child) =>
        cloneElement(child, {
          key: child.props.id,
          reasonsState,
          reasonsSetter,
        })
      )}
    </>
  );
};

const CancelReason = ({ id, reasonsState, reasonsSetter, text }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
      <CheckBox
        state={reasonsState.includes(id)}
        setter={(val) => {
          if (val) {
            reasonsSetter([...reasonsState, id]);
          } else {
            reasonsSetter(reasonsState.filter((reason) => reason !== id));
          }
        }}
      />
      {text}
    </div>
  );
};

export const CancelSubscriptionPage = () => {
  function handleCancelSubscription() {
    popup.showConfirm(
      "¿Estás seguro de que deseas cancelar tu suscripción?",
      () => {
        mutate();
      }
    );
  }

  const [cancelReasons, setCancelReasons] = useState([]);
  const [otherReason, setOtherReason] = useState("");

  const [afterContinue, setAfterContinue] = useState(false);

  const popup = usePopup();
  const auth = useAuth();

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: (data) => {
      if (cancelReasons.length > 0) {
        reasonsMutation.mutate({
          reasons: cancelReasons,
          otherReasons: cancelReasons.includes("other")
            ? otherReason
            : undefined,
        });
      }
      auth.refetch();
      popup.showSuccess(
        <>
          Tu suscripción ha sido cancelada exitosamente.
          <br />
          <b>
            Puedes disfrutar de los beneficios de tu suscripción hasta el{" "}
            {formatDatetime(data.termination_date)}
          </b>
        </>,
        () => navigate("./../")
      );
    },
    onError: () =>
      popup.showError(
        <>
          Ha ocurrido un error al cancelar tu suscripción.
          <br />
          <br />
          Por favor intente más tarde o{" "}
          <a
            className="bold link"
            href={CONTACT_ROUTE}
            target="_blank"
            rel="noreferrer"
          >
            póngase en contácto con nosotros.
          </a>
        </>,
        () => navigate("./../")
      ),
  });

  const reasonsMutation = useMutation({
    mutationFn: cancelSubscriptionForm,
  });

  return (
    <AnimatedPanelPage>
      <p className="l">
        <b>Cancelar suscripción</b>
      </p>
      {isLoading && <GlobalLoader />}
      <FormInfoMsg
        text="Al cancelar tu suscripción,
      no se te cobrará más. Sin embargo, podrás seguir
      disfrutando de los beneficios de tu plan hasta que
      finalice el período de facturación actual."
      />
      {afterContinue ? (
        <>
          <b>Antes de proceder...</b>
          <p>Nos gustaría saber por qué deseas cancelar tu suscripción:</p>
          <CancelReasonsContainer
            reasonsSetter={setCancelReasons}
            reasonsState={cancelReasons}
          >
            <CancelReason
              id="no-interest"
              text="He perdido el interés en el servicio."
            />
            <CancelReason id="not-useful" text="No me resulta útil." />
            <CancelReason id="too-expensive" text="Es demasiado caro." />
            <CancelReason
              id="technical-issues"
              text="He experimentado problemas técnicos."
            />
            <CancelReason
              id="support"
              text="He tenido problemas con la atención al cliente."
            />
            <CancelReason
              id="duration"
              text="Sólo necesitaba el servicio termporalmente."
            />
            <CancelReason
              id="billing"
              text="He tenido problemas con la facturación."
            />
            <CancelReason id="other" text="Otro Motivo:" />
          </CancelReasonsContainer>
          <input
            style={{ width: "320px" }}
            placeholder="Especificar motivo..."
            maxLength={200}
            disabled={!cancelReasons.includes("other")}
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
          />
          <hr />
          <button
            className="semi_rounded left red wide"
            onClick={handleCancelSubscription}
          >
            Cancelar suscripción
          </button>
        </>
      ) : (
        <button
          className="semi_rounded left"
          onClick={() => setAfterContinue(true)}
        >
          Continuar
        </button>
      )}
    </AnimatedPanelPage>
  );
};
