import React from "react";
import { useQuery } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router";
import { BannerGradient } from "../../components/BannerGradient";
import { ImagePlaceholder } from "../../components/Image";
import { SVGIcon } from "../../components/SVGIcon";
import {
  HorizontalPageSelector,
  HorizontalPageSelectorOption,
} from "../../components/HorizontalMenu";
import * as utils from "../../api/utils";
import { Helmet } from "react-helmet-async";
import useImageColor from "../../hooks/useImageColor";
import "../../styles/pages/business_page/BusinessPage.css";
import { getBusinessHeaderInfo } from "../../api/fetchers/files";
import { Content } from "../../components/Content";
import { CONTACT_ROUTE, NAME } from "../../api/constants";
import { ForbiddenError, NotFound, UnauthorizedError } from "../../api/errors";
import {
  AccessDenied,
  BadGateway,
  NotFoundIllustration,
} from "../../assets/illustrations";
import { AnimatedPanelPage } from "../../components/animations/AnimatedPage";
import { faImages, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { LocationPin } from "../../components/LocationPin";

export const BusinessPage = () => {
  const { business_name } = useParams();

  const { status, data, isSuccess, error, isError } = useQuery({
    queryFn: () => getBusinessHeaderInfo({ business_name: business_name }),
    queryKey: `${business_name}-header`,
    staleTime: 600000,
  });

  const navigate = useNavigate();

  var route_select = utils.getBusinessPageCurrentRoute();
  if (route_select === "location") {
    route_select = 1;
  } else if (route_select === "about") {
    route_select = 2;
  } else {
    route_select = 0;
  }

  const { colors } = useImageColor(data ? data.logo.thumbnail_img : undefined, {
    cors: true,
    colors: 2,
  });

  return (
    <Content>
      <Helmet title={!isSuccess ? NAME : data.name} />
      <div
        className="centered"
        style={{ width: "fit-content", maxWidth: "100%" }}
      >
        <BannerGradient
          color1={colors ? colors[0] : undefined}
          color2={colors ? colors[1] : undefined}
        />
        <div className="business_page_header">
          <ImagePlaceholder
            className="business_page_logo"
            src={data ? data.logo.thumbnail_img : null}
            status={status}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <b className="xl font-secondary">
                {data ? data.name : <>@{business_name}</>}
                {data && data.verified ? (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      userSelect: "none",
                    }}
                    className="tooltip"
                  >
                    <span className="tooltiptext">
                      Este es un negocio legalmente constituido y ha sido
                      verificado.
                    </span>
                    <SVGIcon
                      icon_name="verified_badge"
                      style={{
                        color: "var(--main-color)",
                        marginLeft: "10px",
                      }}
                      size={28}
                    />
                  </span>
                ) : null}
              </b>
            </div>
            {isSuccess && (data.location.city || data.location.country) && (
              <LocationPin
                style={{
                  display: "flex",
                }}
                city={data.location.city}
                country={data.location.country}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {data ? (
              <>
                {data.wa_number ? (
                  <a
                    href={`https://wa.me/${data.wa_number}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="transparent semi_rounded inline">
                      <SVGIcon
                        icon_name="whatsapp"
                        style={{ fontSize: "20px", marginRight: "4px" }}
                      />
                      WhatsApp
                    </button>
                  </a>
                ) : null}
                {data.ig_user ? (
                  <a
                    href={`https://instagram.com/${data.ig_user}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="transparent semi_rounded inline">
                      <SVGIcon
                        icon_name="instagram"
                        style={{ fontSize: "20px", marginRight: "4px" }}
                      />
                      Instagram
                    </button>
                  </a>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div className="centered" style={{ width: "1000px", maxWidth: "100%" }}>
          <HorizontalPageSelector
            defaultSelect={route_select}
            style={{ marginTop: "10px" }}
          >
            <HorizontalPageSelectorOption
              icon={faImages}
              text="Colecciones"
              onClick={() => navigate("collections")}
            />
            <HorizontalPageSelectorOption
              icon={faInfoCircle}
              text="Más Información"
              onClick={() => navigate("about")}
            />
          </HorizontalPageSelector>
          <hr style={{ marginBottom: "20px", marginTop: "0px" }} />
          {isSuccess && <Outlet context={{ business_id: data._id }} />}
          {isError && (
            <AnimatedPanelPage
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                className="text-light sm"
                style={{
                  position: "relative",
                  top: "52px",
                  textAlign: "center",
                }}
              >
                <b>
                  {error instanceof NotFound ? (
                    <>ESTE NEGOCIO NO EXISTE</>
                  ) : error instanceof ForbiddenError ? (
                    <>
                      ESTE NEGOCIO HA SIDO BLOQUEADO.
                      <br /> SI ES EL DUEÑO{" "}
                      <a className="link bold" href={CONTACT_ROUTE}>
                        PÓNGASE EN CONTACTO.
                      </a>
                    </>
                  ) : error instanceof UnauthorizedError ? (
                    <>ESTE NEGOCIO NO ESTÁ DISPONIBLE.</>
                  ) : null}
                </b>
              </p>
              {error instanceof NotFound ? (
                <NotFoundIllustration
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : error instanceof UnauthorizedError ? (
                <AccessDenied style={{ maxWidth: "100%", height: "auto" }} />
              ) : error instanceof ForbiddenError ? (
                <BadGateway
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    position: "relative",
                    top: "70px",
                    maxHeight: "480px",
                  }}
                />
              ) : null}
            </AnimatedPanelPage>
          )}
        </div>
      </div>
    </Content>
  );
};
