import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { AnimatedBusinessPage } from "../../components/animations/AnimatedBusinessPage";
import { LineLoader } from "../../components/loaders/LineLoader";
import { getBusinessHeaderInfo } from "../../api/fetchers/files";

export const BusinessAboutPage = () => {
  const { business_name } = useParams();

  const { isLoading, isSuccess, data } = useQuery({
    queryFn: () => getBusinessHeaderInfo({ business_name: business_name }),
    queryKey: `${business_name}-header`,
    staleTime: 600000,
  });

  return (
    <AnimatedBusinessPage>
      <div
        style={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}
      >
        <div
          className="container semi_rounded"
          style={{ width: "500px", maxWidth: "100vw" }}
        >
          <p className="l linespace-l">
            <b>Acerca del negocio</b>
          </p>
          {isLoading ? (
            <LineLoader />
          ) : isSuccess ? (
            <p className="sm">{data.description}</p>
          ) : null}
        </div>
      </div>
    </AnimatedBusinessPage>
  );
};
