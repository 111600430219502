import { SVGIcon } from "./SVGIcon";
import "../styles/components/MercadoPagoSecureCard.css";

export const MercadoPagoSecureCard = ({
  text = (
    <>
      Los datos de tu tarjeta están protegidos y son procesados de forma segura
      por <b style={{ color: "#00bcff" }}>MercadoPago</b>
    </>
  ),
  buttonText = "Más información",
  buttonRef = "",
  onButtonClick,
}) => {
  return (
    <div className="mercado-pago-secure-card container semi_rounded">
      <SVGIcon icon_name="mercado_pago" size={32} />
      <p>{text}</p>
      {buttonText && (
        <a href={buttonRef} target="_blank" rel="noreferrer">
          <button onClick={onButtonClick}>{buttonText}</button>
        </a>
      )}
    </div>
  );
};
