import "../styles/components/InputTag.css";

export const InstagramInput = ({ state, setter }) => { 
  
  return (
    <div style={{ display: "flex" }}>
      <div className="input_tag">
        <p className="s">
          <b>www.instagram.com/</b>
        </p>
      </div>
      <input
        value={state}
        onChange={(e) => setter(e.target.value.toLowerCase())}
        placeholder="Usuario de instagram"
        maxLength={30}
      />
    </div>
  );
};
