import axios from "axios";
import { validateArgs } from "../errors";

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/payments",
  withCredentials: true,
});

export async function getSubscriptionData() {
  const { data } = await fetcher.get("/subscription");

  return data;
}

export async function initializeSubscription({ plan_id }) {
  validateArgs(plan_id);

  const { data } = await fetcher.get(`/subscription/init?plan=${plan_id}`);

  return data;
}

export async function updateSubscription({
  cardTokenId,
  cardBrand,
  last4Digits,
  planId,
}) {
  await fetcher.put("/subscription", {
    card_token_id: cardTokenId,
    card_brand: cardBrand,
    last4: last4Digits,
    plan: planId,
  });

  return true;
}

export async function cancelSubscription() {
  const { data } = await fetcher.delete("/subscription");

  return data;
}

export async function getUserBills({ offset }) {
  const { data } = await fetcher.get(`/bills`, {
    params: {
      offset,
    },
  });

  return data;
}
