import {
  faBars,
  faCircleUser,
  faSliders,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { SideBar } from "./SideBar";
import logo from "../assets/images/logo.png";
import full_logo from "../assets/images/full_logo.png";
import * as constants from "../api/constants";
import "../styles/components/Navbar.css";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { SLIDE } from "./animations/framer-animations";

export const DefaultItems = () => {
  const navigate = useNavigate();

  return (
    <ul>
      <li className="desktop" onClick={() => navigate("/")}>
        Inicio
      </li>
      <li className="desktop" onClick={() => navigate(constants.LOGIN_ROUTE)}>
        Iniciar Sesión
      </li>
      <li className="desktop" onClick={() => navigate(constants.CONTACT_ROUTE)}>
        Contacto
      </li>
      <li onClick={() => navigate(constants.SIGNUP_ROUTE)}>
        <button className="white wide semi_rounded">Únete</button>
      </li>
    </ul>
  );
};

export const UserItems = () => {
  function redirect() {
    if (auth.role === constants.ADMIN_ROLE) {
      navigate(constants.ADMIN_PANEL_ROUTE);
    } else {
      navigate(constants.MY_BUSINESS_ROUTE);
    }
  }

  const navigate = useNavigate();

  const auth = useAuth();

  return (
    <motion.div
      className="user-card"
      variants={SLIDE}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={SLIDE.transition}
    >
      <div className="user-card-name">
        <FontAwesomeIcon icon={faCircleUser} size="lg" />
        <p className="bold">@{auth.username}</p>
      </div>
      <div className="user-card-button desktop" onClick={redirect}>
        <FontAwesomeIcon icon={faSliders} />
      </div>
      <div
        className="user-card-button desktop"
        onClick={() => navigate("/b/" + auth.username)}
      >
        <FontAwesomeIcon icon={faStore} />
      </div>
    </motion.div>
  );
};

export const Navbar = () => {
  const showSidebar = () => {
    sidebarRef.current.show();
  };

  const sidebarRef = useRef();

  const navigate = useNavigate();

  const auth = useAuth();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${constants.MOBILE_SCREEN_THRESHOLD}px)`,
  });

  return (
    <>
      <SideBar
        ref={sidebarRef}
        items={[
          ["Inicio", () => navigate("/")],
          ["Iniciar Sesión", () => navigate(constants.LOGIN_ROUTE)],
          ["Contacto"],
          ["Unirse", () => navigate(constants.SIGNUP_ROUTE)],
        ]}
      />
      <header>
        <div style={{ display: "flex" }}>
          <button className="mobile" onClick={showSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <img
            src={!isDesktop && auth._id !== null ? logo : full_logo}
            alt={`${constants.NAME} Logo}`}
            style={{ maxHeight: "50px" }}
            className="href"
            onClick={() => navigate("/")}
          />
        </div>

        {auth.authState === constants.AUTH_STATE_AUTHENTICATED ? (
          <UserItems />
        ) : (
          <DefaultItems />
        )}
      </header>
    </>
  );
};
